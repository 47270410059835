


































import {Component, Prop, Vue} from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import {Invoice} from "@road-transport-logistics/groove-core/dist/invoicing/types/Invoice";
// eslint-disable-next-line no-unused-vars
import {InvoiceBatch} from "@road-transport-logistics/groove-core/dist/invoicing/types/InvoiceBatch";
import {grooveDatabase} from "@/database";
import {entities} from "@road-transport-logistics/groove-core/dist/database/entities";
import {roundNumber} from "@/utilities/number";
import InvoiceItemList from "@/components/Invoicing/InvoiceItemList.vue";
import {DateTime} from "luxon";

@Component({
  components: {InvoiceItemList}
})
export default class InvoiceList extends Vue {
  @Prop({ default: undefined }) private batch?: InvoiceBatch
  private invoices: Array<Invoice> = []

  async mounted() {
    console.log("Mounted Called.");
    console.log(this.batch);
    console.log(`${entities.invoiceBatch}/${this.batch?.sourceId}/${entities.invoices}`)
    const invoices = await grooveDatabase
        .collection(`${entities.invoiceBatch}/${this.batch?.sourceId}/${entities.invoices}`)
        .orderBy("date").get();
    console.log(invoices.empty);
    this.invoices = invoices.docs.map(doc => doc.data() as Invoice);
    console.log(this.invoices);
  }

  invoiceTotal(invoice: Invoice): string {
    return roundNumber(invoice.items.map(item => item.total).reduce((a,b) => a+b), 2);
  }

  public dateFormatted(invoice: Invoice): string {
    const date = DateTime.fromMillis(invoice.date);
    return date.toFormat("dd/LL/yyyy");
  }
}
