


































































































































































































































































import {grooveDatabase} from "@/database";
import moment from "moment"
import PurchaseOrderDetails from "./PurchaseOrderDetails.vue";
import grooveStore from "@/data/grooveStore";
import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import {PurchaseOrder} from "@road-transport-logistics/groove-core/dist/database/models/PurchaseOrder";
import {PurchaseOrderFilter} from "@/components/PurchaseOrder/PurchaseOrderFilter";
import {Supplier} from "@road-transport-logistics/groove-core/dist/database/models/Supplier";
import {Asset} from "@road-transport-logistics/groove-core/dist/database/models/Asset";
import {UserDetails} from "@road-transport-logistics/groove-core/dist/database/models/UserDetails";
import {generateUniqueId} from "@road-transport-logistics/groove-core/dist/utilities";
import * as firebase from "firebase";
import {entities} from "@road-transport-logistics/groove-core/dist/database/entities";

const queryReference = grooveDatabase
    .collection(entities.purchaseOrder)
    .orderBy('timestamp', 'desc');
const pageLimit = 25;

@Component({
  components: {PurchaseOrderDetails}
})
export default class PurchaseOrders extends Vue {
  private emptyPurchaseOrderId = "0xC0FFEE";
  private expandSearch: boolean = false;
  private expandDetails: boolean = true;
  private currentOrder: PurchaseOrder = {
    cost_centre: "",
    deleted: false,
    id: this.emptyPurchaseOrderId,
    po_number: "",
    price: 0,
    status: "",
    supplier: "",
    timestamp: 0,
    user: "",
    username: ""
  };
  private currentFilter: PurchaseOrderFilter = {
    id: undefined,
    user: undefined,
    supplier: undefined,
    deleted: false,
    cost_centre: undefined,
    status: "pending",
  }
  private statusValues = [
    {
      key:"pending",
      label:"Pending",
      value:"pending"
    },
    {
      key: "accepted",
      label: "Approved",
      value: "accepted"
    },
    {
      key: "deleted",
      label: "Deleted",
      value: "deleted"
    }
  ]
  private startObject: PurchaseOrder | undefined = undefined;
  private ordersLoaded: boolean = false;
  private ordersSaved: boolean = false;
  private purchaseOrders: Array<PurchaseOrder> = [];
  private cost_centres = [{
    name: 'Admin',
    id: 'admin'
  },
    {
      name: 'Health & Safety',
      id: 'health_and_safety'
    },
    {
      name: 'IT',
      id: 'info_tech'
    },
    {
      name: 'Workshop',
      id: 'workshop'
    }];
  private suppliers: Array<Supplier> = [];
  private vehicles: Array<Asset> = [];
  private users: Array<UserDetails> = [];
  private selected_user_search: string = "";
  private selected_supplier_search: string = "";
  private selectedToPrint: Array<PurchaseOrder> = [];
  private selected_cost_centre_search: string = "";
  private selected_po_number: string = "";
  private selectedStatus: string = "pending";

  @Watch('selected_po_number', {
    immediate: true, deep: true
  })
  selectedPurchaseOrderIdUpdated(number: string) {

    // Do a no-op unless the value has been cleared or is exactly the 6 char length required for
    // purchase orders.
    if (!number || number.length === 0) {
      this.currentFilter.id = undefined;
    } else if (number && number.length === 6) {
      // Prefix auto added. Convert to lower case as all are generated lower case.
      // We always want to search server side if searching by PO Number.
      this.currentFilter.id = `GRV-${number}`;
    }
  }

@Watch('currentFilter', {
  immediate: true, deep: true
})
  async currentFilterUpdated() {
  console.log("CURRENT FILTER!!");

  this.ordersLoaded = true;

  // Apply the where clauses based on the current filter set.
  let ordersReference = this.generate_filter(queryReference);
  ordersReference = ordersReference.limit(pageLimit);

  await this.$bind('purchaseOrders', ordersReference);

  this.ordersLoaded = false

  // Keep track of first query item so we can disable previous button.
  this.startObject = this.purchaseOrders[0];
}


@Watch('selectedStatus', {
  immediate: true, deep: true
})
selectedStatusUpdated(status: string) {
  if (status === 'deleted') {
    this.currentFilter.deleted = true;
  } else {
    this.currentFilter.deleted = false;
    this.currentFilter.status = status;
  }
}

async mounted() {
  const _this = this;
  _this.ordersLoaded = true

  // Fire all collection requests
  await Promise.all([
    this.$bind("users", grooveDatabase.collection("users").orderBy("displayName")),
    this.$bind("suppliers", grooveDatabase.collection("suppliers"))
  ]);

  this.vehicles = grooveStore.state.vehicles;

  // Remove invalid accounts.
  this.users = this.users.filter(it => it.displayName);

  _this.ordersLoaded = false;
}

  async nextPage() {
    this.ordersLoaded = true;
    const index = this.purchaseOrders.length - 1;
    const last = this.purchaseOrders[index];
    // Apply the where clauses based on the current filter set.
    let ordersReference = this.generate_filter(queryReference);
    ordersReference = ordersReference.startAt(last.timestamp).limit(pageLimit);
    await this.$bind('purchaseOrders', ordersReference);

    this.ordersLoaded = false
  }
  async previousPage() {
    this.ordersLoaded = true;
    const first = this.purchaseOrders[0];
    // Apply the where clauses based on the current filter set.
    let ordersReference = this.generate_filter(queryReference);
    ordersReference = ordersReference.endBefore(first.timestamp)
        .limitToLast(pageLimit);
    await this.$bind('purchaseOrders', ordersReference);

    this.ordersLoaded = false
  }
  clearFilters() {
    this.selected_po_number = "";
    this.selected_supplier_search = '';
    this.selected_user_search = '';
    this.selected_cost_centre_search = '';
    this.currentFilter = {
      id: undefined,
      user: undefined,
      supplier: undefined,
      deleted: false,
      cost_centre: undefined,
      status: "pending",
    };
  }
  changeCurrentOrder() {
    // Reset so the dialog closes.
    this.currentOrder = {
      cost_centre: "",
      deleted: false,
      id: this.emptyPurchaseOrderId,
      po_number: "",
      price: 0,
      status: "",
      supplier: "",
      timestamp: 0,
      user: "",
      username: ""
    };
  }
  print() {
    const url = "/purchaseorders/print?orders=" + this.selectedToPrint.join(',');
    // @ts-ignore
    window.open(url, '_blank').focus();
  }
  displayAssetNumber(cost_centre: string) {
    if (cost_centre === "health_and_safety") {
      return "Health & Safety";
    } else if (cost_centre === "admin") {
      return "Admin";
    } else if (cost_centre === "info_tech") {
      return "IT";
    } else if (cost_centre === "workshop") {
      return "Workshop";
    }

    const vehicle = this.vehicles.find(vehicle => {
      return vehicle.id === cost_centre
    });
    if (vehicle) {
      return vehicle.identifier;
    }
    return "n/a";
  }
  displayPrice(order: PurchaseOrder) {
    // It appears price can be null or string.....and strings that are not valid numbers.
    let displayPrice = "$0.00";

    try {
      displayPrice = "$" + order.price.toFixed(2);
    } catch(error) {
      // TODO prevent invalid price entries..
    }

    return displayPrice;
  }
  displaySupplier(order: PurchaseOrder) {
    const supplier = this.suppliers.find(supplier => {
      return supplier.id === order.supplier
    });
    if (supplier) {
      return supplier.name;
    }
    return "n/a";
  }
  foundOrder(order: PurchaseOrder) {
    const _this = this;
    this.ordersSaved = true;
    grooveDatabase.collection(entities.purchaseOrder).doc(order.id).update({status: 'accepted'}).then(function () {
      _this.ordersSaved = false;
    });
  }
  add_order() {

    // Don't create a new one just get an id from firestore and use it to populate the model.
    const orderRef = grooveDatabase.collection(entities.purchaseOrder).doc();
    const now = moment().valueOf();

    console.log("Creating Purchase Order");

    this.currentOrder = {
      id: orderRef.id,
      timestamp: now,
      // @ts-ignore
      user: this.$root.user.uid,
      // @ts-ignore
      username: this.$root.user.displayName || this.$root.user.email, /* admin can't access the user collection so we store this here */
      supplier: "",
      email: undefined,
      price: 0.00,
      description: "",
      cost_centre: "", // cost_centre
      po_number: 'GRV-' + generateUniqueId(4, 2),
      status: 'pending',
      deleted: false
    };

    console.log(this.currentOrder);

    this.expandDetails = false;
  }
  generate_filter(query: firebase.default.firestore.Query) {

    console.log('GENERATE FILTER');

    // If we are explicitly filtering on a purchase order number ignore other filters.
    if (this.currentFilter.id && this.currentFilter.id.length) {
      console.log(`PO NUMBER: ${this.currentFilter.id}`);
      query = query.where('po_number', '==', this.currentFilter.id);
      return query;
    }

    // Otherwise apply filters.
    if (this.currentFilter.user) {
      console.log(`USER: ${this.currentFilter.user}`);
      query = query.where('user', '==', this.currentFilter.user);
    }
    if (this.currentFilter.supplier) {
      console.log(`SUPPLIER: ${this.currentFilter.supplier}`);
      query = query.where('supplier', '==', this.currentFilter.supplier);
    }
    if (this.currentFilter.deleted) {
      query = query.where('deleted', '==', this.currentFilter.deleted);
    } else {
      query = query.where('deleted', '==', false);
    }
    if (this.currentFilter.cost_centre) {
      query = query.where('cost_centre', '==', this.currentFilter.cost_centre);
    }
    if (this.currentFilter.status) {
      query = query.where('status', '==', this.currentFilter.status);
    }

    return query;
  }



  get filtersActive() {
    console.log(this.currentFilter);
    return (this.currentFilter.cost_centre !== undefined)
        || (this.currentFilter.supplier !== undefined)
        || (this.currentFilter.status !== "pending")
        || (this.currentFilter.user !== undefined)
  }
  get selectedOrder(): PurchaseOrder {
    return this.currentOrder;
  }
  get filteredUsers() {
    return this.users.filter((option) => {
      const field = option.displayName ? option.displayName : option.email;
      return field
          .toString()
          .toLowerCase()
          .indexOf(this.selected_user_search.toLowerCase()) >= 0
    })
  }
  get filteredSuppliers() {
    return this.suppliers.filter((option) => {
      return option.name
          .toString()
          .toLowerCase()
          .indexOf(this.selected_supplier_search.toLowerCase()) >= 0
    })
  }
  get filteredCostCentres() {
    const costCenters = this.cost_centres.map(
        (item) => {
          return { id: item.id, name: item.name };
        });
    const vehicles = this.vehicles.map(
        (item) => { return { id: item.id, name: item.identifier };
        });
    return [...costCenters, ...vehicles].filter((option) => {
      return option.name
          .toString()
          .toLowerCase()
          .indexOf(this.selected_cost_centre_search.toLowerCase()) >= 0
    })
  }

  get showPreviousButton(): boolean {
    return (this.startObject !== undefined && this.purchaseOrders.length > 0 && this.startObject.id !== this.purchaseOrders[0].id);
  }


}
