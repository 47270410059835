<template>
  <tr>
    <td class="time_entry_type_label">
      <i-input
        v-model="supplier.name"
        size="sm"
        placeholder="Example Supplier Limited"
      />
    </td>
    <td class="myob_code">
      <i-input
        v-model="supplier.id"
        size="sm"
        placeholder="ACMECORP"
      />
    </td>
    <td class="myob_code">
      <i-input
        v-model="supplier.email"
        size="sm"
        placeholder="jdoe@example.com"
      />
    </td>
    <td class="myob_category">
      <i-input
        v-model="supplier.address"
        size="sm"
        placeholder="123 White House Avenue"
      />
    </td>
    <td class="myob_category">
      <i-input
        v-model="supplier.address2"
        size="sm"
        placeholder=""
      />
    </td>
    <td class="myob_allowance_code">
      <i-input
        v-model="supplier.city"
        size="sm"
        placeholder="Metropolis"
      />
    </td>
    <td class="myob_allowance_code">
      <i-button
        type="submit"
        size="sm"
        @click="save"
      >
        Create
      </i-button>
    </td>
  </tr>
</template>

<script>
    import {grooveDatabase} from "../database";

    export default {
        name: "SupplierNew",
        data: function () {
            return {
                selected: [],
                colour: '#000000',
                supplier: {
                   name: null,
                   id: null,
                   invoiceId: null,
                   email: null,
                   address: null,
                   city: null,
                   address_2: null
                }
            }
        },
        watch: {
        },
        methods: {
          async save() {
                try {
                    await grooveDatabase.collection("suppliers").doc(this.supplier.id).set(this.supplier);
                } catch(error) {
                    console.log(JSON.stringify(error));
                }
            },
        }
    }
</script>

<style scoped>
    .kind { max-width: 200px; display: inline-block; margin-right: 10px; text-transform: capitalize; }
    .kind input { text-transform: capitalize; }

    tr td { background-color: #ccc !important; }
    td.time_entry_type_label { width: 300px; }
    td.color, td.icon, td.myob_allowance_code, td.myob_category, td.myob_code { width: 120px; }
    td.color, td.icon, td.myob_allowance_code, td.myob_category, td.myob_code { width: 120px; }
</style>