<template>
  <div class="card p-4 mb-2">
    <div class="level">
      <b-field label="Name">
        <b-input v-model="currentCostCentre.name"></b-input>
      </b-field>
      <b-field label="Payroll Code">
        <b-input v-model="currentCostCentre.code"></b-input>
      </b-field>
      <b-field label="Cost Type">
        <b-select placeholder="Select Type" v-model="currentCostCentre.kind">
          <option
              v-for="time_entry_type of time_entry_types"
              :value="time_entry_type.name"
              :key="time_entry_type.uuid">
            {{ time_entry_type.name }}
          </option>
        </b-select>
      </b-field>
      <b-dropdown class="mt-4" :append-to-body="true" multiple v-model="selected">
        <template #trigger>
          <b-button
              pack="far"
              icon-right="caret-down">
            Roles ({{ selected.length }})
          </b-button>
        </template>
        <b-dropdown-item value="admin" aria-role="listitem">
          <div class="media">
            <b-icon class="media-left" pack="far" icon="shredder"></b-icon>
            <div class="media-content">
              <h3>Admin</h3>
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item value="driver" aria-role="listitem">
          <div class="media">
            <b-icon class="media-left" pack="far" icon="truck"></b-icon>
            <div class="media-content">
              <h3>Driver</h3>
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item value="yardman" aria-role="listitem">
          <div class="media">
            <b-icon class="media-left" pack="far" icon="hat-cowboy-side"></b-icon>
            <div class="media-content">
              <h3>Yardman</h3>
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item value="workshop" aria-role="listitem">
          <div class="media">
            <b-icon class="media-left" pack="far" icon="garage"></b-icon>
            <div class="media-content">
              <h3>Workshop</h3>
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="level">
      <div class="level-left">
        <div class="has-text-grey-light">{{ currentCostCentre.uuid }}</div>
      </div>
      <div class="level-left">
        <b-button class="ml-2" type="is-warning" icon-pack="far" icon-left="trash" @click="save"></b-button>
        <b-button class="ml-2" type="is-success" icon-pack="far" icon-left="floppy-disk" @click="save"></b-button>
      </div>
    </div>
  </div>
</template>

<script>
    import {grooveDatabase} from "../database";
    import _ from 'lodash'
    import Vue from "vue";

    export default {
        name: "CostCentre",
        props: {
          costcentre: {
            type: Object,
            default: null
          }
        },
        data: function () {
            return {
                admin: null,
                workshop: null,
                yardman: null,
                driver: null,
                time_entry_types: [],
                selected: [],
              currentCostCentre: null
            }
        },
        watch: {
            selected(newVal, oldVal) {
                if ( newVal !== oldVal ) {
                    this.debouncedUpdate(newVal);
                }
            },
            costcentre: {
                immediate: true,
                handler: function (newVal) {
                    var _this = this;
                    Object.keys(newVal.roles).forEach(function (key) {
                        if (newVal.roles[key] === true) {
                            _this.selected.push(key);
                        }
                    });
                }
            }
        },
        created: function () {
          const self = this;
          this.currentCostCentre = this.costcentre;
            this.debouncedUpdate = _.debounce(function (newVal) {
                const keys = ['driver', 'admin', 'yardman', 'workshop'];
                const keys_to_set = {}
                keys.forEach(function (key) {
                    keys_to_set[key] = !!newVal.includes(key);
                });
                grooveDatabase.collection("cost_centres").doc(self.costcentre.id).update({roles: keys_to_set});

            }, 500)
        },
        methods: {
          async delete() {
            try {
              await grooveDatabase.collection("cost_centres").doc(this.currentCostCentre.id).update({
                deleted: true
              });
              Vue.$toast.open({
                message: "Cost Centre Updated!",
                type: "success",
                position: "top-right"
              });
            } catch (error) {
              console.log(JSON.stringify(error));
              Vue.$toast.open({
                message: "Cost Centre Update Failed!",
                type: "error",
                position: "top-right"
              });
            }
          },
            async save() {
                try {
                    await grooveDatabase.collection("cost_centres").doc(this.currentCostCentre.id).set(this.currentCostCentre);
                  Vue.$toast.open({
                    message: "Cost Centre Updated!",
                    type: "success",
                    position: "top-right"
                  });
                } catch (error) {
                    console.log(JSON.stringify(error));
                  Vue.$toast.open({
                    message: "Cost Centre Update Failed!",
                    type: "error",
                    position: "top-right"
                  });
                }
            },
        },
        firestore() {
            return {
                time_entry_types: grooveDatabase.collection("time_entry_types")
            }
        },
    }
</script>

<style scoped>

    .kind {
        max-width: 200px;
        display: inline-block;
        margin-right: 10px;
        text-transform: capitalize;
    }

    .kind input {
        text-transform: capitalize;
    }
</style>