<template>
  <div class="container is-fluid mt-4">
    <div class="container is-justify-content-flex-end">
      <div class="level-right">
        <div class="level-item mr-2">
          <b-button
              icon-pack="far"
              icon-right="arrow-left"
              size="is-small"
              @click="back_week"
          >
          </b-button>

        <div class="level-item ml-2 mr-2">
          <p>{{ start_of_week.format("ddd, DD/MMM/YYYY") }} - {{ end_of_week.clone().format("ddd, DD/MMM/YYYY") }}</p>
        </div>
        <div class="level-item">
          <b-button
              icon-pack="far"
              icon-right="arrow-right"
              size="is-small"
              @click="next_week"
          >
          </b-button>
        </div>
      </div>
    </div>
    </div>

      <div class="container is-full-desktop is-fluid">
        <div class="button-container">
          <span
            v-if="requestsLoading"
            class="loading"
          >Loading</span>
          <span
            v-if="requestsSaved"
            class="loading"
          >Saving</span>
        </div>
        <table class="wrapping-table">
          <tr>
            <th class="approve">
              AccPac ID
            </th>
            <th class="approve">
              Batch
            </th>
            <th class="approve">
              Date
            </th>
            <th class="approve">
              Status
            </th>
            <th class="total">
              Total
            </th>
          </tr>
          <tr
            v-for="batch in batches"
            :key="batch.accpacId"
          >
            <td class="order-date">
              {{ batch.accpacId }}
            </td>
            <td class="order-date">
              {{ batch.batch }}
            </td>
            <td class="order-date">
              {{ renderDate(batch.date) }}
            </td>
            <td class="order-date">
              {{ batch.status }}
            </td>
            <td class="order-date">
              {{ renderCurrency(batch.total) }}
            </td>
          </tr>
        </table>
      </div>
  </div>
</template>

<script>

import {grooveDatabase} from "@/database";
import moment from "moment"

export default {
        name: "Batch",
        data: function () {
            return {
                start_of_week: moment().subtract(60, 'days').startOf('week'),
                end_of_week: moment().endOf('week'),
                expandSearch: false,
                expandDetails: false,
                currentFilter: {
                    idx: 0,
                    // status: 'pending',
                    timestamp_start: true,
                    timestamp_end: true
                },
                start_of_week_as_jsdate: {
                    start: moment().subtract(60, 'days').startOf('week'), // Jan 16th, 2018
                    end: moment().endOf('week')     // Jan 19th, 2018
                },

                selected_new_user: null,
                description: null,
                new_starttime: moment().add(1, 'day').startOf('day').add(8, 'hours').valueOf(),
                new_endtime: moment().add(1, 'day').startOf('day').add(17, 'hours').valueOf(),
                filtered_requests: [],
                filtered_leave_requests: [],
                users: [],
                myself: [],
                selected_user: null,
                selected_status: 'pending',
                selected_cc: null,
                requestsLoading: false,
                requestsSaved: false,
                batches: []
            }
        },
        computed: {
            start_timestamp_for_datepicker: function() {
                if ( this.new_starttime ) {
                    var a =  moment(this.new_starttime).format("YYYY-MM-DDTHH:mm");
                    return a;
                }
                return null;
            },
            end_timestamp_for_datepicker: function() {
                if ( this.new_endtime ) {
                  return moment(this.new_endtime).format("YYYY-MM-DDTHH:mm");
                }
                return null;
            }
        },

        watch: {
            batches: {
                handler: async function() {
                }
            },
            currentFilter: {
                immediate: true,
                deep: true,
                handler: function () {

                    this.requestsLoading = true
                    var filterArgs = this.generate_filter();

                    var prepareQuery = grooveDatabase.collection("batches")

                    // Does this even work - apparently yes - iterate over each filter and append!
                    for (var i = 0; i < filterArgs.length; i++) {
                        prepareQuery = prepareQuery.where(...filterArgs[i]);
                    }

                    prepareQuery = prepareQuery.orderBy('date', 'desc');
                    this.$bind('batches', prepareQuery).then(() => this.requestsLoading = false).finally(() => this.requestsLoading = false);
                }
            },
            start_of_week_as_jsdate(v) {
                if ( v ) {
                    var conv_to_moment_date_start = moment(v.start);
                    var conv_to_moment_date_end = moment(v.end);
                    this.start_of_week = conv_to_moment_date_start;
                    this.end_of_week = conv_to_moment_date_end;
                    this.currentFilter['timestamp_start'] = this.start_of_week;
                    this.currentFilter['timestamp_end'] = this.end_of_week;
                    this.currentFilter.idx++;
                  }
            }
        },
        methods: {
            renderDate: function (d) {
              if ( d ) {
                return moment(d).format("ddd, DD/MM/YYYY HH:mm:ss");
              }
            },
            renderCurrency: function (d) {
              if ( d ) {
                return "$" + ((+d).toFixed(2));

                // return +(d).toFixed(2);
              }
            },
            changeStartTime: function(data) {
                if ( data ) {
                    const new_time = moment(data, moment.HTML5_FMT.DATETIME_LOCAL).set('seconds', 0).valueOf();
                    this.new_starttime = new_time;
                }
            },
            changeEndTime: function(data) {
                if ( data ) {
                    const new_time = moment(data, moment.HTML5_FMT.DATETIME_LOCAL).set('seconds', 0).valueOf();
                    this.new_endtime = new_time;
                }
            },
            clearFilters: function() {
            },
            next_week: function() {
              this.start_of_week = this.start_of_week.clone().add(1, 'week');
              this.end_of_week = this.end_of_week.clone().add(1, 'week');
              this.currentFilter['timestamp_start'] = this.start_of_week;
              this.currentFilter['timestamp_end'] = this.end_of_week;
              this.currentFilter.idx++;
            },
            back_week: function() {
              this.start_of_week = this.start_of_week.clone().subtract(1, 'week');
              this.end_of_week = this.end_of_week.clone().subtract(1, 'week');
              this.currentFilter['timestamp_start'] = this.start_of_week;
              this.currentFilter['timestamp_end'] = this.end_of_week;
              this.currentFilter.idx++;
            },
            showMenu: function (menu) {
                var e = document.getElementById(menu).style.display;
                if (e === 'block') {
                    document.getElementById(menu).style.display = 'none';
                } else {
                    document.getElementById(menu).style.display = 'block';
                }
            },
            generate_filter: function () {
                var filter = [];
                if ('timestamp_start' in this.currentFilter && this.currentFilter.timestamp_start) {
                    filter.push(['date', '>=', this.start_of_week.valueOf()])
                }
                if ('timestamp_end' in this.currentFilter && this.currentFilter.timestamp_end) {
                    filter.push(['date', '<=', this.end_of_week.valueOf()])
                }
                return filter;
            }
        }
    }
</script>

<style scoped>


</style>