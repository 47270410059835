<template>
  <div class="container is-fluid mt-5 pl-0 pr-0">
    <div class="card">
      <div class="level">
        <div class="level-item">
          <b-input expanded placeholder="Search by Code"></b-input>
        </div>
        <div class="level-item">
          <b-button @click="addAsset" icon-pack="far" icon-right="plus-circle"></b-button>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="item" :id="'item-sm-' + index" :key="index" v-for="(asset, index) in assets">
        <div class="item-content">
          <div class="card">
            <div class="card-content">
              <div class="is-flex">
                <h4 class="is-flex-grow-1">{{ asset.identifier }}</h4>
                <b-tag v-if="asset.type === 1" class="is-justify-content-flex-end is-primary">Truck</b-tag>
                <b-tag v-if="asset.type === 2" class="is-justify-content-flex-end is-info">Trailer</b-tag>
                <b-tag v-if="asset.type === 3" class="is-justify-content-flex-end is-warning">Vehicle</b-tag>
                <b-tag v-if="asset.type === 0" class="is-justify-content-flex-end is-success">Asset</b-tag>
              </div>
              <h5>{{ asset.description }}</h5>
              <p>{{ asset.registrationNumber }}</p>
              <p class="is-size-7 is-italic">VIN: {{ asset.vin }}</p>
              <p class="is-size-7 is-italic">HUB: {{ asset.hubSerialNumber }}</p>
              <div >
                <b-field label="Division">
                  <b-select :disabled="true" v-model="asset.divisionId" size="is-small" placeholder="Division">
                    <option
                        v-for="option in divisions"
                        :value="option.uuid"
                        :key="option.uuid">
                      {{ option.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <footer class="card-footer is-justify-content-flex-end">
              <div class="content is-right p-2">
                <b-button @click="save(asset)" icon-pack="far" icon-right="save" size="is-small" class="is-success"></b-button>
                <b-button v-if="asset" @click="editAsset(asset)" icon-pack="far" icon-right="edit" size="is-small" class="mr-2"></b-button>
                <b-button @click="disableAsset(asset)" icon-pack="far" icon-right="trash" size="is-small" class="is-danger"></b-button>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
    <b-sidebar
        class="details-bar"
        type="is-light"
        :fullheight="false"
        :fullwidth="false"
        :overlay="false"
        :right="true"
        v-model="currentAsset"
    >
      <div class="card p-4">

        <div class="card-content">
          <b-field label="Cost Centre">
            <b-input :disabled="currentAsset && currentAsset.telemetryProviderId !== null" size="is-small" v-model="currentCostCentre"></b-input>
          </b-field>
          <b-field label="Description">
            <b-input :disabled="currentAsset && currentAsset.telemetryProviderId !== null" size="is-small" v-model="currentDescription"></b-input>
          </b-field>
          <b-field label="Identifier">
            <b-input :disabled="currentAsset && currentAsset.telemetryProviderId !== null" size="is-small" v-model="currentIdentifier"></b-input>
          </b-field>
          <b-field label="Registration">
            <b-input :disabled="currentAsset && currentAsset.telemetryProviderId !== null" size="is-small" v-model="currentRegistrationNumber"></b-input>
          </b-field>
          <b-field label="Division">
            <b-select v-model="currentDivision" size="is-small" placeholder="Division">
              <option
                  v-for="option in divisions"
                  :value="option.uuid"
                  :key="option.uuid">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Type">
            <b-select :disabled="currentAsset && currentAsset.telemetryProviderId !== null" v-model="currentType" size="is-small" placeholder="Type">
              <option
                  v-for="option in types"
                  :value="option.id"
                  :key="option.id">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
          <b-field label="VIN">
            <b-input :disabled="currentAsset && currentAsset.telemetryProviderId !== null" size="is-small" v-model="currentVin"></b-input>
          </b-field>
        </div>

        <footer class="card-footer is-justify-content-flex-end">
          <div class="content is-right p-2">
            <b-button @click="cancelEdit" size="is-small" icon-pack="far" icon-right="window-close" class="is-primary"></b-button>
            <b-button @click="saveEdited" size="is-small" icon-pack="far" icon-right="save" class="is-success ml-2"></b-button>
          </div>
        </footer>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {grooveDatabase} from "@/database";
import Muuri from "muuri";
import Vue from "vue";
import UUID from "pure-uuid";
import {DateTime} from "luxon";
export default {
name: "Asset",
  data: function () {
    return {
      currentAsset: null,
      assets: [],
      divisions: [],
      types: [
        { name: "Truck", id: 1},
        { name: "Trailer", id: 2},
        { name: "Vehicle", id: 3},
        { name: "Asset", id: 0}
      ],
      grid: null,
      currentDivision: null,
      currentDescription: null,
      currentCostCentre: null,
      currentIdentifier: null,
      currentRegistrationNumber: null,
      currentType: null,
      currentVin: null
    }
  },
  async mounted() {
    await this.$bind('divisions', grooveDatabase.collection("divisions").where("deleted", "==", false).orderBy('name'));
    await this.$bind('assets', grooveDatabase.collection("vehicles").where("deleted", "==", false).orderBy('identifier'));
    this.grid = new Muuri('.grid', {dragEnabled:false});
  },
  watch: {
    currentAsset: {
      immediate: true,
      deep: true,
      handler: async function () {
        if(this.currentAsset){
          this.currentDivision = this.currentAsset.divisionId;
          this.currentDescription = this.currentAsset.description;
          this.currentCostCentre = this.currentAsset.costCentre;
          this.currentIdentifier = this.currentAsset.identifier;
          this.currentRegistrationNumber = this.currentAsset.registrationNumber;
          this.currentType = this.currentAsset.type;
          this.currentVin = this.currentAsset.vin;
        } else {
          this.currentDivision = null;
          this.currentDescription = null;
          this.currentCostCentre = null;
          this.currentIdentifier = null;
          this.currentRegistrationNumber = null;
          this.currentType = null;
          this.currentVin = null;
        }
      }
    }
  },
  methods: {
    editAsset: function(asset) {
      this.currentAsset = asset;
    },
    cancelEdit: function() {
      this.currentAsset = null;
    },
    save: async function(asset) {
      await grooveDatabase.collection("vehicles").doc(asset.id).set(asset, { merge: true });
      Vue.$toast.open({
        message: "Asset Updated.",
        type: "success",
        position: "top-right"
      });
    },
    saveEdited: async function() {
      const now = DateTime.now().toMillis();

      this.currentAsset.divisionId = this.currentDivision;
      this.currentAsset.description = this.currentDescription;
      this.currentAsset.costCentre = this.currentCostCentre;
      this.currentAsset.identifier = this.currentIdentifier;
      this.currentAsset.registrationNumber = this.currentRegistrationNumber;
      this.currentAsset.type = this.currentType;
      this.currentAsset.vin = this.currentVin;
      this.currentAsset.lastUpdated = now;

      console.log(this.currentAsset.id);

      const id = this.currentAsset.id ? this.currentAsset.id : new UUID(4).toString()

      await grooveDatabase.collection("vehicles").doc(id).set(this.currentAsset, { merge: true });
      this.currentAsset = null;
      Vue.$toast.open({
        message: "Asset Updated.",
        type: "success",
        position: "top-right"
      });
    },
    addAsset: async function() {

      const now = DateTime.now().toMillis();

      this.currentAsset = {
        costCentre: null,
        deleted: false,
        description: null,
        divisionId: null,
        driverId: null,
        hubSerialNumber: null,
        identifier: null,
        lastUpdated: now,
        registrationNumber: null,
        telemetryProviderId: null,
        timestamp: now,
        type: 1,
        vin: null
      };
    },
    disableAsset: async function(asset) {
      this.$confirm(
          {
            message: 'Are you sure you want to disable this asset?',
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: async (confirm) => {
              if (confirm) {
                asset.deleted = true;
                await grooveDatabase.collection("vehicles").doc(asset.id).set(asset, { merge: true });
                Vue.$toast.open({
                  message: "Asset Disabled.",
                  type: "success",
                  position: "top-right"
                });
              }
            }
          }
      );
    },
  }
}
</script>

<style scoped>

.grid {
  position: relative;
}
.item {
  display: block;
  position: absolute;

  margin: 5px;
  z-index: 1;
  background: transparent;
  color: #fff;
}
.item.muuri-item-dragging {
  z-index: 3;
}
.item.muuri-item-releasing {
  z-index: 2;
}
.item.muuri-item-hidden {
  z-index: 0;
}
.item-content {
  position: relative;
  width: 100%;
  height: 100%;
}

</style>