<template>
  <div class="container mt-5">
    <div v-for="(user, idx) of users"
         :key="idx" class="card m-2">
      <div class="card-content">
        <div class="media">
          <div class="media-left">
            <figure class="image is-48x48">
              <img :src="gravatar.url(user.email, {s: '100', r: 'x', d: 'retro'}, true)" alt="Profile image">
            </figure>
          </div>
          <div class="media-content">
            <p class="title is-4">{{ user.displayName || "REVIEW USER" }}</p>
            <p class="subtitle is-6">{{ user.email }}</p>
          </div>
        </div>
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <b-field label="Name">
                <b-input
                    size="is-small"
                    v-model="user.displayName"
                    @input="updateName(user.id, user.displayName)"
                />
              </b-field>
            </div>
            <div class="level-item">
              <b-field label="Depot">
                <b-select
                    icon-pack="far" icon="warehouse"
                    size="is-small"
                    v-model="user.depot"
                    @input="updateDepot(user.id, user.depot)"
                >
                  <option
                      v-for="depot in depots"
                      :key="depot.id"
                      :label="depot.name"
                      :value="depot.id"
                  />
                </b-select>
              </b-field>
            </div>
            <div class="level-item">
              <b-field label="Division">
                <b-select
                    icon-pack="far" icon="building"
                    size="is-small"
                    v-model="user.division"
                    @input="updateDivision(user.id, user.division)"
                >
                  <option
                      v-for="division in divisions"
                      :key="division.id"
                      :label="division.name"
                      :value="division.id"
                  />
                </b-select>
              </b-field>
            </div>
            <div class="level-item">
              <b-field label="StaffCode">
                <b-input
                    size="is-small"
                    v-model="user.staffCode"
                    placeholder="Payroll ID"
                    @input="updatePayrollId(user.id, user.staffCode)"
                />
              </b-field>
            </div>
            <div class="level-item">
              <b-field>
                <b-button type="is-danger"
                          icon-pack="far"
                          icon-right="ban"
                          @click="handleDelete(user.id, user.displayName)"
                />
              </b-field>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <UserLeaveApprover
                  :user="user"
                  :users="users"
                  :uidx="idx"
              />
            </div>
            <div class="level-item">
              <UserCustomer
                  :user="user"
                  :customers="customers"
              />
            </div>
            <div class="level-item">
              <UserRole :user="user" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import {grooveDatabase} from "@/database";
    import UserRole from "./UserRole";
    import UserCustomer from "./UserCustomer";
    import UserLeaveApprover from "./UserLeaveApprover";
    import {debounce} from "debounce";

    const gravatar = require('gravatar');

    export default {
        name: "Users",
        components: {
            UserRole, UserCustomer, UserLeaveApprover
        },
        data: function() {
            return {
                selected: null,
                divisions: [],
                customers: [],
                users: [],
                depots: [],
              gravatar: gravatar
            }
        },
        methods: {
            updateName: debounce(function (id, name) {
              console.log(`ID: ${id} , Name: ${name}`)
              grooveDatabase.collection("users").doc(id).update({ displayName: name });
            }, 500),
            updatePayrollId: debounce(function (id, staffCode) {
              console.log(`ID: ${id} , Name: ${staffCode}`)
              grooveDatabase.collection("users").doc(id).update({ staffCode: staffCode });
            }, 500),
            updateDivision(id, division) {
              console.log("updateDivision");
                grooveDatabase.collection("users").doc(id).update({ division: division });
            },
            updateDepot(id, depot) {
              console.log("updateDepot");
                grooveDatabase.collection("users").doc(id).update({ depot: depot });
            },
            handleDelete(id, name) {
              this.$confirm(
                {
                  message: `Are you sure you want to disable, ` + name + '?',
                  button: {
                    no: 'No',
                    yes: 'Yes'
                  },
                  /**
                  * Callback Function
                  * @param {Boolean} confirm
                  */
                  callback: confirm => {
                    if (confirm) {
                        grooveDatabase.collection("users").doc(id).update({ disabled: true });
                    }
                  }
                }
              )
            }
        },
        firestore() {
            return {
                users: grooveDatabase.collection("users").where("disabled", "==", false).orderBy('email'),
                depots: grooveDatabase.collection("depots").orderBy('name'),
                customers: grooveDatabase.collection("clients").orderBy('name'),
                divisions: grooveDatabase.collection("divisions").orderBy('name'),
            }
        }
    }
</script>

<style scoped>
    h6 { margin-top: 15px; }
</style>