import {DateTime} from "luxon";

export function alignPayrollWeek(payrollReference: string, currentDate: DateTime) {
    const payroll_start = DateTime.fromISO(payrollReference).startOf('day');
    const now = currentDate.startOf('day');
    const diff = Math.abs(payroll_start.diff(now, 'days').days) % 14;
    console.log(diff);

    // Allow for DST
    const tz = currentDate.toFormat('ZZZZ');
    console.log(tz);
    const days = 7;
    if(diff < 3) {
        return currentDate.startOf('week')
            .minus({days: days});
    }
    if(diff < 10 && diff > 2) {
        return currentDate.startOf('week');
    }
    return currentDate.startOf('week')
        .minus({days: days});
}
