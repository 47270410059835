<template>
  <i-container
    class="_text-left _height-100"
    fluid
  >
    <i-row class="_height-100">
      <i-column
        id="upcoming"
        xs="12"
      >
        <table v-if="loaded === true">
          <tr>
            <th>Type</th>
            <th>Arrives at</th>
            <th>Description</th>
            <th>Vehicle Name</th>
            <th>Vehicle Rego</th>
          </tr>
          <tr
            v-for="(task, idx) in started_tasks"
            :key="idx"
          >
            <!--                        <td>{{ task.customerId }}</td>-->
            <td>{{ task.type }}</td>
            <td>{{ renderDate(task.distanceAndDuration.arrivalTimeEpochSeconds) }}</td>
            <td>{{ task.productDescription }}</td>
            <td>{{ task.vehicleName }}</td>
            <td>{{ task.registrationNumber }}</td>
          </tr>
        </table>
      </i-column>
    </i-row>
  </i-container>
</template>

<script>

    import {grooveDatabase} from "@/database";
    import {DateTime} from "luxon";

    export default {
        name: "Arrivals",
        data: function () {
            return {
                tasks: [],
                started_tasks: [],
                jobs: [],
                vehicles: [],
                bounds: [],
                latest_job: null,
                latest_next_job: null,
                today: null,
                loaded: false,
                currentUser: null,
                user: null,
            }
        },
        watch: {
            '$root.user': {
                handler: function () {
                    this.loadData();
                }
            },
            jobs: {
                handler: function() {
                    this.started_tasks = this.tasks.filter(task => {
                      let job = this.jobs.find(job => {
                        return job.id === task.jobId && job.status === 'Started'
                      });
                      return job
                    });

                    if (this.started_tasks.length) {

                        this.latest_job = this.started_tasks[0] /* TODO - we really want the closest job to now that isn't yet completed, e.g. complete: false */

                        if (this.started_tasks.length >= 2)
                            this.latest_next_job = this.started_tasks[1] /* TODO - we really want the closest job to now that isn't yet completed, e.g. complete: false */


                        this.started_tasks.map(task => {
                          let vehicle = null;
                          if (task.vehicleId) {
                                vehicle = this.vehicles.find(v => {
                                    return v.id === task.vehicleId
                                });
                                if (vehicle) {
                                    task.vehicleName = vehicle.identifier;
                                    task.registrationNumber = vehicle.registrationNumber;
                                }
                            }
                        })
                    }
                }
            }
        },
        mounted() {
            if ( this.$root.user && this.$root.user.uid ) {
              this.user = this.$root.user;
                this.loadData();
            }
        },
        methods: {
            renderDate(epoch) {
                return DateTime.fromSeconds(epoch).toFormat("dd/LL/yyyy HH:mm:ss");
            },
            countdown(epoch) {
              const now = DateTime.now();
              let difference = ((epoch - now.toSeconds()) * 1000) / 60000;
              if (difference < 0) {
                    return "T+" + Math.floor(difference * -1);
                }
                return "T-" + Math.floor(difference);
            },
            updateDay() {
                this.today = DateTime.now();
                setTimeout(this.updateDay, this.today.endOf('day').valueOf() - this.today.toMillis() + 10000);
            },
            loadData() {
              const _this = this;
                _this.today = DateTime.now();
                this.$bind('currentUser', grooveDatabase.collection("users").doc(this.$root.user.uid)).then(() => {
                    Promise.all([
                        _this.$bind('vehicles', grooveDatabase.collection("vehicles")),
                        _this.$bind('tasks', grooveDatabase.collection("integrations").doc("market2x").collection("tasks")
                            .where("distanceAndDuration.arrivalTimeEpochSeconds", "<=", this.today.endOf('day').valueOf() / 1000)
                            .where("distanceAndDuration.arrivalTimeEpochSeconds", ">=", this.today.startOf('day').valueOf() / 1000)
                            .where("complete", "==", false)
                            .where("type", "==", "DELIVERY")

                            // .where("customerId", "==", "ce853ccd-e731-486a-9b87-a74fbbf160f1")
                            .where("customerId", "==", this.currentUser.customerId)
                            .limit(100)
                            .orderBy("distanceAndDuration.arrivalTimeEpochSeconds", "desc")
                        ),
                    ]).then(() => {
                        _this.$bind('jobs', grooveDatabase.collection("integrations").doc("market2x").collection("jobs")
                            .where("customerId", "==", this.currentUser.customerId)
                            .where("status", "==", "Started")
                            .limit(100)
                        );
                        this.loaded = true;
                    })
                });
            }
        }
    }
</script>

<style scoped>
    h6 {
        margin-top: 15px;
    }

    table {
        width: 100%;
        font-size: 1.4em;
    }
</style>
