<template>
  <b-field label="Customer">
    <b-input size="is-small" v-model="selected_customer" @change="save"></b-input>
  </b-field>
</template>

<script>
    import {grooveDatabase} from "../database";

    export default {
        name: "UserCustomer",
        props: {
          user: {
            type: Object,
            default: null
          },
          customers: {
            type: Array,
            default: () => {return [];}
          }
    },
        data: function () {
            return {
                selected_customer: ''
            }
        },
        watch: {
          user: {
              immediate: true,
              handler: function() {
                  this.selected_customer = this.user.customerId;
              }
          }
        },
        methods: {
            save: function() {
                grooveDatabase.collection("users").doc(this.user.id).update({ customerId: this.selected_customer });
            }
        }
    }
</script>

<style scoped>

</style>