<template>
  <div class="section">
    <div class="level">
      <div class="level-left">
        <h1>Time Entry Types</h1>
      </div>
      <div class="level-right">
        <b-button icon-pack="far" icon-left="plus" @click="newTimeEntryType"></b-button>
      </div>
    </div>
    <div class="container">
      <TimeEntryType
          v-for="(type, idx) of timeEntryTypes"
          :key="idx"
          :time-entry-type="type"
      />
    </div>
  </div>
</template>

<script>


import {grooveDatabase} from "../database";
import TimeEntryType from "./TimeEntryType";
import UUID from "pure-uuid";

export default {
  name: "TimeEntryTypes",
  components: {
    TimeEntryType
  },
  data: function() {
    return {
      timeEntryTypes: [],
    }
  },
  methods: {
    newTimeEntryType: function() {
      const id = new UUID(4).toString();
      grooveDatabase.collection("time_entry_types").doc(id).set(
          {
            name: null,
            uuid: id,
            roles: {
              admin: false,
              workshop: false,
              driver: false,
              yardman: false
            }
          }
      )
    }
  },
  firestore() {
    return {
      timeEntryTypes: grooveDatabase.collection("time_entry_types").orderBy('name')
    }
  },
}
</script>

<style scoped>
h6 { margin-top: 15px; }
</style>