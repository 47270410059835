<template>
  <div class="container is-fluid mt-5 pl-0 pr-0">
    <div class="card">
      <div class="level">
        <div class="level-item">
          <b-input expanded placeholder="Search by Code"></b-input>
        </div>
        <div class="level-item">
          <b-button @click="addProduct" icon-pack="far" icon-right="circle-plus"></b-button>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="item" :id="'item-sm-' + index" :key="index" v-for="(product, index) in products">
        <div class="item-content">
          <div class="card">
            <div class="card-content">
              <h4>{{ product.name }}</h4>
              <h5>{{ product.code }}</h5>
              <div >
                <p>{{ product.uom }}</p>
              </div>
              <div >
                <p class="has-text-grey-light">{{ product.id }}</p>
              </div>
            </div>
            <footer class="card-footer is-justify-content-flex-end">
              <div class="content is-right p-2">
                <b-button @click="editProduct(product)" icon-pack="far" icon-right="pen-field" size="is-small" class="mr-2"></b-button>
                <b-button @click="deleteProduct(product)" icon-pack="far" icon-right="trash" size="is-small" class="is-danger"></b-button>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>

    <b-sidebar
        class="details-bar"
        type="is-light"
        :fullheight="false"
        :fullwidth="false"
        :overlay="false"
        :right="true"
        v-model="currentProduct"
    >
      <div class="card p-4">

        <div class="card-content">
          <b-field label="Product Code">
            <b-input size="is-small" v-model="currentCode"></b-input>
          </b-field>
          <b-field label="Product Name">
            <b-input size="is-small" v-model="currentName"></b-input>
          </b-field>
          <b-field label="Product UOM">
            <b-input size="is-small" v-model="currentUom"></b-input>
          </b-field>
        </div>

        <footer class="card-footer is-justify-content-flex-end">
          <div class="content is-right p-2">
            <b-button @click="cancelEdit" size="is-small" icon-pack="far" icon-right="rectangle-xmark" class="is-primary"></b-button>
            <b-button @click="save" size="is-small" icon-pack="far" icon-right="floppy-disk" class="is-success ml-2"></b-button>
          </div>
        </footer>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {grooveDatabase} from "@/database";
import 'web-animations-js';
import Muuri from 'muuri';
import Vue from "vue";
import moment from 'moment';
import UUID from 'pure-uuid'

export default {
name: "Product",
  data: function () {
    return {
      productContainer: "productContainer",
      products: [],
      currentProduct: null,
      currentCode: null,
      currentName: null,
      currentUom: null
    }
  },
  watch: {
    currentProduct: {
      immediate: true,
      deep: true,
      handler: async function () {
        if(this.currentProduct){
          this.currentCode = this.currentProduct.code;
          this.currentName = this.currentProduct.name;
          this.currentUom = this.currentProduct.uom;
        } else {
          this.currentCode = null;
          this.currentName = null;
          this.currentUom = null;
        }

      }
        }
    },
  methods: {
  save: async function() {
    if(!this.currentCode || !this.currentName || !this.currentUom) {
      Vue.$toast.open({
        message: "All Product details must be supplied!",
        type: "error",
        position: "top-right"
      });
      return;
    }
    this.currentProduct.code = this.currentCode;
    this.currentProduct.name = this.currentName;
    this.currentProduct.uom = this.currentUom;
    this.currentProduct.lastUpdated = moment().valueOf();
    const id = this.currentProduct.id ? this.currentProduct.id : new UUID(4).toString()
    await grooveDatabase.collection("products").doc(id).set(this.currentProduct);
    this.currentProduct = null;
    Vue.$toast.open({
      message: "Product Updated.",
      type: "success",
      position: "top-right"
    });
  },
    cancelEdit: function() {
      this.currentProduct = null;
    },
    editProduct: function(product) {
      this.currentProduct = product;
    },
    deleteProduct: async function(product) {
      this.$confirm(
          {
            message: 'Are you sure you want to delete this product?',
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: async (confirm) => {
              if (confirm) {
                product.deleted = true;
                await grooveDatabase.collection("products").doc(product.id).set(product);
                Vue.$toast.open({
                  message: "Product Deleted.",
                  type: "success",
                  position: "top-right"
                });
              }
            }
          }
      );
    },
    addProduct: async function() {

      const now = moment().valueOf();

      this.currentProduct = {
        code: null,
        deleted: false,
        lastUpdated: now,
        name: null,
        timestamp: now,
        uom: null
      };
    }
  },
  async mounted() {
    await this.$bind('products', grooveDatabase.collection("products").where("deleted", "==", false).orderBy('name'));
    const grid = new Muuri('.grid', {dragEnabled:false});
    console.log(grid);
  }
}
</script>

<style scoped>

.grid {
  position: relative;
}
.item {
  display: block;
  position: absolute;

  margin: 5px;
  z-index: 1;
  background: transparent;
  color: #fff;
}
.item.muuri-item-dragging {
  z-index: 3;
}
.item.muuri-item-releasing {
  z-index: 2;
}
.item.muuri-item-hidden {
  z-index: 0;
}
.item-content {
  position: relative;
  width: 100%;
  height: 100%;
}

</style>
