<template>
  <i-container
    class="_text-left _height-100"
    fluid
  >
    <i-row class="_height-100">
      <i-column
        id="entries"
        xs="12"
      >
        <i-button
          size="sm"
          style="margin-top: 20px;"
          @click="hide"
        >
          Go back to Exports...
        </i-button>
        <table class="wrapping-table">
          <div
            v-for="(eee, key) in grouped_data"
            :key="key"
          >
            <tr v-if="renderWorkerName(key)">
              <td
                class="outer"
                style="padding-top: 50px; width: 120px; vertical-align: top; font-weight: bold;"
              >
                {{ renderWorkerName(key) }}
              </td>
              <td
                class="outer"
                style="padding-top: 50px; vertical-align: top"
              >
                <table class="ddd">
                  <tr>
                    <th
                      class="date"
                      style="padding-bottom: 20px;"
                    >
                      Date
                    </th>
                    <th style="padding-bottom: 20px;">
                      Entry Type
                    </th>
                    <th style="padding-bottom: 20px;">
                      Cost Centre
                    </th>
                    <th style="padding-bottom: 20px;">
                      MYOB Code
                    </th>
                    <th style="padding-bottom: 20px;">
                      Description
                    </th>
                    <th style="padding-bottom: 20px;">
                      Status
                    </th>
                    <th style="text-align: right; padding-bottom: 20px;">
                      Hours
                    </th>
                  </tr>
                  <tr
                    v-for="(v,k) in eee.data"
                    :key="k"
                  >
                    <td class="date">
                      {{ renderDate(v.date) }}
                    </td>
                    <td>{{ v.entry_type }}</td>
                    <td>{{ v.cost_centre_name }}</td>
                    <td>{{ v.myob_code }}</td>
                    <td>{{ v.description }}</td>
                    <td>{{ v.status }}</td>
                    <td style="text-align: right;">
                      {{ parseFloat(v.hours).toFixed(2) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="date" />
                    <td />
                    <td />
                    <td />
                    <td style="font-weight: bold">
                      Subtotal
                    </td>
                    <td style="font-weight: bold; text-align: right;">
                      {{ parseFloat(eee.subtotal).toFixed(2) }}
                    </td>
                  </tr>
                </table>
              </td>
              <div class="pagebreak"/>
            </tr>
          </div>
        </table>
      </i-column>
    </i-row>
  </i-container>
</template>

<script>
    const entry_type_leave = '6c284d6e-7b50-4329-acab-5687417a7bbb';
    const entry_type_unpaid = 'b933f35b-a04e-4a6b-827c-b29e1f39093d';

    export default {
        name: "Audit",
        components: {},
        props: {
          selectedSummaries: {
            type: Array,
            default: null
          },
          workers: {
            type: Array,
            default: () => { return [];}
          },
          hideAuditReport: {
              type: Object,
            default: null
          }
        },
        data: function () {
            return {
                grouped_data: {}
            }
        },
        watch: {
          selectedSummaries: {
                immediate: true,
                handler: function() {
                  const _this = this;
                  const workerIds = [...new Set(this.selectedSummaries.map(summary => summary.workerId))];
                  for (const id of workerIds) {
                    const summaries = this.selectedSummaries.filter(summary => summary.workerId === id);

                    _this.grouped_data[id] = {
                      subtotal: 0,
                      data: []
                    }

                    const type_map = {};

                    let subtotal = 0;

                    summaries.forEach(function(summary) {
                      for (const entry of summary.entries) {
                        if ( entry.tet.uuid !== entry_type_unpaid) {

                          // TODO add time entry type myob code and category as prefix to cost centre
                          // TODO myob code

                          let costCentre;
                          let myobCode;
                          console.log(entry);
                          console.log(entry.cost_centre_code);
                          if (entry.is_vehicle && entry.cost_centre_code) {
                            costCentre = entry.cost_centre_code.identifier;
                            myobCode = `${entry.tet.myob_code || ""}${entry.cost_centre_code.costCentre}`;
                          } else if (entry.cost_centre_code) {
                            costCentre = entry.cost_centre_code ? entry.cost_centre_code.name : "COST CENTRE ISSUE";
                            myobCode = `${entry.tet.myob_code || ""}${entry.cost_centre_code.code}`;
                          } else {
                            costCentre = "Invalid Time Entry";
                            myobCode = "Invalid Time Entry"
                          }

                          const tm_key = summary.date.toMillis() + '-' + entry.tet.uuid + '-' + myobCode;
                          const hours = entry.time_entry_type === entry_type_leave ? 0 : entry.hours_worked / 3600;

                          if ( ! type_map[tm_key] ) {
                            type_map[tm_key] = {
                              date: summary.date,
                              entry_type: entry.tet.name || "INVALID TIME ENTRY TYPE",
                              cost_centre_name: costCentre,
                              hours: hours,
                              description: entry.description,
                              myob_code: myobCode,
                              status: entry.status
                            }
                          }

                          else {
                            /* increment the hours worked */
                            type_map[tm_key].hours = type_map[tm_key].hours + hours;
                            type_map[tm_key].description = type_map[tm_key].description + ', ' + entry.description;
                          }

                          subtotal = subtotal + hours;

                        }
                      }
                    });

                    Object.keys(type_map).forEach(function(tm) {
                      _this.grouped_data[id].data.push(type_map[tm]);
                    });
                    _this.grouped_data[id].subtotal = subtotal;
                  }


                  /*Object.keys(this.selectedSummaries.data).forEach(function (key) {

                        /!* need to group entries by type *!/
                        _this.grouped_data[key] = {
                            subtotal: 0,
                            data: []
                        }

                    let subtotal = 0;

                    Object.keys(_this.computedData.data[key].data).forEach(function (day) {

                      const type_map = {};

                      const date = day.split(/-/)[0];

                      _this.computedData.data[key].data[day].entries.forEach(function(entry) {

                                if ( entry.tet.uuid !== entry_type_unpaid) {

                                  // TODO add time entry type myob code and category as prefix to cost centre
                                  // TODO myob code

                                  let costCentre;
                                  let myobCode;
                                  console.log(entry);
                                  console.log(entry.cost_centre_code);
                                  if (entry.is_vehicle && entry.cost_centre_code) {
                                    costCentre = entry.cost_centre_code.identifier;
                                    myobCode = `${entry.tet.myob_code || ""}${entry.cost_centre_code.costCentre}`;
                                  } else if (entry.cost_centre_code) {
                                    costCentre = entry.cost_centre_code ? entry.cost_centre_code.name : "COST CENTRE ISSUE";
                                    myobCode = `${entry.tet.myob_code || ""}${entry.cost_centre_code.code}`;
                                  } else {
                                    costCentre = "Invalid Time Entry";
                                    myobCode = "Invalid Time Entry"
                                  }

                                  const tm_key = date + '-' + entry.tet.uuid + '-' + myobCode;
                                  const hours = entry.time_entry_type === entry_type_leave ? 0 : entry.hours_worked / 3600;

                                  if ( ! type_map[tm_key] ) {
                                        type_map[tm_key] = {
                                            date: date,
                                            entry_type: entry.tet.name || "INVALID TIME ENTRY TYPE",
                                            cost_centre_name: costCentre,
                                            hours: hours,
                                            description: entry.description,
                                            myob_code: myobCode,
                                            status: entry.status
                                        }
                                    }

                                    else {
                                        /!* increment the hours worked *!/
                                        type_map[tm_key].hours = type_map[tm_key].hours + hours;
                                        type_map[tm_key].description = type_map[tm_key].description + ', ' + entry.description;
                                    }

                                    subtotal = subtotal + hours;

                                }


                            });

                            Object.keys(type_map).forEach(function(tm) {
                                _this.grouped_data[key].data.push(type_map[tm]);
                            });
                            _this.grouped_data[key].subtotal = subtotal;
                        });

                    });*/

                }

            },
        },
        methods: {
            hide() {
              this.$emit("hideAuditReport")
            },
            renderDate(luxonDate) {
              return luxonDate.toFormat("dd/LLL/yyyy");
              /**console.log("RENDER DATE");
              console.log(v);
                if (v) {
                  const d = v.split(/-/)[0];
                  if (d) {
                        return DateTime.fromMillis(+d).toFormat("dd/LLL/yyyy");
                    }
                }*/
            },
             renderWorkerName(workerId) {
              console.log(`WORKER: ${workerId}`);
                if (workerId) {
                  const w = this.workers.find(function (worker) {
                    return worker.id === workerId
                  });
                  if ( w ) {
                        return w.displayName;
                    }
                }
            },
       },
    }
</script>

<style scoped>

@media print {
  .pagebreak { page-break-after: always; }
}


table.wrapping-table { font-size: 0.8em; width: 100%; }
table.wrapping-table td.outer { padding: 10px; vertical-align: top;  }
table.wrapping-table td.outer { padding: 10px; vertical-align: top;  }
table.wrapping-table table { border-bottom: 1px solid #999; }
table.wrapping-table table td, table.wrapping-table table th { width: 200px; }
table.wrapping-table table tr:last-child td:last-child {  }

table.wrapping-table table td.date, table.wrapping-table table th.date { width: 100px; }
table.wrapping-table table td.other, table.wrapping-table table th.other { width: 140px; }

</style>