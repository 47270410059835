<template>
  <tr>
    <td class="time_entry_type_label">
      <i-input
        v-model="currentSupplier.name"
        size="sm"
      />
    </td>
    <td class="myob_code">
      <i-input
        v-model="currentSupplier.id"
        size="sm"
      />
    </td>
    <td class="myob_code">
      <i-input
        v-model="currentSupplier.email"
        size="sm"
      />
    </td>
    <td class="myob_category">
      <i-input
        v-model="currentSupplier.address"
        size="sm"
      />
    </td>
    <td class="myob_category">
      <i-input
        v-model="currentSupplier.address2"
        size="sm"
      />
    </td>
    <td class="myob_allowance_code">
      <i-input
        v-model="currentSupplier.city"
        size="sm"
      />
    </td>
    <td class="myob_allowance_code">
      <i-button
        type="submit"
        size="sm"
        @click="save"
      >
        Save
      </i-button>
    </td>
  </tr>
</template>

<script>
    import {grooveDatabase} from "@/database";
    import Vue from "vue";

    export default {
        name: "Supplier",
        props: {
          supplier: {
            type: Object,
            default: null
          }
        },
        data: function () {
            return {
                selected: [],
                colour: '#000000'
            }
        },
        watch: {
        },
        methods: {
          async save() {
                try {
                    await grooveDatabase.collection("suppliers").doc(this.supplier.id).set(this.supplier);
                  Vue.$toast.open({
                    message: "Supplier Updated.",
                    type: "success",
                    position: "top-right"
                  });
                } catch(error) {
                    console.log(JSON.stringify(error));
                }
            },
        },
      computed: {
          currentSupplier() {
            return this.supplier;
          }
      }
    }
</script>

<style scoped>
    .kind { max-width: 200px; display: inline-block; margin-right: 10px; text-transform: capitalize; }
    .kind input { text-transform: capitalize; }

    td.time_entry_type_label { width: 300px; }
    td.color, td.icon, td.myob_allowance_code, td.myob_category, td.myob_code { width: 120px; }
    td.color, td.icon, td.myob_allowance_code, td.myob_category, td.myob_code { width: 120px; }
</style>