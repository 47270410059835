// Pads minutes to 2 numbers if returned value less than 10.
export function pad(num: number) {
    return ("0" + num).slice(-2);
}

// Provides a human readable representation of seconds.
export function formatSeconds(seconds: number){
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    const hours = Math.floor(minutes / 60)
    minutes = minutes % 60;
    return `${(hours)}h ${pad(minutes)}m`;
}