<template>
  <div class="hero-body is-vcentered">
    <div class="card">
      <div class="card-header-title is-centered">
        <h1 v-if="this.$root.user">Hi {{ this.$root.user.displayName }} ! Welcome to Groove  <font-awesome-icon
            icon="thumbs-up"
        /></h1>
      </div>
      <div class="card-content">
        <p> Version: {{ version }}.{{ hash }}</p>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
      name: "HomeVue",
      data: function() {
        return {
          version: process.env.VUE_APP_VERSION,
          hash: process.env.VUE_APP_GIT_REVISION
        }
      }
    }
</script>

<style scoped>

</style>