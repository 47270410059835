<template lang="html">
  <div class="container">
    <div id="firebaseui-auth-container" />
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth";
import * as firebaseui from 'firebaseui'
    import {grooveAuthentication} from "@/database";
    export default {
        name: 'Auth',
        mounted() {
            const uiConfig = {
                //Start it here
                credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
                //End it here
                signInFlow: 'popup',
                signInSuccessUrl: '/',
                signInOptions: [
                    firebase.auth.EmailAuthProvider.PROVIDER_ID
                ],
                // accountChooserEnabled: false
            };
            const ui = new firebaseui.auth.AuthUI(grooveAuthentication);
            ui.start('#firebaseui-auth-container', uiConfig);
        },
    }
</script>
<style scoped>
</style>