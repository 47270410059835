<template>
  <b-collapse animation="slide" v-if="isVisible" v-model="isOpen" aria-id="contentIdForFilters" class="mt-4">

    <div class="level"
         aria-controls="contentIdForFilters"
         slot="trigger"
         inputmode="button">
      <div class="level-left">
        <b-tooltip type="is-light" :append-to-body="true" label="Show/Hide filters" square>
          <font-awesome-icon v-if="!filtersActive"
                             icon="filter"
                             size="1x"
          />
          <font-awesome-icon v-if="filtersActive"
                             icon="filter"
                             size="1x"
                             style="color: #48c774;"
          />
        </b-tooltip>
      </div>
    </div>

    <div class="container pl-0 pr-0">
      <div class="card mt-2">
        <div class="section has-text-left pt-2 pb-2">
          <b-field custom-class="is-small" grouped label="Staff">
            <b-field position="is-centered" expanded>
              <b-dropdown
                  :append-to-body="true"
                  size="is-small"
                  v-model="selected_depots"
                  multiple
                  aria-role="list">
                <b-button icon-pack="far" icon-left="warehouse" size="is-small" class="button" type="button" slot="trigger">
                  <span>Depots ({{ selected_depots.length }})</span>
                  <b-icon icon="chevron-down"></b-icon>
                </b-button>
                <b-dropdown-item v-for="depot of depots" :value="depot.id" :key="depot.id" aria-role="listitem">
                  <span>{{ depot.name }}</span>
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown
                  :append-to-body="true"
                  size="is-small"
                  v-model="selected_roles"
                  multiple
                  aria-role="list">
                <b-button icon-pack="far" icon-left="user-tag" size="is-small" class="button" type="button" slot="trigger">
                  <span>Roles ({{ selected_roles.length }})</span>
                  <b-icon icon="chevron-down"></b-icon>
                </b-button>
                <b-dropdown-item v-for="role of roles" :value="role.key" :key="role.key" aria-role="listitem">
                  <span>{{ role.name }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
          </b-field>
          <b-field custom-class="is-small" grouped>
            <b-field expanded position="is-centered">
              <b-dropdown
                  :append-to-body="true"
                  size="is-small"
                  v-model="selected_divisions"
                  multiple
                  aria-role="list">
                <b-button icon-pack="far" icon-left="building" size="is-small" class="button" type="button" slot="trigger">
                  <span>Divisions ({{ selected_divisions.length }})</span>
                  <b-icon icon="chevron-down"></b-icon>
                </b-button>
                <b-dropdown-item v-for="division of divisions"
                                 :value="division.id"
                                 :key="division.id" aria-role="listitem">
                  <span>{{ division.name }}</span>
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown></b-dropdown>
            </b-field>
          </b-field>

        </div>
        <div class="section has-text-left pt-0 pb-4">
          <b-field custom-class="is-small" grouped position="is-centered" label="Entries">
            <b-field custom-class="is-small">
              <b-dropdown
                  :append-to-body="true"
                  size="is-small"
                  v-model="selected_time_entry_type"
                  aria-role="list">
                <b-button icon-pack="far" icon-left="clock" size="is-small" class="button" type="button" slot="trigger">
                  <span v-if="selected_time_entry_type">{{ getTypeName(selected_time_entry_type) }}</span>
                  <span v-if="selected_time_entry_type === null || selected_time_entry_type === undefined">Type</span>
                  <b-icon icon="chevron-down"></b-icon>
                </b-button>
                <b-dropdown-item v-for="type of time_entry_types_available" :value="type.uuid" :key="type.name" aria-role="listitem">
                  <span>{{ type.name }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
            <b-field expanded custom-class="is-small">
              <b-autocomplete
                  size="is-small"
                  :append-to-body="true"
                  v-model="selected_cost_centre"
                  placeholder="Cost Centre e.g. 3216, Admin"
                  :open-on-focus="true"
                  :data="cost_centres_available"
                  field="name"
                  :clearable="true"
              >
              </b-autocomplete>
            </b-field>
          </b-field>

        </div>
        <div class="section pt-0 pb-3">
          <b-field position="is-right" grouped custom-class="is-small">
            <b-field custom-class="is-small">
              <b-icon pack="far" icon="robot"/>
              <b-tooltip type="is-light" size="is-small" :append-to-body="true" label="Show Auto Jobs" square>
                <b-switch position="is-right" size="is-small" v-model="showAuto" @input="autoCheckboxUpdated"/>
              </b-tooltip>
              <b-tooltip type="is-light" size="is-small" :append-to-body="true" label="Clear Filters" square>
                <b-button size="is-small" icon-right="broom" @click="clearFilters"/>
              </b-tooltip>
            </b-field>
          </b-field>
        </div>
      </div>
    </div>
  </b-collapse>

</template>

<script>

import {grooveDatabase} from '@/database'

export default {
  name: "Filters",
  props: {
    currentWorker: {
      type: String,
      default: null
    },
    // Determines whether the filter should be visible in the dom.
    isVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showAuto: false,
      selected_cost_centre: null,
      selected_time_entry_type: null,
      selected_vehicle: null,
      selected_roles: [],
      selected_depots: [],
      selected_divisions: [],
      depots: [],
      divisions: [],
      roles: [{name: 'Driver & Dispatch', key: 'driver'}, {name: 'Admin', key: 'admin'}, {
        name: 'Workshop',
        key: 'workshop'
      }, {name: 'Yardman', key: 'yardman'}],
      cost_centres: [],
      cost_centres_available: [],
      vehicles: [],
      time_entry_types: [],
      time_entry_types_available: [],
      filter: {
        depot: null,
        division: null,
        cost_centre: null,
        time_entry_type: null,
        vehicles: null,
        role: null,
        worker: null,
        showAuto: false
      },
      isOpen: false
    }
  },

  firestore() {
    return {
      cost_centres: grooveDatabase.collection("cost_centres").orderBy('name'),
      vehicles: grooveDatabase.collection("vehicles").where("deleted", "==", false).orderBy("registrationNumber", "asc"),
      time_entry_types: grooveDatabase.collection("time_entry_types").orderBy('name'),
      depots: grooveDatabase.collection("depots").orderBy('name'),
      divisions: grooveDatabase.collection("divisions").orderBy('name'),
    }
  },

  watch: {
    selected_depots(depots) {
      this.filter.depot = depots;
      this.$emit('changeFilter', this.filter);
    },
    selected_roles(roles) {
      this.filter.role = roles
      this.$emit('changeFilter', this.filter);
    },
    selected_divisions(divisions) {
      this.filter.division = divisions
      this.$emit('changeFilter', this.filter)
    },
    selected_cost_centre(value) {
      if (!value) {
        this.filter.cost_centre_array = null;
        return;
      }
      if (value === "All cost centres") {
        value = null;
      } else {
        var array_of_uuids = [];
        var searchString = new RegExp(value);
        this.cost_centres.forEach(function (cc) {
          if (cc.name.match(searchString)) {
            if (!array_of_uuids.find(function (v) {
              return v === cc.uuid
            })) {
              if (array_of_uuids.length < 10) {
                array_of_uuids.push([cc.name, cc.uuid]);
              }
            }
          }
        });

        this.vehicles.forEach(function (vehicle) {
          if (vehicle.registrationNumber && vehicle.registrationNumber.match(searchString)) {
            if (!array_of_uuids.find(function (v) {
              return v === vehicle.id
            })) {
              if (array_of_uuids.length < 10) {
                array_of_uuids.push([vehicle.registrationNumber, vehicle.id]);
              }
            }
          }
        });

        this.vehicles.forEach(function (vehicle) {
          if (vehicle.identifier && vehicle.identifier.match(searchString)) {
            if (!array_of_uuids.find(function (v) {
              return v === vehicle.id
            })) {
              if (array_of_uuids.length < 10) {
                array_of_uuids.push([vehicle.identifier, vehicle.id]);
              }
            }
          }
        });

        if (array_of_uuids.length) {
          this.filter.cost_centre_array = array_of_uuids;
        } else {
          this.filter.cost_centre_array = null;
        }
      }

      this.$emit('changeFilter', this.filter);
    },
    selected_time_entry_type(value) {

      var _this = this;

      if (value === "All types") {
        value = null;
      }
      this.filter.time_entry_type = value;

      /* filter cost centres by time entry type */
      if (this.filter && this.filter.time_entry_type) {
        var tt = _this.time_entry_types.find(function (tet) {
          return tet.uuid === _this.filter.time_entry_type
        });
        if (tt) {
          this.cost_centres_available = _this.cost_centres.filter(function (cc) {
            if (_this.filter && _this.filter.role) {
              return cc.roles[_this.filter.role] && tt.name === cc.kind;
            } else {
              return tt.name === cc.kind;
            }
          });
        } else {
          this.cost_centres_available = _this.cost_centres.filter(function (cc) {
            if (_this.filter && _this.filter.role) {
              return cc.roles[_this.filter.role] && tt.name === cc.kind;
            } else {
              return true;
            }
          });
        }
      } else {
        this.cost_centres_available = _this.cost_centres.filter(function (cc) {
          if (_this.filter && _this.filter.role) {
            return cc.roles[_this.filter.role] && tt.name === cc.kind;
          } else {
            return true;
          }
        });
      }


      this.$emit('changeFilter', this.filter);
    },
    selected_vehicle(value) {
      if (value === "All vehicles") {
        value = null;
      }
      this.filter.vehicles = value;
      this.$emit('changeFilter', this.filter);
    },
    selected_division(value) {
      this.filter.division = value;
      this.$emit('changeFilter', this.filter);
    },
    selected_depot(value) {
      this.filter.depot = value;
      this.$emit('changeFilter', this.filter);
    },
    selected_role(value) {

      var _this = this;
      this.filter.role = value;

      /* the role has changed - so the time entry types have to change */
      this.time_entry_types_available = this.time_entry_types.filter(function (tet) {
        return (_this.filter.role.filter(r => tet.roles && r in tet.roles && tet.roles[r] === true)).length;
      });

      /* we have a time entry type filter and a role - now filter cost centres */
      if (this.filter && this.filter.time_entry_type) {

        /* return the time_entry_type object */
        var tt = _this.time_entry_types.find(tet => tet.uuid === _this.filter.time_entry_type);

        if (tt) {
          this.cost_centres_available = _this.cost_centres.filter(cc =>
              /* return if the current role filter matches or the time_entry_type matches the cost centre kind */
              (_this.filter.role.filter(r => cc.roles && r in cc.roles && cc.roles[r] === true)).length || tt.name === cc.kind
          );
        }
      }
      /* only a role */
      else {
        this.cost_centres_available = _this.cost_centres.filter(cc =>
            /* return if the current role filter matches */
            (_this.filter.role.filter(r => cc.roles && r in cc.roles && cc.roles[r] === true)).length
        );
      }

      this.$emit('changeFilter', this.filter);
    },
    time_entry_types() {
      this.time_entry_types_available = this.time_entry_types.filter(function (tet) {
        var visible = false;
        if (tet.roles) {
          Object.keys(tet.roles).forEach(function (role) {
            if (tet.roles[role]) {
              visible = true;
            }
          });
        }

        return visible;
      });
    },
    time_entry_types_available: {
      immediate: true,
      handler: function () {

        var _this = this;

        /* filter cost centres by time entry type */
        if (this.filter && this.filter.time_entry_type) {
          var tt = _this.time_entry_types.find(function (tet) {
            return tet.uuid === _this.filter.time_entry_type
          });
          if (tt) {
            this.cost_centres_available = _this.cost_centres.filter(function (cc) {
              if (_this.filter && _this.filter.role) {
                return cc.roles[_this.filter.role] && tt.name === cc.kind;
              } else {
                return tt.name === cc.kind;
              }
            });
          }
        }
      }
    },
    currentWorker(value) {
      this.filter.worker = value;
      this.$emit('changeFilter', this.filter);
    },
    isOpen: {
      immediate: true,
      deep: true,
      handler: function(value) {
        this.$emit('filtersOpen', value);
      }
    }
  },
  computed: {
    filtersActive: function() {
      return (this.selected_divisions.length > 0)
          ||(this.selected_roles.length > 0)
          || (this.selected_depots.length > 0)
          || this.selected_cost_centre
          || this.selected_time_entry_type;
    }
  },
  methods: {
    autoCheckboxUpdated: function () {
      this.filter.showAuto = this.showAuto;
      this.$emit('changeFilter', this.filter);
    },
    clearFilters: function () {
      this.filter = {
        depot: null,
        division: null,
        cost_centre: null,
        time_entry_type: null,
        vehicles: null,
        role: null,
        worker: this.currentWorker,
        showAuto: false
      };
      this.selected_cost_centre = null;
      this.selected_divisions = [];
      this.selected_roles = [];
      this.selected_depots = [];
      this.selected_time_entry_type = null;
      this.$emit('changeFilter', this.filter);
    },
    selectscc: function (v) {
      this.selected_cost_centre = v;
    },
    showRoleMenu: function () {
      var e = document.getElementById("roleMenu").style.display;
      if (e === 'block') {
        document.getElementById("roleMenu").style.display = 'none';
      } else {
        document.getElementById("roleMenu").style.display = 'block';
      }
    },
    getTypeName: function (id) {
      const type = this.time_entry_types_available.filter(type => type.uuid === id)[0];
      return type.name;
    }
  }

}
</script>

<style scoped>

    .dropdown-box div, button {
      width: 100%;
    }

    .select:after {
        color: red !important;
    }

    /* inkline color is wrong */
    .list-group.filters div.item {
        margin: 0px;
        padding: 5px;
        font-size: 0.8em;
    }

    .role-placeholder, .division-placeholder, .depot-placeholder, .ccx-placeholder {
        width: calc(100% - 1px);
        position: relative;
        cursor: pointer;
        font-size: 12.8px;
        margin-top: 3px;
        margin-bottom: 3px;
        color: #888;
        background-color: #fff;
        border-radius: 4px;
        border-color: red;
        border-top-width: 5px;

        line-height: 24px;
        padding: 6.4px 12.8px 6.4px 12.8px;
    }

    .role, .division, depot {
        width: 100%;
        cursor: pointer;
        font-size: 12.8px;
        padding: 0px;
        background-color: red;
    }

    .role > div, .division > div, .depot > div {
        width: 100%;
        cursor: pointer;
        font-size: 12.8px;
        padding: 10px;
        background-color: white;
    }

    .role-placeholder:after, .division-placeholder:after, .depot-placeholder:after, .cc-placeholder:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 20px;
        margin-top: -2px;
        z-index: 1;
        font-size: 12px;

        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #000;


    }

    .clear-filters { margin-top: 3px; }
    .cc-placeholder-wrapper { margin-top: 3px; }
    #ccMenu div { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
</style>
