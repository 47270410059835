<template>
  <div class="container">
    <b-field>
      <b-dropdown
          class="is-small"
          :append-to-body="true"
          v-model="selected"
          multiple
          aria-role="list">
        <template #trigger>
          <b-button
              size="is-small"
              type="is-primary"
              icon-pack="far"
              icon-right="angle-down">
            Roles ({{ selected.length }})
          </b-button>
        </template>
        <b-dropdown-item v-bind:key="role.value" v-for="role in availableRoles" :value="role.value" aria-role="listitem">
          <span>{{ role.name }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-field>

    <b-button size="is-small" @click="save">Save</b-button>
  </div>
</template>

<script>
    import {grooveDatabase} from "../database";

    export default {
        name: "UserRole",
        props: {
          user: {
            type: Object,
            default: null
          }
        },
        data: function () {
            return {
                selected: [],
                availableRoles: [
                  {
                    name: 'Admin',
                    value: 'admin'
                  },
                    {
                      name: 'Driver',
                      value: 'driver'
                    },
                  {
                    name: 'Yardman',
                    value: 'yardman'
                  },
                  {
                    name: 'Workshop',
                    value: 'workshop'
                  },
                  {
                    name: 'Customer',
                    value: 'customer'
                  },
                  {
                    name: 'Purchase Order',
                    value: 'purchaseorder'
                  },
                  {
                    name: 'Leave',
                    value: 'leave'
                  },
                  {
                    name: 'Payroll',
                    value: 'payroll'
                  }
                ]
            }
        },
        watch: {
           user: {
                immediate: true,
                handler: function(newVal) {
                    var _this = this;
                    _this.selected = [];
                    Object.keys(newVal.roles).forEach(function(key) {
                        if (newVal.roles[key] === true) {
                            _this.selected.push(key);
                        }
                    });
                }
            }
        },
        methods: {
            save() {
                var _this = this;
                var keys = ['driver', 'admin', 'yardman', 'workshop', 'customer', 'purchaseorder', 'leave', 'payroll'];
                var keys_to_set = {}
                keys.forEach(function(key) {
                    if ( _this.selected.includes(key) ) {
                        keys_to_set[key] = true;
                    }
                    else {
                        keys_to_set[key] = false;
                    }
                });
                grooveDatabase.collection("users").doc(this.user.id).update({ roles: keys_to_set });
            }
        }
    }
</script>

<style scoped>

</style>