<template>
  <div>
    <div
      v-for="order in orders"
      :key="order.po_number"
      class="order"
    >
      <div
        class="_align-content-left"
        style="text-align: left; height: 280px; padding-bottom: 20px; "
      >
        <img
          style="height: 60px; float: left; margin-left: 0px;  margin-top: 20px;"
          src="/images/rtl-logo-naked.png"
        >
        <div class="po-title">
          <h2>Purchase Order Authority</h2>
          <h3>PO Number: {{ order.po_number }}</h3>
          <h3>Date: {{ renderDate(order.timestamp) }}</h3>
        </div>
        <div
          class="_clearfix"
          style="clear: left; margin-top: 20px;"
        />
        <p><b>Ship To / Supply To:</b></p>
        <p>Road Transport Logistics Ltd</p>
        <p>PO Box 9</p>
        <p>Tapanui 9542</p>
        <p>Phone: 0800 224 661</p>
        <p>E-mail: enquiries@roadtrans.co.nz</p>
        <div
          class="_clearfix"
          style="clear: left; margin-top: 20px;"
        />
      </div>

      <div
        class="_align-content-left"
        style="clear: left;  text-align: left; height: 140px; padding-bottom: 20px; "
      >
        <div
          class="_clearfix"
          style="clear: left; margin-top: 20px;"
        />
        <p><b>Vendor:</b></p>
        <p>{{ displaySupplier(order) }}</p>
        <p>{{ displaySupplierAddress(order) }}</p>
        <div
          class="_clearfix"
          style="clear: left; margin-top: 20px;"
        />
      </div>


      <div
        class="_clearfix"
        style="border-top: 2px solid #ccc; clear: both; margin-top: 20px;"
      >
        <table style="text-align: left; width: 100%;">
          <tr>
            <th>Asset Number</th>
            <th>By</th>
            <th>Description</th>
            <th style="text-align: right;">
              Price
            </th>
          </tr>
          <tr>
            <td>{{ displayAssetNumber(order) }}</td>
            <td>{{ order.username }}</td>
            <td>{{ order.description }}</td>
            <td style="text-align: right;">
              {{ displayPrice(order) }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="footer" />
  </div>
</template>

<script>

    import moment from 'moment'

    import {grooveDatabase} from "../../database";

    export default {
        name: "PurchaseOrderDetails",
        props: [],

        data: function() {
            return {
                suppliers: [],
                vehicles: [],
                orders: [],
                orders_hash: null
            }
        },

        created() {
          console.log("CREATED");
            const urlParams = new URLSearchParams(window.location.search);
            console.log(urlParams);
            this.orders_hash = urlParams.get('orders').split(/,/);
            this.$bind("orders", grooveDatabase.collection("purchase_orders").where("po_number", "in", this.orders_hash)).then(function() {
                window.print();
            });
        },

        firestore: {
            'suppliers': grooveDatabase.collection('suppliers'),
            'vehicles': grooveDatabase.collection('vehicles')
        },

        methods: {
            renderDate(epoch) {
                return moment(epoch).format("YYYY/MM/DD");
            },
            displayAssetNumber(order) {
                var vehicle = this.vehicles.find(vehicle => {
                    return vehicle.uuid === order.asset_number
                });
                if (vehicle) {
                    return vehicle.identifier;
                }
                return "Unknown Asset Number";
            },
            displaySupplier(order) {
                var supplier = this.suppliers.find(supplier => {
                    return supplier.id === order.supplier
                });
                if (supplier) {
                    return supplier.name;
                }
                return "Unknown Supplier";
            },
            displaySupplierAddress(order) {
                var supplier = this.suppliers.find(supplier => {
                    return supplier.uuid === order.supplier
                });
                if (supplier && supplier.address) {
                    return supplier.address;
                }
                return "";
            },
            displayPrice(order) {
                return order.price ? "$" + order.price.toFixed(2) : "$0.00";
            },
        }

    }
</script>

<style scoped>
    p { font-size: 0.8em; }

    div.po-title { text-align: right; margin-top: 20px; float:right; font-size: 1em; font-weight: bold; }
    div.po-title h2 { font-size: 1.2em; font-weight: bold; line-height: 1.8em; padding: 0px; margin: 0px;}
    div.po-title h3 { font-size: 1em; line-height: 1.5em; font-weight: bold; padding: 0px; margin: 0px;}
    div.order {
        width: 21cm;
        height: 29.7cm;
        /*margin: 30mm 45mm 30mm 45mm;*/
    }

    div.order p { line-height: 1em; }

    table tr { border-bottom: 1px solid #ccc;}
    table td, table th { padding: 10px; }
</style>
