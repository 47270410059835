<template>
  <div class="container is-fluid mt-5 pl-0 pr-0">
    <div class="columns is-variable is-1">
      <div class="column is-narrow pt-0 mt-2">
        <div class="card">
          <div id="worker_content" class="section pt-0">
            <b-tooltip :delay="500" v-if="expandWorkers" :append-to-body="true" type="is-light" label="Minimise Staff List" square style="position: absolute; right:8px; top: 8px;">
              <b-button  @click="toggleExpanded" size="is-small" icon-pack="far" icon-right="arrow-left"/>
            </b-tooltip>
            <b-tooltip :delay="500" v-if="!expandWorkers" :append-to-body="true" type="is-light" label="Maximise Staff List" square style="position: absolute; right:8px; top: 8px;">
              <b-button @click="toggleExpanded" size="is-small" icon-pack="far" icon-right="arrow-right"/>
            </b-tooltip>
            <div v-if="!expandWorkers" style="height: 16px" class="mt-4"/>
            <Filters
                :is-visible="expandWorkers"
                :current-worker="currentWorker"
                @changeFilter="updateCurrentFilter"
                @filtersOpen="updateFilterStatus"
            />
            <Workers
                :loaded="false"
                :filtersOpen="filtersOpen"
                :workers="workers"
                :expanded="expandWorkers"
                :current-worker="currentWorker"
                :found-workers="foundWorkers"
                :start-of-week="startOfWeek"
                :current-filter="currentFilter"
                :current-depot="currentDepot"
                @changeWorker="updateCurrentWorker"
            />
          </div>
        </div>
      </div>
      <div class="column pt-0 mt-2">
        <entry-table
            :current-worker="currentWorker"
            :time-entries-loaded="timeEntriesLoaded"
            :vehicles="vehicles"
            :current-filter="currentFilter"
            :time-entry-types="timeEntryTypes"
            :current-entry="currentEntry"
            :current-entries="timeEntries"
            @changeWeek="updateCurrentWeek"
            @changeEntry="updateCurrentEntry"/>
      </div>
      <b-sidebar
          class="details-bar"
          type="is-light"
          :fullheight="true"
          :fullwidth="false"
          :overlay="false"
          :right="true"
          v-model="hasCurrentEntry"
          :can-cancel="canCancelEdit"
      >
        <div
            v-bind:class="{ 'is-hidden': !currentEntry }"
            class="column p-0 mt-2">
          <EntryDetails
              :current-entry="currentEntry"
              :workers="workers"
              :vehicles="vehicles"
              :trailers="trailers"
              :time-entry-types="timeEntryTypes"
              :cost-centres="costCentres"
              @changeEntry="updateCurrentEntry"
              @canCancelEdit="cancelEvent"
          />
        </div>
      </b-sidebar>
    </div>
  </div>
</template>

<script>

import Workers from "../Workers";
import EntryTable from "./EntryTable";
import EntryDetails from "./EntryDetails";
import Filters from "../Filters";
import { DateTime } from "luxon";
import {generate_filter} from "@/utilities/timeEntries";
import {grooveDatabase} from "@/database";

export default {
  name: "Timesheets",
  components: {
    EntryDetails,
    Workers,
    EntryTable,
    Filters
  },
  data: function () {
    return {
      loaded: false,
      currentDepot: null,
      currentWorker: null,
      currentEntry: null,
      hasCurrentEntry: false,
      filtersOpen: false,
      currentFilter: {

      },
      expandDetails: true,
      // Toggles between full name and initials.
      expandWorkers: true,
      expandMenu: false,
      timeEntries: [],
      timeEntriesLoaded: false,
      foundWorkers: {},
      today: DateTime.now().endOf('day'),
      startOfWeek: DateTime.now().startOf('week'),
      end_of_week: DateTime.now().endOf('week'),
      /* after much consideration, there doesn't seem to be any other choice, but to bind a query to return all the time entries for everyone within a time period - simply to */
      /* determine there status */
      allEntriesForPeriod: [],
      timeEntryTypes: [],
      vehicles: [],
      trailers: [],
      costCentres: [],
      workers: [],
      canCancelEdit: true
    }
  },

  watch: {
    currentFilter: {
      immediate: true,
      deep: true,
      handler: async function () {
        if (!this.currentWorker) {
          return;
        }

        let filterArgs = generate_filter(this.currentFilter);

        let prepareQuery = grooveDatabase.collection("time_entries");

        // Does this even work - apparently yes - iterate over each filter and append!
        for (let i = 0; i < filterArgs.length; i++) {
          prepareQuery = prepareQuery.where(...filterArgs[i]);
        }

        await this.$bind('timeEntries', prepareQuery.orderBy("start_time"));
      }
    },
    allEntriesForPeriod: {
      handler: function () {

        if (!this.startOfWeek || this.allEntriesForPeriod.length === 0) {
          // $bind resets array on binding so this gets fired with empty array.
          return;
        }

        /* get unique array of worker uuids */
        const worker_uuids = [...(new Set(this.allEntriesForPeriod.map(e => e.worker)))];

        // create new object to assign to foundWorkers to trigger updates.
        const newWorkers = {};

        worker_uuids.forEach(w => {
          const entries_for_worker = this.allEntriesForPeriod.filter(e => e.worker === w);

          // Here we pull out auto entries for verification check as they aren't approved or used for payroll.
          const filteredEntries = entries_for_worker.filter(entry => entry.type === 'manual');

          const all_entries_approved = filteredEntries.every(e => e.status === 'approved' || e.status === 'locked');

          if (!(w in newWorkers)) {
            newWorkers[w] = {};
          }

          newWorkers[w][this.startOfWeek.valueOf()] = all_entries_approved;
        })

        this.foundWorkers = newWorkers;
      }
    },
    currentEntry: {
      handler: function () {
        this.currentEntryEdited = true;
      },
      deep: true /* we will be notified of changes also if myData.child is changed :) */
    }
  },

  async mounted() {

    await this.$bind("workers", grooveDatabase.collection("users").where("disabled", "==", false).where("roles.customer", "==", false));

    // All timesheets need to be loaded first to allow workers status to be correctly generated on page load.
    await this.bindAllEntriesForPeriod();

    // Rest can be left to async load.
    this.$bind('timeEntryTypes', grooveDatabase.collection("time_entry_types"));
    this.$bind('vehicles', grooveDatabase.collection("vehicles").where("deleted", "==", false).orderBy("identifier"));
    this.$bind('trailers', grooveDatabase.collection("vehicles").where("deleted", "==", false).where("type", "==", 2).orderBy("identifier"));
    this.$bind('costCentres', grooveDatabase.collection("cost_centres").orderBy("name"));
  },
  methods: {
    cancelEvent: function(value) {
      this.canCancelEdit = value;
    },
    bindAllEntriesForPeriod: async function() {
      this.timeEntriesLoaded = false;
      // Binding gets auto released by Vuefire.
      let timeEntryQuery = grooveDatabase.collection("time_entries")
          .where('start_time', '<=', this.startOfWeek.endOf('week').valueOf())
          .where('start_time', '>', this.startOfWeek.minus({ days: 1 }).valueOf())
          .where('deleted', '==', false);
      await this.$bind('allEntriesForPeriod', timeEntryQuery.orderBy("start_time"));
      this.timeEntriesLoaded = true;
    },
    toggleExpanded: function () {
      this.expandWorkers = !this.expandWorkers;
    },
    updateCurrentWeek: async function (value) {
      this.today = value.today;
      this.startOfWeek = value.start_of_week;
      await this.bindAllEntriesForPeriod();
    },
    updateCurrentWorker(worker) {
      if (this.currentWorker !== worker) {
        this.updateCurrentEntry(null); /* close existing entry - since it must pertain to the old worker */
        this.currentWorker = worker;
      }
    },
    updateCurrentFilter(filter) {
      this.currentFilter = filter;
    },
    updateFilterStatus(status) {
      console.log(`FILTER STATUS: ${status}`);
      this.filtersOpen = status;
    },
    updateCurrentEntry(entry) {
      if (entry === null) {
        this.expandDetails = false;
        this.currentEntry = null;
        this.hasCurrentEntry = false;
        this.currentEntryEdited = false;
      } else {
        this.expandDetails = true;
        this.currentEntry = entry;
        this.hasCurrentEntry = true;
        this.currentEntryEdited = false;
      }
    },
    /* a filter key must exist and its value must not be null */
    generate_filter: function () {
      const filter = [];
      if ('worker' in this.currentFilter && this.currentFilter.worker) {
        filter.push(['worker', '==', this.currentFilter.worker])
      }
      if ('cost_centre_array' in this.currentFilter && this.currentFilter.cost_centre_array) {
        filter.push(['cost_centre', 'in', this.currentFilter.cost_centre_array.map(e => e[1])])
      }
      if ('vehicles' in this.currentFilter && this.currentFilter.vehicles) {
        filter.push(['vehicle', '==', this.currentFilter.vehicles])
      }
      if ('time_entry_type' in this.currentFilter && this.currentFilter.time_entry_type) {
        filter.push(['time_entry_type', '==', this.currentFilter.time_entry_type])
      }
      if ('role' in this.currentFilter && this.currentFilter.role) { // client side filtering
      }
      if ('start_time' in this.currentFilter && this.currentFilter.start_time) {
        filter.push(['start_time', '<=', this.startOfWeek.endOf('week').toMillis()])
        filter.push(['start_time', '>', (this.startOfWeek.subtract({ days: 1 }).toMillis())])
        /* go back an extra day to get jobs that start one day earlier - filter out in JS - I think it probably already filters because of the way EntryDaySummary component is designed */
        /* worth writing a unit for */
      }

      /* Don't show deleted entries */
      filter.push(['deleted', '==', false]);

      if ('showAuto' in this.currentFilter) {
        if (this.currentFilter.showAuto === false) {
          filter.push(['type', '==', 'manual']);
        }
      }

      return filter;
    },
  }
}
</script>

<style scoped lang="scss">

.columns {
  height: calc(100vh - 75px);
}

.column, .section {
  height: inherit;
}

.card {
  height: inherit;
}

h6 {
  margin-top: 15px;
}
</style>