<template>
  <JobList :dispatch-mode="false"></JobList>
</template>

<script>
import JobList from "./JobList"
export default {
  name: "Invoicing",
  components: {
    JobList
  }
}
</script>

<style scoped>

</style>