<template>
  <i-container
    class="_text-left _height-100"
    fluid
  >
    <i-row class="_height-100">
      <i-column
        id="entries"
        xs="12"
      >
        <i-table size="sm">
          <tr size="sm">
            <th>Supplier Name </th>
            <th>Vendor ID</th>
            <th>E-mail Address</th>
            <th>Address</th>
            <th>Address 2</th>
            <th>City</th>
          </tr>
          <SupplierNew />
          <Supplier
            v-for="(supplier, idx) of suppliers"
            :key="idx"
            :supplier="supplier"
          />
        </i-table>
      </i-column>
    </i-row>
  </i-container>
</template>

<script>


    import {grooveDatabase} from "../database";
    import Supplier from "./Supplier";
    import SupplierNew from "./SupplierNew";

    export default {
        name: "Suppliers",
        components: {
            Supplier, SupplierNew
        },
        data: function() {
            return {
                suppliers: [],
            }
        },
        methods: {
            newSupplier: function() {

            }
        },
        firestore() {
            return {
                suppliers: grooveDatabase.collection("suppliers").orderBy("name", "asc")
            }
        }
    }
</script>

<style scoped>
    h6 { margin-top: 15px; }
</style>