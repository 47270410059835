export function generate_filter(currentFilter, disableWorker = false) {
    const filter = [];
    if (!disableWorker && 'worker' in currentFilter && currentFilter.worker) {
        filter.push(['worker', '==', currentFilter.worker])
    }
    if ('cost_centre_array' in currentFilter && currentFilter.cost_centre_array) {
        filter.push(['cost_centre', 'in', currentFilter.cost_centre_array.map(e => e[1])])
    }
    if ('vehicles' in currentFilter && currentFilter.vehicles) {
        filter.push(['vehicle', '==', currentFilter.vehicles])
    }
    if ('time_entry_type' in currentFilter && currentFilter.time_entry_type) {
        filter.push(['time_entry_type', '==', currentFilter.time_entry_type])
    }
    if ('role' in currentFilter && currentFilter.role) { // client side filtering
    }
    if ('start_time' in currentFilter && currentFilter.start_time) {
        filter.push(['start_time', '<=', this.startOfWeek.clone().endOf('isoWeek').valueOf()])
        filter.push(['start_time', '>', (this.startOfWeek.clone().subtract(1, 'days').valueOf())])
        /* go back an extra day to get jobs that start one day earlier - filter out in JS - I think it probably already filters because of the way EntryDaySummary component is designed */
        /* worth writing a unit for */
    }

    /* Don't show deleted entries */
    filter.push(['deleted', '==', false]);

    if ('showAuto' in currentFilter) {
        if (currentFilter.showAuto === false) {
            filter.push(['type', '==', 'manual']);
        }
    }

    return filter;
}