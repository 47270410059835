



























































































































































































































































































import {grooveDatabase} from "@/database";
import {DateTime} from "luxon";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import {JobFilter} from "@/components/JobList/JobFilter";
// eslint-disable-next-line no-unused-vars
import {Job} from "@road-transport-logistics/groove-core/dist/database/models/Job";
// eslint-disable-next-line no-unused-vars
import {UserDetails} from "@road-transport-logistics/groove-core/dist/database/models/UserDetails";
// eslint-disable-next-line no-unused-vars
import {Asset} from "@road-transport-logistics/groove-core/dist/database/models/Asset";
// eslint-disable-next-line no-unused-vars
import {Client} from "@road-transport-logistics/groove-core/dist/database/models/Client";
// eslint-disable-next-line no-unused-vars
import {Depot} from "@road-transport-logistics/groove-core/dist/database/models/Depot";
// eslint-disable-next-line no-unused-vars
import {Division} from "@road-transport-logistics/groove-core/dist/database/models/Division";
import BatchGenerator from "@/components/Invoicing/BatchGenerator.vue";
import ItemList from "@/components/JobList/ItemList.vue";
import {JobStatus} from "@road-transport-logistics/groove-core/dist/database/models/JobStatus";
// eslint-disable-next-line no-unused-vars
import {Location} from "@road-transport-logistics/groove-core/dist/database/models/Location";

import {roundNumber} from "@/utilities/number";
import JobErrorComponent from "@/components/JobList/JobErrorComponent.vue";
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite-min.css';
import grooveStore from "@/data/grooveStore";

@Component({
  components: {
    BatchGenerator,
    ItemList,
    JobErrorComponent
  }
})
export default class JobList extends Vue {
  private currentFilter: JobFilter = {
    id: undefined,
    costCentre: undefined,
    selectedDepot: undefined,
    selectedDivision: undefined,
    selectedStatus: [],
    timestampStart: undefined,
    timestampEnd: undefined,
    deleted: false,
    client: undefined,
    user: undefined,
    showDeleted: false,
  }
  private expandFilters: boolean = true
  private startOfPagination: boolean = true;
  private firstJob?: Job = undefined
  private jobs: Array<Job> = []
  private states: Array<string> = []
  private selected_client_search: string = ""
  private selected_id: string = ""
  private loading: boolean = false
  private checkboxGroup: Array<Job> = []
  private divisions: Array<Division> = grooveStore.state.divisions
  private depots: Array<Depot> = grooveStore.state.depots
  @Prop({ default: false }) private dispatchMode!: string

  private queryReference = grooveDatabase
      .collection("jobs")
      .orderBy("pickupTimestamp", "desc");

  private pageLimit = 25;
  private searchClient = algoliasearch(
  'R0U3MNFXTN',
  '90cd8bba8fa818da413c15bcff2c2c74'
)

  private batchCompleted() {
    console.log("batch completed");
    this.selectAll();
    this.$buefy.toast.open({
      message: 'Batch being processed!',
      type: 'is-success'
    })
  }

  public async applyFilters(navigation: boolean = false) {

    if(!navigation) {
      this.jobs = [];
    }

    // Reset base query.
    this.queryReference = grooveDatabase
        .collection("jobs");

    if(!this.dispatchMode) {
      // this.queryReference = this.queryReference.orderBy("errors", "desc");
    }

    this.queryReference = this.queryReference.orderBy("pickupTimestamp", "desc");

    if (!this.currentFilter.id && this.currentFilter.selectedDepot) {
      this.queryReference = this.queryReference.where("depot", "==", this.currentFilter.selectedDepot);
    }
    if (!this.currentFilter.id && this.currentFilter.selectedDivision) {
      this.queryReference = this.queryReference.where("division", "==", this.currentFilter.selectedDivision);
    }

    if(!this.currentFilter.id && this.currentFilter.client) {
      this.queryReference = this.queryReference.where("customerId", "==", this.currentFilter.client);
    }

    if(!this.currentFilter.id && !this.currentFilter.showDeleted) {
      this.queryReference = this.queryReference.where("deleted", "==", this.currentFilter.deleted);
    }

    if(!this.currentFilter.id && this.dispatchMode && this.currentFilter.selectedStatus.length > 0) {
      this.queryReference = this.queryReference.where("state", "in", this.currentFilter.selectedStatus);
    }

    if(this.currentFilter.id) {
      this.queryReference = this.queryReference.where("reference", "==", this.currentFilter.id);
    }

    if(!this.dispatchMode && !this.currentFilter.id) {
      this.queryReference = this.queryReference.where("state", "in", [JobStatus.Accepted, JobStatus.Hold, JobStatus.Pending]);
    }

    if(this.dispatchMode) {
      this.queryReference = this.queryReference.limit(this.pageLimit);
    }
  }

  public async fetchJobs(navigation: boolean = false) {
    this.loading = true;
    console.log(this.queryReference);
    await this.$bind('jobs', this.queryReference);
    if(!navigation) {
      this.firstJob = this.jobs[0];
    }
    this.loading = false;
  }

  public async nextPage() {
    const index = this.jobs.length - 1;
    const last = this.jobs[index];
    await this.applyFilters(true);
    this.queryReference = this.queryReference
        .startAt(last.pickupTimestamp)
        .limit(this.pageLimit);
    await this.fetchJobs(true);
    this.checkPosition();
  }

  public async previousPage() {
    const first = this.jobs[1];
    await this.applyFilters(true);
    this.queryReference = this.queryReference
        .endBefore(first.pickupTimestamp)
        .limitToLast(this.pageLimit);
    await this.fetchJobs(true);
    this.checkPosition();
  }

  public clearFilters()  {
    this.currentFilter = {
      id: undefined,
      costCentre: undefined,
      selectedDepot: undefined,
      selectedDivision: undefined,
      selectedStatus: [],
      timestampStart: undefined,
      timestampEnd: undefined,
      deleted: false,
      client: undefined,
      user: undefined,
      showDeleted: false
    }
    this.selected_id = "";
  }

  public addJob() {

  }

  public editJob() {

  }

  private getStatusClass(job: Job): string {
    if(job.deleted) {
      return "is-danger";
    }
    if(job.state === JobStatus.Accepted) {
      return "is-success";
    }
    if(job.state === JobStatus.Pending) {
      return "is-info is-light"
    }
    if(job.state === JobStatus.Hold) {
      return "is-danger is-light"
    }
    return ""
  }

  public selectAll() {
    console.log("Toggling Select All.");
    if(this.checkboxGroup.length > 0) {
      this.checkboxGroup = [];
    } else {
      this.checkboxGroup = [];
      this.checkboxGroup.push(...this.jobs.filter(job => !job.deleted && job.state === JobStatus.Accepted));
    }
  }

  public timestampFormatted(job: Job): string {
    const date = DateTime.fromMillis(job.pickupTimestamp);
    return date.toFormat("ccc, dd/LL/yyyy hh:mm a");
  }

  round(value: number): string {
    return roundNumber(value, 2);
  }

  checkPosition() {
    if(this.firstJob && this.jobs.length > 0) {
      console.log(this.firstJob.id === this.jobs[0].id);
      this.startOfPagination = this.firstJob.id === this.jobs[0].id;
    }
  }

  get filtersActive() {
    return (this.currentFilter.selectedDivision)
        || (this.currentFilter.selectedDepot)
        || (this.currentFilter.client !== undefined)
        || (this.currentFilter.user !== undefined)
        || (this.currentFilter.id !== undefined)
  }

  async mounted() {
    const _this = this;
    _this.loading = true

    // Fire all collection requests
    await this.$bind("users", grooveDatabase.collection("users").orderBy("displayName"))

    // Remove invalid accounts.
    this.states = Object.values(JobStatus);

    this.depots = grooveStore.state.depots;
    this.divisions = grooveStore.state.divisions;

    _this.loading = false;
  }

  get filteredClients(): Array<Client> {
    return grooveStore.state.clients.filter((option) => {
      return option.name
          .toLowerCase()
          .indexOf(this.selected_client_search.toLowerCase()) >= 0
    })
  }

  @Watch('selected_id', {
    immediate: true, deep: true
  })
  idChanged(currentString: string) {
    // Do a no-op unless the value has been cleared or is exactly the 6 char length required for
    // purchase orders.
    if (!currentString || currentString.length === 0) {
      this.currentFilter['id'] = undefined;
    } else if (currentString && currentString.length === 6) {
      // Prefix auto added. Convert to lower case as all are generated lower case.
      // We always want to search server side if searching by PO Number.
      this.currentFilter.id = `${currentString}`;
    }
  }

  @Watch('currentFilter', {
    immediate: true, deep: true
  })
  async filterChanged(filter: JobFilter) {
    console.log(filter);
    this.checkboxGroup = [];
    await this.applyFilters();
    await this.fetchJobs();
  }

  formatNumber(value: number) {
    return roundNumber(value, 2);
  }
}
