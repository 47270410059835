// @ts-ignore
import {firestoreAction, vuexfireMutations} from 'vuexfire'
import {Division} from "@road-transport-logistics/groove-core/dist/database/models/Division";
import {Depot} from "@road-transport-logistics/groove-core/dist/database/models/Depot";
import {Client} from "@road-transport-logistics/groove-core/dist/database/models/Client";
import {Asset} from "@road-transport-logistics/groove-core/dist/database/models/Asset";
import {Location} from "@road-transport-logistics/groove-core/dist/database/models/Location";
import {grooveDatabase} from "@/database";
// @ts-ignore
import Vuex from 'vuex';
import Vue from "vue";
// @ts-ignore
import { firestoreOptions } from 'vuexfire'

// always wait for bindings to be resolved
firestoreOptions.wait = true

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        divisions: Array<Division>(),
        depots: Array<Depot>(),
        clients: Array<Client>(),
        vehicles: Array<Asset>(),
        locations: Array<Location>(),
    },

    mutations: vuexfireMutations,

    actions: {
        // @ts-ignore
        bindDivisions: firestoreAction(({bindFirestoreRef}) => {
            // return the promise returned by `bindFirestoreRef`
            console.log("BIND DIVISIONS");
            return bindFirestoreRef('divisions', grooveDatabase.collection("divisions").orderBy("name"))
        }),
        // @ts-ignore
        bindDepots: firestoreAction(({bindFirestoreRef}) => {
            // return the promise returned by `bindFirestoreRef`
            return bindFirestoreRef('depots', grooveDatabase.collection("depots").orderBy("name"))
        }),
        // @ts-ignore
        bindClients: firestoreAction(({bindFirestoreRef}) => {
            // return the promise returned by `bindFirestoreRef`
            return bindFirestoreRef('clients', grooveDatabase.collection("clients").where("deleted", "==", false).orderBy("name"))
        }),
        // @ts-ignore
        bindVehicles: firestoreAction(({bindFirestoreRef}) => {
            // return the promise returned by `bindFirestoreRef`
            return bindFirestoreRef('vehicles', grooveDatabase.collection("vehicles").orderBy("identifier"))
        }),
    },
})
