<template>
  <div class="section">
    <div class="level">
      <div class="level-left">
        <h1>Cost Centres</h1>
      </div>
      <div class="level-right">
        <b-button icon-pack="far" icon-left="plus" @click="newCostCentre"></b-button>
      </div>
    </div>
    <div class="container">

      <CostCentre
          v-for="cost_centre of costCentres"
          :key="cost_centre.uuid"
          :costcentre="cost_centre"
      />
    </div>
  </div>
</template>

<script>


    import {grooveDatabase} from "@/database";
    import CostCentre from "./CostCentre";
    import UUID from "pure-uuid";

    export default {
        name: "CostCentres",
        components: {
            CostCentre
        },
        data: function() {
            return {
                costCentres: [],
            }
        },
        watch: {
            cost_centre() {
            }
        },
        methods: {
            newCostCentre: function() {
              const id = new UUID(4).toString();
               grooveDatabase.collection("cost_centres").doc(id).set(
                   {
                       name: 'New cost centre...',
                       uuid: id,
                       deleted: false,
                       code: null,
                       kind: null,
                       icon: 'faTools',
                       roles: {
                           admin: null,
                           driver: null,
                           yardman: null,
                           workshop: null,
                       }
                   }
               )
            }
        },
        firestore() {
            return {
                costCentres: grooveDatabase.collection("cost_centres")
                    .where('deleted', "==", false)
                    .orderBy('name')

            }
        },
    }
</script>

<style scoped>
    h6 { margin-top: 15px; }
</style>