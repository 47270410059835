<template>
  <i-container
    class="_text-left _height-100"
    fluid
  >
    <i-row class="_height-100">
      <i-column
        id="search"
        xs="2"
        :class="{ closed: expandSearch }"
      >
        <h6>
          <span :class="{ closed: expandSearch }">Search</span>
          <font-awesome-icon
            class="expand-icon-left _float-right"
            icon="arrow-circle-left"
            @click="expandSearch = !expandSearch"
          />
        </h6>
        <font-awesome-icon
          class="expand-icon-right _float-right"
          icon="arrow-circle-right"
          @click="expandSearch = !expandSearch"
        />
        <div id="search_content">
          <div class="status-placeholder-wrapper">
            <i-select
              v-model="selected_status"
              class="status-placeholder"
              size="sm"
              placeholder="Filter by Status"
            >
              <i-select-option
                key="pending"
                label="Pending"
                value="pending"
              />
              <i-select-option
                key="accepted"
                label="Approved"
                value="accepted"
              />
              <i-select-option
                key="rejected"
                label="Rejected"
                value="rejected"
              />
            </i-select>
          </div>

          <div class="user-placeholder-wrapper">
            <div
              size="sm"
              class="user-placeholder"
              @click="showMenu('userMenu')"
            >
              {{ displayUserItem() || "Filter PO by User" }}
            </div>
          </div>
          <div
            id="userMenu"
            size="sm"
            style="display: none;"
            class="user"
          >
            <div
              v-for="user of users"
              :key="user.id"
              @click="select_user(user.id)"
            >
              {{ user.displayName }}
            </div>
          </div>
          <i-button
            size="sm"
            class="_float-right clear-filters"
            @click="clearFilters"
          >
            Clear Filters
          </i-button>
        </div>
      </i-column>
      <i-column
        id="orders"
        xs="7"
      >
        <div class="button-container">
          <!--                    <i-button size="sm" style="margin-right: 20px;" id="back_week" v-on:click="new_request">New Leave Request...</i-button>-->
          <span
            v-if="requestsLoading"
            class="loading"
          >Loading</span>
          <span
            v-if="requestsSaved"
            class="loading"
          >Saving</span>

          <DatePicker
            v-model="start_of_week_as_jsdate"
            mode="range"
            :popover="{ placement: 'bottom', visibility: 'click' }"
          >
            <div
              id="date-button"
              title="Click to select date..."
            >
              {{ start_of_week.format("ddd, DD/MMM/YYYY") }} - {{ end_of_week.clone().format("ddd, DD/MMM/YYYY") }}
            </div>
          </DatePicker>

          <i-button
            id="back_week"
            size="sm"
            @click="back_week"
          >
            Back Week
          </i-button>
          <i-button
            id="next_week"
            size="sm"
            @click="next_week"
          >
            Next Week
          </i-button>
        </div>
        <table class="wrapping-table">
          <tr>
            <th class="approve">
              Command
            </th>
            <th class="order-date">
              Date
            </th>
            <th class="order-date">
              Hours
            </th>
            <th class="username">
              User
            </th>
            <th class="description">
              Reason for Leave
            </th>
          </tr>
          <tr
            v-for="request in filtered_leave_requests"
            :key="request.id"
          >
            <td class="approve">
              <i-button
                v-if="request.state == 'pending'"
                style="display: inline-block"
                class="approve_button approveb"
                size="sm"
                @click="confirmRequest(request)"
              >
                <font-awesome-icon
                  v-if="request.state == 'pending'"
                  class="_float-right"
                  icon="check-circle"
                />
              </i-button>
              <i-button
                v-if="request.state == 'pending'"
                style="display: inline-block"
                class="approve_button reject"
                size="sm"
                @click="rejectRequest(request)"
              >
                <font-awesome-icon
                  v-if="request.state == 'pending'"
                  class="_float-right"
                  icon="stop-circle"
                />
              </i-button>
              <i-button
                v-if="request.state == 'rejected'"
                style="display: inline-block"
                class="approve_button reject"
                size="sm"
                @click="unrejectRequest(request)"
              >
                <font-awesome-icon
                  class="_float-right"
                  icon="check-circle"
                />
              </i-button>
              <i-button
                v-if="request.state == 'accepted'"
                style="display: inline-block"
                class="approve_button locked"
                size="sm"
              >
                <font-awesome-icon
                  class="_float-right"
                  icon="lock"
                />
              </i-button>
            </td>
            <td class="order-date">
              {{ request.date | moment("DD/MM/YY") }}
            </td>
            <td class="username">
              {{ displayDuration(request.minutes) }}
            </td>
            <td class="username">
              {{ displayUser(request.worker) }}
            </td>
            <td class="description">
              {{ request.description }}
            </td>
          </tr>
        </table>
      </i-column>
      <i-column
        id="details"
        xs="3"
        style="display: block;"
        :class="{ closed: expandDetails }"
      >
        <h6>
          <span :class="{ closed: expandDetails }">New Leave Request</span>
          <font-awesome-icon
            class="expand-icon-left _float-right"
            icon="arrow-circle-left"
            @click="expandDetails = !expandDetails"
          />
        </h6>
        <font-awesome-icon
          class="expand-icon-right _float-right"
          icon="arrow-circle-right"
          @click="expandDetails = !expandDetails"
        />

        <div id="details_content">
          <div class="status-placeholder-wrapper">
            <i-form-label
              size="sm"
              class="_font-weight-bold"
            >
              Leave Requestee
            </i-form-label>
            <i-select
              v-model="selected_new_user"
              class="status-placeholder"
              size="sm"
              placeholder="Select a worker..."
            >
              <i-select-option
                v-for="user in myself"
                :key="user.id"
                :label="user.displayName"
                :value="user.id"
              />
              <i-select-option
                v-for="user in users"
                :key="user.id"
                :label="user.displayName"
                :value="user.id"
              />
            </i-select>
          </div>
          <div class="status-placeholder-wrapper">
            <i-form-label
              size="sm"
              class="_font-weight-bold"
            >
              Cost Centres
            </i-form-label>
            <i-select
              v-model="selected_cc"
              class="status-placeholder"
              size="sm"
              placeholder="Select a cost centre..."
            >
              <i-select-option
                v-for="cc in costcentres"
                :key="cc.uuid"
                :label="cc.name"
                :value="cc.uuid"
              />
            </i-select>
          </div>
          <br>
          <i-form-group>
            <i-form-label
              size="sm"
              class="_font-weight-bold"
            >
              Leave From
            </i-form-label>
            <i-input
              type="datetime-local"
              :value="start_timestamp_for_datepicker"
              @change="changeStartTime"
            />
          </i-form-group>

          <i-form-group>
            <i-form-label
              size="sm"
              class="_font-weight-bold"
            >
              Leave To
            </i-form-label>
            <i-input
              type="datetime-local"
              :value="end_timestamp_for_datepicker"
              @change="changeEndTime"
            />
          </i-form-group>

          <i-form-group>
            <i-form-label
              size="sm"
              class="_font-weight-bold"
            >
              Reason for leave
            </i-form-label>
            <i-textarea
              v-model="description"
              placeholder="Description"
            />
          </i-form-group>

          <i-form-group>
            <i-button
              size="sm"
              class="_background-primary"
              @click="save"
            >
              Create Request
            </i-button>
          </i-form-group>
        </div>
      </i-column>
    </i-row>
  </i-container>
</template>

<script>

import {grooveDatabase} from "../database";
import moment from "moment"
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

function waitFor(conditionFunction) {

        const poll = resolve => {
            if (conditionFunction()) resolve();
            else setTimeout(() => poll(resolve), 400);
        }

        return new Promise(poll);
    }

    export default {
        name: "LeaveRequests",
        components: {DatePicker},
        data: function () {
            return {
                start_of_week: moment().subtract(60, 'days').startOf('week'),
                end_of_week: moment().endOf('week'),
                expandSearch: false,
                expandDetails: false,
                currentFilter: {
                    idx: 0,
                    // status: 'pending',
                    timestamp_start: true,
                    timestamp_end: true
                },
                start_of_week_as_jsdate: {
                    start: moment().subtract(60, 'days').startOf('week'), // Jan 16th, 2018
                    end: moment().endOf('week')    // Jan 19th, 2018
                },

                selected_new_user: null,
                description: null,
                new_starttime: moment().add(1, 'day').startOf('day').add(8, 'hours').valueOf(),
                new_endtime: moment().add(1, 'day').startOf('day').add(17, 'hours').valueOf(),
                leave_requests: [],
                myusers: [],
                myusersloaded: false,
                filtered_requests: [],
                filtered_leave_requests: [],
                users: [],
                myself: [],
                selected_user: null,
                selected_status: 'pending',
                selected_cc: null,
                requestsLoading: false,
                requestsSaved: false,
                costcentres: []
            }
        },

        firestore() {
            return {
                costcentres: grooveDatabase.collection("cost_centres").where('kind', '==', 'Leave')
            }
        },
        computed: {
            start_timestamp_for_datepicker: function() {
                if ( this.new_starttime ) {
                  return moment(this.new_starttime).format("YYYY-MM-DDTHH:mm");
                }
                return null;
            },
            end_timestamp_for_datepicker: function() {
                if ( this.new_endtime ) {
                  return moment(this.new_endtime).format("YYYY-MM-DDTHH:mm");
                }
                return null;
            }
        },

        watch: {
            '$root.user': {
                immediate: true,
                handler: function() {
                    if ( this.$root.user ) {
                        this.$bind("users", grooveDatabase.collection("users").where('leave_approver_uuid', '==', this.$root.user.uid))
                    }
                }
            },
            users: {
                handler: function() {
                    this.myusers = this.users.map(m => { return m.id});
                    this.myself = [this.$root.user];
                    this.myusersloaded = true;
                }
            },
            leave_requests: {
                handler: async function() {
                    await waitFor(() => { return this.myusersloaded === true});
                    this.filtered_leave_requests = this.leave_requests.filter(r => {
                        return this.myusers.includes(r.worker)
                    });

                }
            },
            currentFilter: {
                immediate: true,
                deep: true,
                handler: function () {

                    this.requestsLoading = true
                    var filterArgs = this.generate_filter();

                    var prepareQuery = grooveDatabase.collection("leave_requests")

                    // Does this even work - apparently yes - iterate over each filter and append!
                    for (var i = 0; i < filterArgs.length; i++) {
                        prepareQuery = prepareQuery.where(...filterArgs[i]);
                    }
                    this.$bind('leave_requests', prepareQuery).then(() => this.requestsLoading = false).finally(() => this.requestsLoading = false);
                }
            },
            selected_status(value) {
                this.selected_option('status', value);
            },
            selected_user(value) {
                this.selected_option('user', value);
            },
            start_of_week_as_jsdate(v) {
                var conv_to_moment_date_start = moment(v.start);
                var conv_to_moment_date_end = moment(v.end);
                this.start_of_week = conv_to_moment_date_start;
                this.end_of_week = conv_to_moment_date_end;
                this.currentFilter['timestamp_start'] = this.start_of_week;
                this.currentFilter['timestamp_end'] = this.end_of_week;
                this.currentFilter.idx++;
            }
        },
        methods: {
            displayUserItem: function() {
                var user = this.users.find(u => { return u.id === this.selected_user})
                if ( user ) {
                    return user.displayName
                }
                else {
                    return null;
                }
            },
            displayUser: function(uuid) {
                var user = this.users.find(u => { return u.id === uuid})
                if ( user ) {
                    return user.displayName
                }
                else {
                    return null;
                }
            },
            displayDuration: function(duration) {
              // Currently shows total hours for the full duration.
                return (duration / 60)
            },
            changeStartTime: function(data) {
                if ( data ) {
                    const new_time = moment(data, moment.HTML5_FMT.DATETIME_LOCAL).set('seconds', 0).valueOf();
                    this.new_starttime = new_time;
                }
            },
            changeEndTime: function(data) {
                if ( data ) {
                    const new_time = moment(data, moment.HTML5_FMT.DATETIME_LOCAL).set('seconds', 0).valueOf();
                    this.new_endtime = new_time;
                }
            },
            save: async function() {
                var _this = this;
                var start = moment(this.new_starttime).startOf('day');
                var days = Math.floor((this.new_endtime - this.new_starttime) / 86400000) + 1;
                var day_array = [];
                for (var i = 1; i < days; i++) {
                    day_array.push(start.clone().add(i, 'days').valueOf());
                }
                var duration;
                var minutes;
                if ( days > 1 ) {
                    duration = 'multi';
                    minutes = days * 480;
                }
                else if ( this.new_endtime - this.new_starttime === 32400000 && days === 1 ) {
                    duration = 'full'
                    minutes = 480;
                }
                else {
                    duration = 'half';
                    minutes = (this.new_endtime - this.new_starttime) / 60000;
                }

                var request = {
                    worker: this.selected_new_user,
                    state: 'pending',
                    minutes: minutes,
                    duration: duration,
                    description: this.description,
                    deleted: false,
                    days: day_array,
                    date: start.valueOf(),
                    costCentre: this.selected_cc
                }
                await grooveDatabase.collection('leave_requests').add(request).then(function() {
                    _this.expandDetails = ! _this.expandDetails;
                });
            },
            clearFilters: function() {
                this.selected_user = null;
            },
            select_user: function(v) {
                this.selected_user = v;
                this.showMenu('userMenu');
            },
            next_week: function() {
              this.start_of_week = this.start_of_week.clone().add(1, 'week');
              this.end_of_week = this.end_of_week.clone().add(1, 'week');
              this.currentFilter['timestamp_start'] = this.start_of_week;
              this.currentFilter['timestamp_end'] = this.end_of_week;
              this.currentFilter.idx++;
            },
            back_week: function() {
              this.start_of_week = this.start_of_week.clone().subtract(1, 'week');
              this.end_of_week = this.end_of_week.clone().subtract(1, 'week');
              this.currentFilter['timestamp_start'] = this.start_of_week;
              this.currentFilter['timestamp_end'] = this.end_of_week;
              this.currentFilter.idx++;
            },
            selected_option(key, value) {
                if ( value && value !== "0" )
                    this.currentFilter[key] = value;
                else
                    delete this.currentFilter[key];

                this.currentFilter.idx++;
            },
            showMenu: function (menu) {
                var e = document.getElementById(menu).style.display;
                if (e === 'block') {
                    document.getElementById(menu).style.display = 'none';
                } else {
                    document.getElementById(menu).style.display = 'block';
                }
            },
            confirmRequest(leave) {
              this.$confirm(
                {
                  message: 'Confirm the leave request?',
                  button: {
                    no: 'Cancel',
                    yes: 'Confirm'
                  },
                  callback: confirm => {
                    if (confirm) {
                        grooveDatabase.collection('leave_requests').doc(leave.id).update({ state: 'accepted' })
                    }
                  }
                }
              )
            },
            rejectRequest(leave) {
              this.$confirm(
                {
                  message: 'Reject the leave request?',
                  button: {
                    no: 'Cancel',
                    yes: 'Reject'
                  },
                  callback: confirm => {
                    if (confirm) {
                        grooveDatabase.collection('leave_requests').doc(leave.id).update({ state: 'rejected' })
                    }
                  }
                }
              )
            },
            unrejectRequest(leave) {
              this.$confirm(
                {
                  message: 'Restore the leave request?',
                  button: {
                    no: 'Cancel',
                    yes: 'Restore'
                  },
                  callback: confirm => {
                    if (confirm) {
                        grooveDatabase.collection('leave_requests').doc(leave.id).update({ state: 'pending' })
                    }
                  }
                }
              )
            },
            generate_filter: function () {
                var filter = [];
                if ('user' in this.currentFilter && this.currentFilter.user) {
                    filter.push(['worker', '==', this.currentFilter.user])
                }
                if ('status' in this.currentFilter && this.currentFilter.status) {
                    filter.push(['state', '==', this.currentFilter.status])
                }

                // If we are looking for pending entries ignore date range - we want to see all outstanding leave requests.
                if ('status' in this.currentFilter && this.currentFilter.status !== 'pending') {
                  if ('timestamp_start' in this.currentFilter && this.currentFilter.timestamp_start) {
                    filter.push(['date', '>=', this.start_of_week.valueOf()])
                  }
                  if ('timestamp_end' in this.currentFilter && this.currentFilter.timestamp_end) {
                    filter.push(['date', '<=', this.end_of_week.valueOf()])
                  }
                }

                return filter;
            },
        },

    }
</script>

<style scoped>

    h6 {
        margin-top: 15px;
    }

    .search {
        width: 300px;
        margin-right: 30px;
        display: inline-block;
    }

    .button-container {
        padding: 10px;
        float: left;
    }

    .button-container button {
        margin-left: 10px;
    }

    table.wrapping-table {
        width: 100%;
    }

    table.wrapping-table td.outer {
        padding: 10px;
        vertical-align: top;
        font-weight: bold;
    }

    table.wrapping-table table {
        width: 100%;
    }

    table.wrapping-table table td {
        padding: 10px;
        width: 200px;
    }

    th.selected, td.selected {
        width: 70px !important;
        text-align: center;
    }

    th.date, td.date {
        padding-left: 50px !important;
        text-align: left;
    }

    .confirm {
        background-color: lawngreen !important;
    }


    #details, #search, #menu {
        width: 50px;
        /*flex-grow: 1;*/
        /*flex-basis: auto;*/
        /*transition: 400ms;*/
    }

    #search {
        background-color: #ddd;
    }

    #details {
        background-color: #eee;
    }

    #orders {
        max-width: 100%;
    }

    #details h6 span.closed {
        display: inline-block;
        color: #fff;
        position: absolute;
        top: 100px;
        left: -44px;
        width: 140px;
        transform: rotate(-90deg);
        transition: 400ms;
    }

    #search h6 span.closed {
        display: inline-block;
        position: absolute;
        top: 55px;
        left: 0px;
        transform: rotate(-90deg);
        transition: 400ms;
        width: 50px;
    }

    #menu h6 span.closed {
        display: inline-block;
        position: absolute;
        top: 47px;
        left: 5px;
        transform: rotate(-90deg);
        transition: 400ms;
    }

    #search.closed, #details.closed, #menu.closed {
        flex-grow: 0;
        flex-basis: unset;
        transition: 400ms;
    }

    #details.closed {
        background-color: #999 !important;
    }

    #details.closed #details_content {
        display: none;
    }

    #search.closed {
        background-color: #bbb !important;
    }

    #search.closed #search_content {
        display: none;
    }

    .expand-icon-left {
        display: block;
    }

    .closed .expand-icon-left {
        display: none;
    }

    .expand-icon-right {
        display: none;
    }

    .closed .expand-icon-right {
        display: block;
    }


    .user-placeholder, .supplier-placeholder, .pocode-placeholder {
        width: calc(100% - 1px);
        position: relative;
        cursor: pointer;
        font-size: 12.8px;
        margin-top: 3px;
        margin-bottom: 3px;
        color: #888;
        background-color: #fff;
        border-radius: 4px;
        border-color: red;
        border-top-width: 5px;

        line-height: 24px;
        padding: 6.4px 12.8px 6.4px 12.8px;
    }

    .user, .supplier, .pocode {
        width: 100%;
        cursor: pointer;
        font-size: 12.8px;
        padding: 0px;
    }

    .user > div, .supplier > div, .pocode > div {
        width: 100%;
        cursor: pointer;
        font-size: 12.8px;
        padding: 10px;
        background-color: white;
        display: block;
    }

    .user-placeholder:after, .supplier-placeholder:after, .pocode-placeholder:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 20px;
        margin-top: -2px;
        z-index: 1;
        font-size: 12px;
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #000;
    }

    table { table-layout: fixed }
    table tr th { height: 50px; cursor: pointer; font-size: 12.8px; }
    table tr td { height: 50px; cursor: pointer; font-size: 12.8px; border-top: 1px solid #ccc; }
    .selected {
        background-color: lawngreen;
    }

    td.approve, th.approve { width: 90px; text-align: center; }
    td.print, th.print { width: 50px; text-align: center; }
    td.found, th.found { width: 50px; }
    td.order-date, th.order-date { width: 70px !important; }
    td.po_number, th.po_number { width: 110px; }
    td.supplier, th.supplier { width: 110px; }
    td.price, th.price { width: 70px; text-align: right; padding-right: 10px;}
    td.cost_centre, th.cost_centre { width: 120px !important; }
    td.username, th.username { width: 120px; }
    td.email, th.email { width: 180px; overflow: hidden;
    padding-right: 10px;
    overflow-wrap: unset;
    white-space: nowrap;
    text-overflow: ellipsis; }
    td.description, th.description {  }
    th:last-child {  }

    .button-container { float: right; }

    #print {
        margin-top: 10px;   float: left;
    }

    #add_order { margin-right: 10px; }

    .loading { color: #00bf8f; display: inline-block; margin-right: 10px; }

    #date-button { display: inline-block; margin-right: 10px; cursor: pointer; }
    #date-button:hover { text-decoration: underline; }

    .clear-filters { margin-top: 3px; }
    .cc-placeholder-wrapper { margin-top: 3px; }
    #vehicleMenu div { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }


       .role {
        width: 100%;
        cursor: pointer;
        font-size: 12.8px;
        padding: 0px;
        background-color: red;
    }

    .role > div {
        width: 100%;
        cursor: pointer;
        font-size: 12.8px;
        padding: 10px;
        background-color: white;
    }

    #supplierMenu {
        height: 300px; overflow-y: scroll; text-wrap: none; overflow-wrap: normal;
    }
    #userMenu {
        height: 300px; overflow-y: scroll; text-wrap: none; overflow-wrap: normal;
    }

    .approveb {
        background-color: lightgreen !important;
    }


    .locked {
        background-color: gold !important;
    }

    .reject {
        background-color: lightcyan !important;
    }


</style>