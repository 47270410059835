<template>
  <div class="container worker-container p-0 pt-4">
    <vue-scroll :ops="scrollOptions" class="scroll-container" v-bind:class="{ filterVisible: (filtersOpen && expanded) }">
      <div v-if="filtered_workers && filtered_workers.length === 0">
        <div  class="card m-1">
          <div class="card-content p-2">
            <div v-if="expanded" class="container">
              <div class="content">
                <p class="title is-4">No Matches</p>
                <p class="subtitle is-italic is-small is-6">Adjust Filter</p>
              </div>
            </div>
            <div v-if="!expanded" class="container">
              <div class="content">
                <p class="title is-4">NM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="filtered_workers && filtered_workers.length > 0">
        <div  class="card m-1" v-for="worker of filtered_workers"
              :key="worker.id"
              :class="is_verified(startOfWeek, worker.id)"
              :alpha="'worker_' + worker.id"
              @click="load_worker(worker.id, $event)">
          <div class="card-content p-2">
            <div v-if="expanded" class="container">
              <div class="content">
                <p class="title is-4">{{ worker.displayName || "REVIEW USER!" }}</p>
                <p class="subtitle is-italic is-small is-6">{{ worker.email }}</p>
              </div>
            </div>
            <div v-if="!expanded" class="container">
              <div class="content">
                <p class="title is-4">{{ getInitials(worker.displayName) || "!!" }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>

export default {
  name: "Workers",
  props: {
    currentFilter: {
      type: Object,
      default: null
    },
    filtersOpen: {
      type: Boolean,
      default: false
    },
    startOfWeek: {
      type: Object,
      default: null
    },
    workers: {
      type: Array,
      default: null
    },
    foundWorkers: {
      type: Object,
      default: null
    },
    currentWorker: {
      type: String,
      default: null
    },
    expanded: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      scrollOptions: {
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: false,
          keepShow: false,
          background: '#c1c1c1',
          opacity: 0.8,
          minSize: 0.1,
          size: '6px',
          disable: false
        }
      },
      filtered_workers: []
    }
  },

  watch: {

    currentFilter: {
      deep: true,
      immediate: true,
      handler: function () {
        this.filter_workers();
      }
    },
    foundWorkers: {
      immediate: true,
      handler: function() {
        this.filter_workers();
      }
    },
  },

  methods: {
    getInitials: function (displayName) {
      if(!displayName) {
        return "!!";
      }
      const split = displayName.split(/[ ,]+/);
      return `${split[0][0]}${split[1][0]}`;
    },
    load_worker: function (worker) {
      let elements = document.getElementsByClassName("selected");
      elements.forEach(e => {
        e.classList.remove("selected")
      });

      if (worker === this.currentWorker) {
        this.$emit('changeWorker', null);
      } else {
        this.$emit('changeWorker', worker);
      }
    },
    generate_filter: function () {
      let filter = [];
      if ('role' in this.currentFilter && this.currentFilter.role) {
        let role_to_filter = 'role.' + this.currentFilter.role;
        filter.push([role_to_filter, '==', true])
      }
      return filter;
    },
    is_verified: function (start_of_week, id) {
      let str = (this.currentWorker && this.currentWorker === id) ? " selected " : "";
      if (start_of_week && this.foundWorkers && this.foundWorkers[id]) {
        if (!(start_of_week.valueOf() in this.foundWorkers[id])) {
          str += " unknown ";
        } else {
          str += (this.foundWorkers[id][start_of_week.valueOf()]) ? " verified " : " notyet ";
        }
      } else {
        str += " unknown ";
      }
      return str;

    },
    filter_workers: function () {

      let _this = this;
      /* filter by role, division, depot, and time_entry_workers */

      /* sort on client side - removes need to Firestore indexes */

      let workers_with_role = this.workers; // be default - everyone
      if (this.currentFilter && this.currentFilter.role && this.currentFilter.role.length && !this.currentFilter.role.includes('All roles')) {
        workers_with_role = this.workers.filter(worker => {
          return _this.currentFilter.role.some(role => worker.roles[role] === true);
        });
      }

      let workers_with_depot = this.workers; // be default - everyone
      if (this.currentFilter && this.currentFilter.depot && this.currentFilter.depot.length && !this.currentFilter.depot.includes('All depots')) {
        workers_with_depot = this.workers.filter(worker => {
          return _this.currentFilter.depot.includes(worker.depot);
        });
      }

      let workers_with_division = this.workers; // be default - everyone
      if (this.currentFilter && this.currentFilter.division && this.currentFilter.division.length && !this.currentFilter.division.includes('All divisions')) {
        workers_with_division = this.workers.filter(worker => {
          return _this.currentFilter.division.includes(worker.division);
        });
      }

      /* intersection of the sets */
      if (this.currentFilter && this.currentFilter.cost_centre_array && this.currentFilter.cost_centre_array.length) {
        this.filtered_workers = workers_with_role.filter(v => workers_with_depot.includes(v)).filter(v => workers_with_division.includes(v)).filter(v => {
          return Object.keys(this.workers).find(e => {
            return e === v.id
          }) // beware the includes array method didn't work
        })
        this.filtered_workers.sort((a, b) => (a.displayName && b.displayName ? a.displayName.localeCompare(b.displayName) : a.email.localeCompare(b.email)));
      } else {
        this.filtered_workers = workers_with_role.filter(v => workers_with_depot.includes(v)).filter(v => workers_with_division.includes(v))
        this.filtered_workers.sort((a, b) => (a.displayName && b.displayName ? a.displayName.localeCompare(b.displayName) : a.email.localeCompare(b.email)));
      }
    },
  }
}
</script>

<style scoped>

.card:hover {
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.5), 0 0 0 1px rgba(10, 10, 10, 0.05);
  box-shadow: 0 0.5em 1em -0.125em rgba(5, 5, 5, 0.5), 0 0 0 1px rgba(5, 5, 5, 0.05);
}

.verified {
  background-image: linear-gradient(120deg, rgba(212, 252, 121, 1) 0%, rgba(150, 230, 161, 1) 100%) !important;
}

.notyet {
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%) !important;
}

.selected {
  border: 1px solid #555 !important;
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.5), 0 0 0 1px rgba(10, 10, 10, 0.05);
  box-shadow: 0 0.5em 1em -0.125em rgba(5, 5, 5, 0.5), 0 0 0 1px rgba(5, 5, 5, 0.05);
}

.worker-container {
  height: inherit;
}

.scroll-container {
  height: calc(100vh - 140px) !important;
}

.scroll-container.filterVisible {
  height: calc(100vh - 360px) !important;
}
</style>
