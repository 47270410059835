























import {Component, Prop, Vue, Watch} from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import {Job} from "@road-transport-logistics/groove-core/dist/database/models/Job";
// eslint-disable-next-line no-unused-vars
import {Error} from "@road-transport-logistics/groove-core/dist/database/models/Error";

@Component
export default class JobErrorComponent extends Vue {
  @Prop({ default: undefined }) private job?: Job
  showErrors: boolean = false;
  errors: Array<Error> = [];
  warnings: Array<Error> = [];

  @Watch("job", {immediate: true})
  jobUpdated(job: Job) {
    this.errors = job.errors ? job.errors : [];
    this.warnings = job.warnings ? job.warnings : [];
  }

  toggleErrors() {
    console.log("TOGGLE ERRORS");
    this.showErrors = !this.showErrors;
  }
}

