



































































































import moment from 'moment'
import {grooveDatabase} from "@/database";
import Vue from "vue";
import grooveStore from "@/data/grooveStore";
import {Component, Prop} from "vue-property-decorator";
import {Supplier} from "@road-transport-logistics/groove-core/dist/database/models/Supplier";
import {PurchaseOrder} from "@road-transport-logistics/groove-core/dist/database/models/PurchaseOrder";
import {Asset} from "@road-transport-logistics/groove-core/dist/database/models/Asset";
import {entities} from "@road-transport-logistics/groove-core/dist/database/entities";

@Component
export default class PurchaseOrderDetails extends Vue {
  @Prop({default: undefined}) currentOrder?: PurchaseOrder
  @Prop({default: []}) private suppliers?: Array<Supplier>
  private vehicles: Array<Asset> = []
  private renderDate(epoch: number): string {
    return moment(epoch).format("DD/MM/YYYY hh:mma");
  }

  mounted() {
    this.vehicles = grooveStore.state.vehicles;
  }

  private async deleteOrder() {
    console.log("DELETE ORDER");
    try {
      // eslint-disable-next-line vue/no-mutating-props
      await grooveDatabase.collection(entities.purchaseOrder).doc(this.currentOrder!.id).update({ deleted: true });
      this.$emit("changeCurrentOrder", null);
    } catch(error) {
      console.log("ERROR");
      console.log(error);
    }
  }

  private cancel() {
    this.$emit("changeCurrentOrder", null);
  }

  private async save() {
    if(!this.currentOrder!.cost_centre || !this.currentOrder!.supplier) {
      Vue.$toast.open({
        message: "You must include a cost centre and supplier",
        type: "error",
        position: "top-right"
      });

      return;
    }
    try {
      // eslint-disable-next-line vue/no-mutating-props
      this.currentOrder!.status = 'pending';
      // eslint-disable-next-line vue/no-mutating-props
      this.currentOrder!.price = +(this.currentOrder!.price) // should cast back to a number?
      await grooveDatabase.collection("purchase_orders").doc(this.currentOrder!.id).set(this.currentOrder!);
      this.$emit("changeCurrentOrder", null);
    } catch(error) {
      console.log(error);
      Vue.$toast.open({
        message: "Purchase Order Update Failed.",
        type: "error",
        position: "top-right"
      });
    }
  }
}
