import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/storage';
import "@firebase/auth";

const instance = firebase
    .initializeApp({
        apiKey: "AIzaSyAE4NuXl1DkSKcV6ZXwMhuXpvZ_piYxfZQ",
        authDomain: "groove-3d526.firebaseapp.com",
        databaseURL: "https://groove-3d526.firebaseio.com",
        projectId: "groove-3d526",
        storageBucket: "groove-3d526.appspot.com",
        messagingSenderId: "283357838827",
        appId: "1:283357838827:web:9fbe0f1af721f5a433edae",
        measurementId: "G-4Q0E24W2Q1"
    });

instance.analytics();

// Get a Firestore instance
export const grooveDatabase = instance.firestore();
export const grooveAuthentication = instance.auth();
export const grooveStorage = instance.storage();

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore;
export { Timestamp, GeoPoint };
