<template>
  <div
    class="event_card_wrapper-div"
    @click="load_entry(entry)"
  >
    <!-- TODO: For some reason click events are being detected on the i-card itself properly, possibly to do with the display and height attributes -->
    <div
      v-if="hBarMode !== 'tile'"
      size="sm"
      :style="width(entry)"
      class="card hbar _padding-0"
    >
      <div class="event_card_wrapper">
        <font-awesome-icon :icon="icon(entry)"/>
        {{ cost_centre_string }}
      </div>
    </div>
    <div
      v-if="hBarMode === 'tile'"
      :style="width(entry)"
      class="card m-1 p-1"
    >
      <div class="card-content p-1">
        <p class="m-0">
          <font-awesome-icon :icon="icon(entry)" />
          {{ cost_centre_string }}
        </p>
        <p class="m-0"><b-icon icon-pack="far" icon="hourglass-start" />: {{ entry.start_time | moment("hh:mma") }}</p>
        <p class="m-0"><b-icon icon-pack="far" icon="hourglass-end" />: {{ entry.end_time | moment("hh:mma") }}</p>
        <b-tooltip type="is-light" v-if="entry.description" :append-to-body="true" :label="entry.description" square>
          <p class="m-1">{{ entry.description }}</p>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import {grooveDatabase} from "@/database";

export default {
  name: "EntrySummaryVue",
  props: {
    hBarMode: {
      type: String,
      default: '24'
    },
    entry: {
      type: Object,
      required: true
    },
    date: {
      type: Object,
      default: null
    },
    currentEntry: {
      type: Object,
      default: null
    },
    timeEntryTypes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    vehicles: {
      type: Array,
      default: () => {
        return [];
      }
    },
    overlapMap: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      cost_centres: {},
      selected: false
    }
  },
  firestore: {
    cost_centres: grooveDatabase.collection("cost_centres"),
  },
  computed: {
    cost_centre_string() {
      let tt = this.timeEntryTypes.find(tet => {
        return tet.uuid === this.entry.time_entry_type
      });

      let cc = this.cost_centres.find(cc => {
        return cc.uuid === this.entry.cost_centre;
      });
      let v;
      if (cc && tt) {
        return cc.name;
      } else {
        if (this.entry.is_vehicle) {
          v = this.vehicles.find(vehicle => {
            return vehicle.id === this.entry.cost_centre
          });
        }
        if (v && tt) {
          return v.identifier;
        }
      }
      return '';
    }
  },
  watch: {
    currentEntry: {
      handler: function () {
        this.selected = this.currentEntry && this.currentEntry.id === this.entry.id;
      }
    }
  },
  methods: {
    vehicle_label: function () {
      let _this = this;
      if (this.entry && this.entry.vehicle) {
        let v = this.vehicles.find(function (vehicle) {
          return vehicle.id === _this.entry.vehicle
        });
        return v && v.registrationNumber ? v.registrationNumber : this.entry.vehicle;
      } else {
        return this.entry.vehicle
      }
    },
    load_entry: function (entry) {
      if (entry && this.currentEntry && this.currentEntry.id === entry.id) {
        this.$emit('changeEntry', null);
      } else {
        this.$emit('changeEntry', entry);
      }
    },
    /* there are two modes here - overlapping and non-overlapping - we also need to now if left overlapping or right overlapping */
    icon: function (entry) {
      if (!entry) {
        return [ 'far', 'dna'];
      }
      let tet = this.timeEntryTypes.find(function (time_entry_type) {
        return time_entry_type.uuid === entry.time_entry_type;
      });
      if (!entry || !tet) {
        return [ 'far', 'dna'];
      }

      return [ 'far', `${tet.icon}`];
    },
    width: function (entry) {

      let start_of_day;
      let end_of_day;
      let shown_day_length;

      if (this.hBarMode === '24') {
        start_of_day = this.date.startOf('day') + 0;
        end_of_day = this.date.endOf('day') + 0;
        shown_day_length = 862000;
      } else if (this.hBarMode === '12') {
        start_of_day = this.date.startOf('day') + 21600000;
        end_of_day = this.date.endOf('day') - 21600000;
        shown_day_length = 432000;
      }
      let left;
      let width;
      let padding;
      let topx;
      let br = "";

      /* TODO - handle cases that both start and end after 6:00pm and before 6:00am */

      if (entry.start_time <= start_of_day && entry.end_time >= end_of_day) { // ends tomorrow and starts yesterday
        width = 100;
        left = 0;
        br = "border-bottom-right-radius: 0px !important; border-top-right-radius: 0px !important; border-bottom-left-radius: 0px !important; border-top-left-radius: 0px !important;"
      } else if (entry.end_time > end_of_day) { // ends tomorrow
        width = (end_of_day - entry.start_time) / shown_day_length;
        left = (entry.start_time - start_of_day) / shown_day_length;
        br = "border-bottom-right-radius: 0px !important; border-top-right-radius: 0px !important;"
      } else if (entry.start_time < start_of_day) { // starts yesterday
        width = (entry.end_time - start_of_day) / shown_day_length;
        left = 0;
        br = "border-bottom-left-radius: 0px !important; border-top-left-radius: 0px !important;"
      } else {
        left = (entry.start_time - start_of_day) / shown_day_length;
        width = (entry.end_time - entry.start_time) / shown_day_length;
      }

      let tet = this.timeEntryTypes.find(function (time_entry_type) {
        return time_entry_type.uuid === entry.time_entry_type;
      });

      let color = "#dddddd";

      // If we dont have an entry type defined ( i.e. its an auto entry ) don't apply type colour.
      if(entry.type) {
        color = tet && tet.colour ? tet.colour : "#dddddd";
      }

      if (this.selected) {
        padding = "padding: 0px !important; border: 1px solid #999;"
        topx = 0;
      } else {
        padding = "padding: 2px !important; border: none;";
        topx = -1;
      }

      if (this.hBarMode !== 'tile') {
        return padding + " position: absolute; top: " + (10 + topx + ((entry.id in this.overlapMap) ? this.overlapMap[entry.id] : 1) * 30) + "px;  left: " + left + "%; width: " + width + "%; background-color: " + color + " !important; " + br;
      } else {
        return padding + " position: relative; background-color: " + color + " !important; " + br;
      }
    }
  }

}
</script>

<style scoped lang="scss">


.event_card.tile p {
  margin: 2px !important;
}

.event_card.hbar {
  display: block;
  background-color: #cccccc !important;
  cursor: pointer;
  max-height: 120px;
  overflow: hidden;
  text-wrap: avoid;
}

.event_card:hover {
  background-color: lawngreen;
}

.event_card_wrapper {
  overflow: hidden;
  text-wrap: none;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selected {
  background-color: red !important;
}

.m-1, .m-0 {
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>