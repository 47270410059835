<template>
  <div class="card p-4 mb-2">
    <div class="level">
      <b-field label="Name">
        <b-input v-model="currentTimeEntryType.name"></b-input>
      </b-field>
      <b-field label="Payroll Code">
        <b-input v-model="currentTimeEntryType.myob_code"></b-input>
      </b-field>
      <b-field label="Payroll Category">
        <b-input v-model="currentTimeEntryType.myob_category"></b-input>
      </b-field>
      <b-field label="Allowance Code">
        <b-input v-model="currentTimeEntryType.myob_allowance_code"></b-input>
      </b-field>
      </div>
    <div class="level">
      <b-field label="Icon">
        <b-input v-model="currentTimeEntryType.icon" placeholder="faTools"></b-input>
      </b-field>
      <b-field label="Colour">
        <b-input v-model="currentTimeEntryType.colour" placeholder="#ffcc99"></b-input>
      </b-field>
      <b-dropdown class="mt-4" :append-to-body="true" multiple v-model="selected">
        <template #trigger>
          <b-button
              pack="far"
              icon-right="caret-down">
            Roles ({{ selected.length }})
          </b-button>
        </template>
        <b-dropdown-item value="admin" aria-role="listitem">
          <div class="media">
            <b-icon class="media-left" pack="far" icon="shredder"></b-icon>
            <div class="media-content">
              <h3>Admin</h3>
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item value="driver" aria-role="listitem">
          <div class="media">
            <b-icon class="media-left" pack="far" icon="truck"></b-icon>
            <div class="media-content">
              <h3>Driver</h3>
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item value="yardman" aria-role="listitem">
          <div class="media">
            <b-icon class="media-left" pack="far" icon="hat-cowboy-side"></b-icon>
            <div class="media-content">
              <h3>Yardman</h3>
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item value="workshop" aria-role="listitem">
          <div class="media">
            <b-icon class="media-left" pack="far" icon="garage"></b-icon>
            <div class="media-content">
              <h3>Workshop</h3>
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="level">
      <div class="level-left">
        <div class="has-text-grey-light">{{ currentTimeEntryType.uuid }}</div>
      </div>
      <div class="level-left">
        <b-button class="ml-2" type="is-success" icon-pack="far" icon-left="floppy-disk" @click="save"></b-button>
      </div>
    </div>
  </div>
</template>

<script>
    import {grooveDatabase} from "@/database";

    export default {
        name: "TimeEntryType",
        props: {
          timeEntryType: {
            type: Object,
            default: null
          }
        },
        data: function () {
            return {
                selected: [],
                colour: '#000000',
              currentTimeEntryType: null
            }
        },
        watch: {
            selected(newVal) {
                let keys = ['driver', 'admin', 'yardman', 'workshop'];
                let keys_to_set = {}
                keys.forEach(function(key) {
                    keys_to_set[key] = !!newVal.includes(key);
                });
                grooveDatabase.collection("time_entry_types").doc(this.currentTimeEntryType.id).update({ roles: keys_to_set });
            },
          timeEntryType: {
                immediate: true,
                handler: function(newVal) {
                    const _this = this;
                    Object.keys(newVal.roles).forEach(function(key) {
                        if (newVal.roles[key] === true) {
                            _this.selected.push(key);
                        }
                    });
                }
            }
        },
      created() {
          this.currentTimeEntryType = this.timeEntryType;
      },
      methods: {
          async save() {
                try {
                    await grooveDatabase.collection("time_entry_types").doc(this.currentTimeEntryType.id).set(this.currentTimeEntryType);
                } catch(error) {
                    console.log(JSON.stringify(error));
                }
            },
        }
    }
</script>

<style scoped>
    .kind { max-width: 200px; display: inline-block; margin-right: 10px; text-transform: capitalize; }
    .kind input { text-transform: capitalize; }

    td.time_entry_type_label { width: 300px; }
    td.color, td.icon, td.myob_allowance_code, td.myob_category, td.myob_code { width: 100px; }
</style>