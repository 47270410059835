<template>
  <b-field label="Manager">
    <b-select size="is-small" @change="save()" v-model="selected_leave_approver" placeholder="Manager" icon-pack="far" icon="user">
      <option v-for="(u, idx) in filtered_users"
              :key="uidx + '_' + idx" :value="u.id">{{ u.displayName }}</option>
    </b-select>
  </b-field>
</template>

<script>
    import {grooveDatabase} from "../database";

    export default {
        name: "UserLeaverApprover",
        props: {
          user: {
            type: Object,
            default: null
          },
          users: {
            type: Array,
            default: () => {return [];}
          },
          uidx: {
            type: Number,
            default: null
          }
    },
        data: function () {
            return {
                selected_leave_approver: null,
                filtered_users: []
            }
        },
        watch: {
          user: {
              immediate: true,
              handler: function() {
                  this.selected_leave_approver = this.user.leave_approver_uuid;
              }
          },
          users: {
              deep: true,
              immediate: true,
              handler: function() {
                  this.filtered_users = this.users.filter(u => {
                      return u.displayName
                  })
              }
          },
            selected_leave_approver() {
              this.save();
            }
        },
        methods: {
            save: function() {
                grooveDatabase.collection("users").doc(this.user.id).update({ leave_approver_uuid: this.selected_leave_approver });
            }
        }
    }
</script>

<style scoped>

</style>