





















import {Component, Prop, Vue, Watch} from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import {Item} from "@road-transport-logistics/groove-core/dist/database/models/Item";
import {grooveDatabase} from "@/database";
import {entities} from "@road-transport-logistics/groove-core/dist/database/entities";
// eslint-disable-next-line no-unused-vars
import {Asset} from "@road-transport-logistics/groove-core/dist/database/models/Asset";
// eslint-disable-next-line no-unused-vars
import {Location} from "@road-transport-logistics/groove-core/dist/database/models/Location";
import grooveStore from "@/data/grooveStore";

@Component
export default class ItemList extends Vue {
  @Prop({ default: "" }) public jobId!: string
  private items: Array<Item> = []
  private vehicles: Array<Asset> = []
  private loading: boolean = false
  @Watch('jobId', {immediate: true}) async onIdChanged() {
    this.loading = true;
    const result = await grooveDatabase.collection(entities.item).where("jobId", "==", this.jobId).orderBy("productName").get();
    this.items = result.docs.map(doc => doc.data() as Item);
    this.loading = false;
  }

  mounted() {
    this.vehicles = grooveStore.state.vehicles;
  }

  public roundNumber(value: number) {
    return value.toFixed(2);
  }

  getVehicleName(uuid: string) {
    try {
      const vehicle: Asset = this.vehicles.find(asset => asset.id === uuid)!;
      return vehicle.identifier;
    } catch(error) {
      //console.log(error);
      return "Unknown";
    }
  }
}
