





































































import {Component, Prop, Vue} from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import {Job} from "@road-transport-logistics/groove-core/dist/database/models/Job";
import {generateUniqueId} from "@road-transport-logistics/groove-core/dist/utilities";
// eslint-disable-next-line no-unused-vars
import {InvoiceBatch} from "@road-transport-logistics/groove-core/dist/invoicing/types/InvoiceBatch";
import {InvoiceBatchStatus} from "@road-transport-logistics/groove-core/dist/invoicing/types/InvoiceBatchStatus";
import {DateTime} from "luxon";
// eslint-disable-next-line no-unused-vars
import {InvoiceItem} from "@road-transport-logistics/groove-core/dist/invoicing/types/InvoiceItem";
// eslint-disable-next-line no-unused-vars
import {Invoice} from "@road-transport-logistics/groove-core/dist/invoicing/types/Invoice";
import {grooveDatabase} from "@/database";
import {entities} from "@road-transport-logistics/groove-core/dist/database/entities";
// eslint-disable-next-line no-unused-vars
import {Item} from "@road-transport-logistics/groove-core/dist/database/models/Item";
// eslint-disable-next-line no-unused-vars
import {UserDetails} from "@road-transport-logistics/groove-core/dist/database/models/UserDetails";
import {DispatchSource} from "@road-transport-logistics/groove-core/dist/dispatch/enums/DispatchSource";
import {JobStatus} from "@road-transport-logistics/groove-core/dist/database/models/JobStatus";

@Component
export default class BatchGenerator extends Vue {
  @Prop({ default: [] }) private selectedJobs!: Array<Job>
  private loading: boolean = false
  private batchModalActive: boolean = false
  private launchBatchModal() {
    this.batchModalActive = true;
  }
  private closeBatchModal() {
    this.batchModalActive = false;
  }
  public async batch() {
    this.batchModalActive = false;
    try {
      this.loading = true;
      // @ts-ignore
      const userDetails = this.$root.userDetails! as UserDetails;
      // @ts-ignore
      // eslint-disable-next-line no-undef
      const user = this.$root.user! as firebase.User;
      const batchId = generateUniqueId();
      const batch: InvoiceBatch = {
        sourceId: batchId,
        sourceProvider: DispatchSource.groove,
        status: InvoiceBatchStatus.PENDING,
        timestamp: DateTime.now().toMillis(),
        total: 0,
        createdBy: {
          name: userDetails.displayName ?? "Unknown",
          email: userDetails.email!,
          uid: user.uid!,
        }
      }
      const invoices: Array<Invoice> = [];
      for( const job of this.selectedJobs) {
        const invoiceId = generateUniqueId();
        const result = await grooveDatabase.collection(entities.item).where("jobId", "==", job.id).orderBy("productName").get();
        const jobItems = result.docs.map(doc => doc.data() as Item);
        const invoice: Invoice = {
          batchId: batchId,
          customerId: job.customerId,
          customerName: job.customerName,
          date: DateTime.now().toMillis(),
          description: job.description,
          from: job.pickupName!,
          id: invoiceId,
          items: [],
          reference: invoiceId,
          to: job.dropOffName!
        }
        for( const jobItem of jobItems) {
          const item: InvoiceItem = {
            costCentre: jobItem.assetId,
            date: job.dropOffTimestamp,
            deliveryReference: jobItem.deliveryReference,
            description: jobItem.description,
            jobId: job.id,
            orderReference: jobItem.orderReference,
            productId: jobItem.productId,
            productName: jobItem.productName,
            quantity: jobItem.shippedQuantity!,
            total: jobItem.lineItemCost !== 0 ? jobItem.lineItemCost! : jobItem.shippedQuantity! * jobItem.costPerUnit!
          }
          invoice.items.push(item);
        }

        invoices.push(invoice);
      }
      console.log(batch);

      batch.total = invoices.map(invoice => invoice.items.map(item => item.total).reduce((a,b) => a+b)).reduce((a,b) => a+b);

      await grooveDatabase.runTransaction(async (transaction) => {
        const batchRef = grooveDatabase.collection(entities.invoiceBatch).doc(batchId);
        transaction.set(batchRef, batch, { merge: true });
        for(const invoice of invoices) {
          const invoiceRef = grooveDatabase
              .collection(entities.invoiceBatch)
              .doc(batch.sourceId!)
              .collection(entities.invoices)
              .doc(invoice.id);
          transaction.set(invoiceRef, invoice, { merge: true });
        }
        for(const job of this.selectedJobs) {
          const jobRef = await grooveDatabase.collection(entities.job).doc(job.id).get();
          transaction.update(jobRef.ref, {state: JobStatus.Pending});
        }
      })
      // TODO Need to set job status to invoiced.
      this.loading = false;
      this.$emit("batch-generated");
    } catch(error) {
      this.loading = false;
      Vue.$toast.open({
        message: "Error generating batch!",
        type: "error",
        position: "top-right"
      });
    }
  }
  private getClientCount(): number {
    const clientIds = this.selectedJobs.map(job => job.customerId);
    let uniqueIds = [...new Set(clientIds)];
    return uniqueIds.length;
  }
  private getTotalValue(): number {
    if(this.selectedJobs && this.selectedJobs.length > 0) {
      return this.selectedJobs.map(job => job.totalCost).reduce((a,b) => a+b);
    }
    return 0;
  }
  private getJobCount(): number {
    return this.selectedJobs.length;
  }
}
