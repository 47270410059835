<template>
  <div class="container">
    <div class="card">
      <div class="container pt-4">
        <div class="level">
          <div class="level-left">
            <b-button icon-pack="far" icon-right="arrow-left" @click="back_week"/>
            <span class="pl-2">{{
                startOfWeek.toLocaleString({
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  weekday: "short"
                })
              }}</span>
          </div>
          <div class="level-item">
            <h1 class="title">{{ currentWorkerName || "Selected Staff Member" }}</h1>
          </div>
          <div class="level-right">
            <b-tooltip :delay="500" type="is-light" :append-to-body="true"
                       label="Generate summary export - uses selected date range." class="mr-4" square>
              <b-button size="is-small" icon-pack="far" icon-right="file-export" @click="generateCostCentreReport"/>
            </b-tooltip>
            <b-tooltip :delay="500" type="is-light" :append-to-body="true" label="Switch between tiles and timebars"
                       class="mr-4" square>
              <b-button size="is-small" icon-pack="far" icon-right="sliders-up" @click="changeHBarMode"/>
            </b-tooltip>
            <span class="pr-2">{{
                startOfWeek.endOf('week').toLocaleString({
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  weekday: "short"
                })
              }}</span>
            <b-button icon-pack="far" icon-right="arrow-right" @click="next_week"/>
          </div>
        </div>
      </div>
      <EntryWeekSummary
          :date="startOfWeek"
          :time-entry-types="timeEntryTypes"
          :weekly-summary="weeklySummary"
      />
      <div class="level mb-1">
        <b-taglist class="level-item" attached>
          <b-tag size="is-small"
                 v-for="tet in timeEntryTypes.filter(entry => (entry.uuid !== 'd996d163-0cbf-40cc-8a48-92294d8e1274' && entry.uuid !== 'c33c1907-bcdf-4b27-ab7f-506753a6647e'))"
                 :key="tet.id"
                 :style="colour(tet.colour)">{{ tet.name }}
          </b-tag>
        </b-taglist>
      </div>
      <b-loading :is-full-page="false" v-model="timeEntriesLoaded" :can-cancel="true"></b-loading>
      <i-table
          id="h-table"
          size="sm"
          class="_font-size-sm"
          style="height: calc(100vh - 240px);table-layout: auto;"
      >
        <tbody v-if="currentWorker">

        <EntryDaySummary
            v-for="(date, idx) of dates"
            :key="idx"
            :h-bar-mode="tileMode"
            :currentWorker="currentWorker"
            :date="date"
            :vehicles="vehicles"
            :time-entry-types="timeEntryTypes"
            :entries="timeEntriesForDay(date)"
            @addEntry="addEntry"
            @changeWeekSummary="changeWeekSummary"
            @changeEntry="load_entry"
        />
        </tbody>
        <div id="message-container" class="container m-4" v-if="!currentWorker">
          <div class="card">
            <div class="card-content">
              <div class="container">
                <h2>Please Select Staff Member</h2>
              </div>
            </div>
          </div>
        </div>
      </i-table>
    </div>
  </div>
</template>

<script>

import {grooveDatabase} from "@/database";
import EntryDaySummary from "./EntryDaySummary";
import EntryWeekSummary from "./EntryWeekSummary";
import {DateTime} from 'luxon';
import {ExportToCsv} from 'export-to-csv';
import {generate_filter} from "@/utilities/timeEntries";

export default {
  name: "EntryTable",
  components: {EntryDaySummary, EntryWeekSummary},
  props: {
    currentWorker: {
      type: String,
      default: null
    },
    entry: {
      type: Object,
      default: null
    },
    currentFilter: {
      type: Object,
      default: null
    },
    currentEntries: {
      type: Array,
      default: () => {
        return [];
      }
    },
    currentEntry: {
      type: Object,
      default: null
    },
    timeEntryTypes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    vehicles: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      today: DateTime.now().endOf('week'),
      startOfWeek: DateTime.now().startOf('week'),
      timeEntries: [],
      timeEntriesLoaded: false,
      weeklySummary: {
        idx: 0
      },
      tileMode: '12',
      workers: [],
      scrollOptions: {
        height: 'inherit',
        size: 5
      }
    }
  },

  firestore() {
    return {
      workers: grooveDatabase
          .collection("users")
          .where('disabled', '==', false)
          .orderBy("email")
    }
  },

  computed: {
    dates() {
      const date_array = [];
      for (let i = 0; i < 7; i++) {
        date_array[i] = this.startOfWeek.plus({ days: i });
      }
      return date_array
    },
    currentWorkerName() {
      let worker = null;
      if (this.currentWorker) {
        worker = this.workers.find(worker => worker.id === this.currentWorker)
      }
      return worker ? worker.displayName || worker.email : '';
    }
  },
  watch: {
    start_of_week_as_jsdate(v) {
      this.startOfWeek = DateTime.fromJSDate(v).startOf('week');
    }
  },

  methods: {
    colour: function (c) {
      return "background-color: " + c + ";font-size:8pt;"
    },

    generateCostCentreReport: async function () {

      if (!this.currentFilter.role || !this.currentFilter.role.length > 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: `Please select a role in filter options.`,
          type: 'is-warning'
        });
        return;
      }

      const workersWithRole = this.workers.filter((worker) => {
        for (const role of this.currentFilter.role) {
          if (worker.roles[role] === true) {
            return true;
          }
        }
        return false;
      });

      const ids = workersWithRole.map(worker => worker.id);

      let filterArgs = generate_filter(this.currentFilter, true);
      filterArgs.push(['worker', 'in', ids]);
      filterArgs.push(['start_time', '>', this.startOfWeek.toMillis()]);
      filterArgs.push(['start_time', '<', this.startOfWeek.plus({ days: 7 }).toMillis()]);

      console.log(filterArgs);

      let prepareQuery = grooveDatabase.collection("time_entries");

      for (let i = 0; i < filterArgs.length; i++) {
        prepareQuery = prepareQuery.where(...filterArgs[i]);
      }

      const entries = await prepareQuery
          .orderBy("start_time")
          .get();

      const results = entries.docs.map(entry => entry.data());

      // Convert entries into a summerised form?
      // We need to iterate over all and convert into a date map?
      const userMap = new Map()
      for (const id of ids) {
        userMap.set(id, new Map())
      }

      for (const entry of results) {
        const map = userMap.get(entry.worker);
        const date = DateTime.fromMillis(entry.start_time).startOf('day').toMillis();
        if (!map.has(date)) {
          map.set(date, []);
        }
        const entryArray = map.get(date);
        entryArray.push(entry);
      }

      const summaries = [];
      userMap.forEach((dates, userKey) => {
        const staffName = this.workers.find(worker => worker.id === userKey).displayName
        dates.forEach((entries, date) => {
          const timeEntryTypes = [...new Set(entries.map(entry => entry.time_entry_type))];
          for (const currentType of timeEntryTypes) {

            const typeName = this.timeEntryTypes.find(tet => tet.uuid === currentType).name
            const summary = {
              date: DateTime.fromMillis(date).toISODate(),
              name: staffName,
              type: typeName,
              total: this.msToTime(entries
                  .filter(entry => entry.time_entry_type === currentType)
                  .map(entry => entry.end_time - entry.start_time)
                  .reduce((a, b) => a + b, 0))
            }
            summaries.push(summary);
          }
        })
      })
      const options = {
        filename: `costcentre-summary-export-${DateTime.now().toMillis()}`,
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: `Timesheet Role Export: ${new Date()}`,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };

      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(summaries);
    },
    msToTime: function (duration) {
      let milliseconds = Math.floor((duration % 1000) / 100),
          seconds = Math.floor((duration / 1000) % 60),
          minutes = Math.floor((duration / (1000 * 60)) % 60),
          hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      hours = (hours < 10) ? "0" + hours : hours;
      minutes = (minutes < 10) ? "0" + minutes : minutes;
      seconds = (seconds < 10) ? "0" + seconds : seconds;

      return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
    },
    changeHBarMode: function () {
      if (this.tileMode === 'tile') {
        this.tileMode = '12';
      } else if (this.tileMode === '12') {
        this.tileMode = '24';
      } else if (this.tileMode === '24') {
        this.tileMode = 'tile';
      }
    },
    next_week: function () {
      this.today = this.today.plus({ days: 7 });
      this.startOfWeek = this.startOfWeek.plus({ days: 7 });
      console.log("NEXT WEEK FIRING CHANGE EVENT");
      this.$emit('changeWeek', {today: this.today, start_of_week: this.startOfWeek});
    },
    back_week: function () {
      this.today = this.today.minus({ days: 7 });
      this.startOfWeek = this.startOfWeek.minus({ days: 7 });
      console.log("NEXT WEEK FIRING CHANGE EVENT");
      this.$emit('changeWeek', {today: this.today, start_of_week: this.startOfWeek});
    },
    changeWeekSummary: function (summary) {
      this.weeklySummary['idx'] = this.weeklySummary['idx'] + 1;
      this.weeklySummary[summary.date] = summary;
    },
    load_entry: function (entry) {
      this.$emit('changeEntry', entry);
    },
    timeEntriesForDay: function (date) {
      console.log(`Entries for day called: ${date.toLocaleString()}`);
      const _this = this;
      const dayStart = date.startOf('day').valueOf();
      const dayEnd = date.endOf('day').valueOf();
      return this.currentEntries
          .filter(function (timeEntry) {
            if (timeEntry.worker === _this.currentWorker && (timeEntry.start_time >= dayStart && timeEntry.start_time < dayEnd) || (timeEntry.end_time >= dayStart && timeEntry.end_time < dayEnd)) {
              return timeEntry;
            }
          });
    },
    /* this is has been shifted to EntryDaySummary - now a event flows up to this component before firing */
    addEntry: async function (v) {

      let last_entry;

      const end_of_today = v.day.endOf('day').toMillis();
      const start_of_today = v.day.startOf('day').toMillis();


      const snapshot = await grooveDatabase.collection('time_entries')
          .where('worker', '==', this.currentWorker)
          .where('deleted', '==', false)
          .where('end_time', '>=', start_of_today)
          .where('end_time', '<=', end_of_today)
          .orderBy('end_time', "desc")
          .limit(1)
          .get();

      if (snapshot && snapshot.docs.length) {
        last_entry = snapshot.docs[0].data();

        this.today = DateTime.fromMillis(last_entry.end_time).startOf('day');
        this.startOfWeek = DateTime.fromMillis(last_entry.end_time).startOf('week');
        this.$emit('changeWeek', {today: this.today, start_of_week: this.startOfWeek});
      }

      const now = last_entry ? DateTime.fromMillis(last_entry.end_time).plus({ seconds: 1 }) : DateTime.fromMillis(start_of_today).set({ hour: 8, minute: 0, second: 0 });

      // Get a new ID
      const entryReference = await grooveDatabase.collection('time_entries').doc();

      let newEntry = {
        id: entryReference.id,
        is_vehicle: false,
        asset_distance: null,
        created_by: this.$root.user.uid,
        created_by_timestamp: now.toMillis(),
        start_time: now.toMillis(),
        end_time: now.plus({ hours: 4 }).toMillis(),
        worker: this.currentWorker ? this.currentWorker : null,
        description: '',
        history: [],
        status: 'pending',
        cost_centre: null,
        deleted: false,
        type: "manual"
      }

      this.$emit('changeEntry', newEntry);
    }
  }
}
</script>

<style scoped>
.container:last-of-type, .card {
  height: inherit;
}

th {
  position: sticky;
  top: 0;
  min-width: 100px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
