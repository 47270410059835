<template>
  <div class="container">
    <div class="level">
      <div class="level-left">
        <font-awesome-icon
            class="is-success"
            :icon="{prefix: 'far', iconName: 'check'}"
            size="2x"
        />
        <span class="pl-1">{{ verified }}</span>
        <font-awesome-icon
            class="pl-1"
            :icon="{prefix: 'far', iconName: 'xmark'}"
            size="2x"
        />
        <span class="pl-1">{{ unverified }}</span>
      </div>
      <h2 class="level-item is-center">{{ total }}</h2>
      <div class="level-right">
        <div style="width: 200px;">

        </div>
      </div>
    </div>
  </div>
</template>

<script>

const overnight_allowance_type = 'd996d163-0cbf-40cc-8a48-92294d8e1274';
const overnight_allowance_type_motel = 'c33c1907-bcdf-4b27-ab7f-506753a6647e';

function pad(num) {
  return ("0" + num).slice(-2);
}

function hhmmss(secs) {
  var minutes = Math.floor(secs / 60);
  secs = secs % 60;
  var hours = Math.floor(minutes / 60)
  minutes = minutes % 60;
  return `${(hours)}h ${pad(minutes)}m`;
}

export default {
  name: "EntryWeekSummary",
  props: {
    weeklySummary: {
      type: Object,
      default: null
    },
    date: {
      // Luxon
      type: Object,
      default: null
    },
    timeEntryTypes: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data: function () {
    return {
      approved_value: 0,
      unapproved_value: 0,
      time_entries_filtered: [],
      total_value: 0
    }
  },
  computed: {
    verified() {
      return hhmmss(this.approved_value);
    },
    unverified() {
      return hhmmss(this.unapproved_value);
    },
    total() {
      return hhmmss(this.total_value);
    },
    incomplete() {
      /* use to indicate to the user that the approved/verified hours are not yet in sync */
      return this.approved_value !== this.total_value;
    },
    complete() {
      /* use to indicate to the user that the approved/verified hours are not yet in sync */
      return this.approved_value === this.total_value;
    }

  },
  watch: {
    timeEntryTypes: {
      immediate: true,
      handler: function () {
        this.time_entries_filtered = this.timeEntryTypes.filter(tet => {
          return tet.roles && (tet.roles.admin || tet.roles.yardman || tet.roles.workshop || tet.roles.dispatch)
              && (tet.uuid !== overnight_allowance_type && tet.uuid !== overnight_allowance_type_motel)

        });
      }
    },
    weeklySummary: {
      deep: true,
      immediate: true,
      handler: function () {
        let approved_total = 0;
        let unapproved_total = 0;
        let day = this.date;
        for (let i = 0; i < 7; i++) {
          if (day.toMillis() in this.weeklySummary) {
            approved_total = approved_total + this.weeklySummary[day.valueOf()].approved_value;
            unapproved_total = unapproved_total + this.weeklySummary[day.valueOf()].unapproved_value;
          }
          day = day.plus({days: 1});
        }

        this.approved_value = approved_total;
        this.unapproved_value = unapproved_total;
        this.total_value = approved_total + unapproved_total;
      }
    }
  }
}
</script>

<style scoped>
.content h2:not(:first-child) {
  margin-top: 0;
}

.is-success {
  color: #48c774;
}

td {
  width: 150px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

td:last-child {
  width: 100% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.summary-label {
  display: inline-block;
  width: 100px;
}

.summary-value {
  display: inline-block;
  width: 100px;
}

tr.weekly-summary {
  font-weight: bold;
}

.key-bar {
  border-radius: 5px;
}

.incomplete {
  color: red;
}

.complete {
  color: lawngreen;
}
</style>
