

































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {grooveDatabase} from "@/database";
import firebase from 'firebase/app';
// eslint-disable-next-line no-unused-vars
import {UserDetails} from "@road-transport-logistics/groove-core/dist/database/models/UserDetails";
import grooveStore from "@/data/grooveStore";


@Component
export default class App extends Vue {
  @Prop({ default: undefined }) private user?: firebase.User
  private userDetails?: UserDetails = undefined;
  public userDetailsLoaded: boolean = false;
  private sidebarOpen: boolean = false;
  private collapsed: boolean = false;
  private version: string = process.env.VUE_APP_VERSION;
  private hash: string = process.env.VUE_APP_GIT_REVISION;
  private loading: boolean = false

  @Watch('user', {
    immediate: true, deep: false
  })
  async userUpdated(user: firebase.User) {
    if ( user && user.uid && !this.userDetails ) {
      await this.$bind('userDetails', grooveDatabase.collection("users").doc(user.uid));
      this.userDetailsLoaded = true;
    }
  }

  created() {
  }

  logout() {
    this.userDetailsLoaded = false;
    this.userDetails = undefined;
    this.user = undefined;
    firebase.auth().signOut()
  }

  closeMenu() {
    this.sidebarOpen = false;
  }

  async mounted() {
    this.loading = true;
    // Dispatch all and wait....
    await Promise.all([
      grooveStore.dispatch('bindDivisions'),
      grooveStore.dispatch('bindDepots'),
      grooveStore.dispatch('bindClients'),
      grooveStore.dispatch('bindVehicles'),
    ])
    this.loading = false;
  }
}
