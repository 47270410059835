
























































































































































































import {Component, Vue} from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import {JobFilter} from "@/components/JobList/JobFilter";
// eslint-disable-next-line no-unused-vars
import {Client} from "@road-transport-logistics/groove-core/dist/database/models/Client";
// eslint-disable-next-line no-unused-vars
import {InvoiceBatch} from "@road-transport-logistics/groove-core/dist/invoicing/types/InvoiceBatch";
import {DateTime} from "luxon";
import {grooveDatabase} from "@/database";
import {entities} from "@road-transport-logistics/groove-core/dist/database/entities";
import {roundNumber} from "@/utilities/number";
import InvoiceList from "@/components/Invoicing/InvoiceList.vue";
import {InvoiceBatchStatus} from "@road-transport-logistics/groove-core/dist/invoicing/types/InvoiceBatchStatus";
import {JobStatus} from "@road-transport-logistics/groove-core/dist/database/models/JobStatus";
@Component({
  components: {InvoiceList}
})
export default class Batches extends Vue {
  private selected_id: string = ""
  private clients: Array<Client> = []
  private states: Array<string> = []
  private batches: Array<InvoiceBatch> = []
  private loading: boolean = false
  private expandFilters: boolean = true
  private currentFilter: JobFilter = {
    id: undefined,
    costCentre: undefined,
    selectedDepot: undefined,
    selectedDivision: undefined,
    selectedStatus: [],
    timestampStart: undefined,
    timestampEnd: undefined,
    deleted: false,
    client: undefined,
    user: undefined,
    showDeleted: false,
  }

  public clearFilters()  {
    this.currentFilter = {
      id: undefined,
      costCentre: undefined,
      selectedDepot: undefined,
      selectedDivision: undefined,
      selectedStatus: [],
      timestampStart: undefined,
      timestampEnd: undefined,
      deleted: false,
      client: undefined,
      user: undefined,
      showDeleted: false
    }
    this.selected_id = "";
  }

  public timestampFormatted(invoiceBatch: InvoiceBatch): string {
    const date = DateTime.fromMillis(invoiceBatch.timestamp);
    return date.toFormat("ccc, dd/LL/yyyy hh:mm a");
  }

  get filtersActive() {
    return (this.currentFilter.selectedDivision)
        || (this.currentFilter.selectedDepot)
        || (this.currentFilter.selectedStatus?.length > 0)
        || (this.currentFilter.client !== undefined)
        || (this.currentFilter.user !== undefined)
        || (this.currentFilter.id !== undefined)
  }

  async mounted() {
    this.states = Object.values(InvoiceBatchStatus);
    await this.$bind("batches", grooveDatabase.collection(entities.invoiceBatch).orderBy("timestamp", "desc"));
  }

  formatNumber(value: number) {
    return roundNumber(value, 2);
  }

  async deleteBatch(batch: InvoiceBatch) {
    console.log(`BATCH: ${batch.sourceId}`);
    const jobs: Array<string> = [...batch.invoices!.flatMap(invoice => invoice.items.flatMap(item => item.jobId!))];
    console.log(jobs);
    try {
      await grooveDatabase.runTransaction(async (transaction) => {
        for(const jobId of jobs) {
          transaction.update(grooveDatabase.collection(entities.job).doc(jobId), { state: JobStatus.Accepted });
        }
        transaction.update(grooveDatabase.collection(entities.invoiceBatch).doc(batch.sourceId), { status: InvoiceBatchStatus.DELETED })
      });
    } catch(error) {
      console.error(error);
    }
  }

  async reProcessBatch(batch: InvoiceBatch) {
    console.log(`BATCH: ${batch.sourceId}`);
    const jobs: Array<string> = [...batch.invoices!.flatMap(invoice => invoice.items.flatMap(item => item.jobId!))];
    console.log(jobs);
    try {
      await grooveDatabase.runTransaction(async (transaction) => {
        for(const jobId of jobs) {
          transaction.update(grooveDatabase.collection(entities.job).doc(jobId), { state: JobStatus.Pending });
        }
        transaction.update(grooveDatabase.collection(entities.invoiceBatch).doc(batch.sourceId), { status: InvoiceBatchStatus.PENDING })
      });
    } catch(error) {
      console.error(error);
    }
  }
}
