<template>
  <i-container
    class="_text-left _height-100"
    fluid
  >
    <i-row style="height: calc(100% - 120px);">
      <i-column
        id="upcoming"
        xs="2"
        class="_hidden-only"
      >
        <ul>
          <li
            v-for="(task, idx) in tasks"
            :key="idx"
          >
            <div v-if="task.distanceAndDuration">
              {{ task.customerName }} ({{ task.registrationNumber }})<br>
              {{ renderDate(task.distanceAndDuration.arrivalTimeEpochSeconds) }}
            </div>
          </li>
        </ul>
      </i-column>
      <i-column
        id="map"
        xs="12"
      >
        <div
          v-if="latest_job"
          id="next"
          class="big"
          style="position: relative;"
        >
          <h4 style="position: absolute; top: -32px; font-weight: bold; font-size: 14px; left: 1px; ">
            Next Arrival
          </h4>
          <span
            v-if="countdown(latest_job.distanceAndDuration.arrivalTimeEpochSeconds) > 0"
            style="color: red"
          >T + {{ countdown(latest_job.distanceAndDuration.arrivalTimeEpochSeconds) }} mins</span>
          <span
            v-if="countdown(latest_job.distanceAndDuration.arrivalTimeEpochSeconds) <= 0"
            style="color: black"
          >T {{ countdown(latest_job.distanceAndDuration.arrivalTimeEpochSeconds) }} mins</span>
        </div>
        <div
          v-if="latest_next_job"
          id="next_after"
          class="big"
          style="position: relative;"
        >
          <h4 style="position: absolute; top: -32px; font-weight: bold; font-size: 14px; left: 1px; ">
            Subsequent Arrival
          </h4>
          <span
            v-if="countdown(latest_next_job.distanceAndDuration.arrivalTimeEpochSeconds) > 0"
            style="color: red"
          >T + {{ countdown(latest_next_job.distanceAndDuration.arrivalTimeEpochSeconds) }} mins</span>
          <span
            v-if="countdown(latest_next_job.distanceAndDuration.arrivalTimeEpochSeconds) <= 0"
            style="color: black"
          >T {{ countdown(latest_next_job.distanceAndDuration.arrivalTimeEpochSeconds) }} mins</span>
        </div>
        <LMap
          ref="myMap"
          style="height: 100%; width: 100%;"
          :center="center"
          :zoom="zoom"
        >
          <l-tile-layer
            :url="url"
            :attribution="attribution"
          />
          <span
            v-for="(vehicle, idx) in vehicle_positions"
            :key="idx"
          >
            <span
              v-for="(vv, xidx) in vehicle"
              :key="idx + '-' + xidx"
              :radius="3"
              :lat-lng="calcPos(vv)"
            >
              <l-circle-marker
                v-for="(markerVehicle, markerIndex) in vehicle"
                :key="idx + '-' + markerIndex"
                :radius="3"
                :lat-lng="calcPos(markerVehicle)"
              />
            </span>
          </span>
        </LMap>
      </i-column>
    </i-row>
  </i-container>
</template>

<script>

import {grooveDatabase} from "../database";

import {LMap, LTileLayer, LCircleMarker} from 'vue2-leaflet';
import {latLng} from "leaflet";
import moment from 'moment';
import Vue from "vue";

export default {
  name: "Tracking",
  components: { LMap, LTileLayer, LCircleMarker },
  data: function() {
    return {
      tasks: [],
      started_tasks: [],
      jobs: [],
      vehicles: [],
      bounds: [],
      vehicles_to_track: [],
      vehicle_positions: {},
      latest_job: null,
      latest_next_job: null,
      center: [-45.505, 169.30],
      zoom: 8,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }
  },
  watch: {
    '$root.user': {
      handler: function() {
        this.loadData();
      }
    },
    jobs: {
      handler: function() {
        this.started_tasks = this.tasks.filter(task => {
          var job = this.jobs.find(job => {
            return job.id === task.jobId && job.status === 'Started'
          });
          return job
        });

        if (this.started_tasks.length) {

          this.latest_job = this.started_tasks[this.started_tasks.length - 1] /* TODO - we really want the closest job to now that isn't yet completed, e.g. complete: false */

          if (this.started_tasks.length >= 2)
            this.latest_next_job = this.started_tasks[this.started_tasks.length - 2] /* TODO - we really want the closest job to now that isn't yet completed, e.g. complete: false */


          this.started_tasks.map(task => {
            var vehicle = null;
            if (task.vehicleId) {
              vehicle = this.vehicles.find(v => {
                return v.id === task.vehicleId
              });
              if (vehicle) {
                task.vehicleName = vehicle.identifier;
                task.registrationNumber = vehicle.registrationNumber;
              }
            }
          })

          var v = this.started_tasks.map(t => {
            return t.vehicleId;
          }).sort()

          this.vehicles_to_track = [...(new Set(v))]

        }
      }
    },
    vehicles_to_track() {
      var _this = this;
      this.vehicles_to_track.forEach(vehicle => {

        var id = vehicle;

        var query = grooveDatabase.collection("telemetry")
            .where("assetId", "==", id)
            .orderBy("timestamp", "desc")
            .limit(1);

        Vue.set(_this.vehicle_positions, id, null);
        _this.$bind(`vehicle_positions.${id}`, query);

      })
    },
    latest_job() {
      // this.center = [n.location.latitude, n.location.longitude];
    }
  },
  mounted() {
    if ( this.$root.user && this.$root.user.uid ) {
      this.loadData();
    }
  },
  methods: {
    calcPos: function(pos) {
      return latLng(pos.latitude, pos.longitude);
    },
    renderDate(epoch) {
      return moment.unix(epoch).format("DD/MM/YYYY HH:mm:ss");
    },
    countdown(epoch) {
      var now = moment();
      var difference = ((epoch - now.unix()) * 1000) / 60000;
      if ( difference > 0 ) {
        return Math.floor(difference * -1);
      }
      return Math.floor(difference);
    },
    loadData() {
      var _this = this;
      _this.today = moment();
      this.$bind('currentUser', grooveDatabase.collection("users").doc(this.$root.user.uid)).then(() => {
        Promise.all([
          _this.$bind('vehicles', grooveDatabase.collection("vehicles")),
          _this.$bind('tasks', grooveDatabase.collection("integrations").doc("market2x").collection("tasks")
              .where("distanceAndDuration.arrivalTimeEpochSeconds", "<=", this.today.clone().endOf('day').valueOf() / 1000)
              .where("distanceAndDuration.arrivalTimeEpochSeconds", ">=", this.today.clone().startOf('day').valueOf() / 1000)
              .where("complete", "==", false)
              .where("type", "==", "DELIVERY")

              // .where("customerId", "==", "ce853ccd-e731-486a-9b87-a74fbbf160f1")
              .where("customerId", "==", this.currentUser.customerId)
              .limit(100)
              .orderBy("distanceAndDuration.arrivalTimeEpochSeconds", "desc")
          ),
        ]).then(() => {
          _this.$bind('jobs', grooveDatabase.collection("integrations").doc("market2x").collection("jobs")
              // .where("customerId", "==", "ce853ccd-e731-486a-9b87-a74fbbf160f1")
              .where("customerId", "==", this.currentUser.customerId)
              .where("status", "==", "Started")
              .limit(100)
          );
          this.loaded = true;
        })
      });
    }
  },
  calculated: {

  }
}
</script>

<style scoped>
h6 { margin-top: 15px; }

#upcoming ul { list-style: none; margin: 0px; padding: 0px;}
#upcoming ul li { border-bottom: 1px solid #999; }
.big { width: calc(50% - 20px); float: left; height: 100px; margin: 10px; font-size: 4em; border: 2px solid #999; border-radius: 5px; }
</style>