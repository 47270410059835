<template>
  <tr class="p-2">
    <td style="width: 100%;white-space: nowrap;">
      <div class="card p-1 m-1">
        <div class="container">
          <div class="level mt-1 mb-1">
            <div class="level-left">
              <h4>{{ renderDate(date) }}</h4>
              <font-awesome-icon
                  class="ml-2"
                  style="color: #ffdd57"
                  v-if="status === 'locked'"
                  :icon="{prefix: 'far', iconName: 'lock'}"
                  size="2x"
              />
            </div>
            <div class="level-right">
              <div class="level-item">
                <font-awesome-layers v-if="has_overtime_entry" full-width class="fa-2x">
                  <font-awesome-icon
                      :icon="{prefix: 'far', iconName: 'bed'}"
                      size="1x"
                      @click="checkOvertimeExists"
                  />
                  <font-awesome-layers-text counter :value="get_overnight_entry_type" position="top-right"/>
                </font-awesome-layers>
              </div>
              <div class="level">
                <div class="level-item">
                  <font-awesome-icon
                      :icon="{prefix: 'far', iconName: 'clock'}"
                      size="2x"
                  />
                </div>
                <div class="level-item">
                  <h4>{{ hours_worked.total_text }}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="level mb-1">
            <div class="level">
              <div class="level-left">
                <div class="card">
                  <l-map
                      style="height: 100px; width: 150px;"
                      :center="center"
                      :zoom="zoom"
                      :options="{ zoomControl: false }"
                  >
                    <l-control-fullscreen/>
                    <l-tile-layer :url="url"/>
                    <l-polyline v-for="(vehicle, index) of vehiclesUsed" v-bind:key="vehicle.id"
                                :lat-lngs="vehicle.telemetry" :color="mapColours[index]"></l-polyline>
                  </l-map>
                </div>
              </div>
              <div class="level-right container">
                <div class="container pr-0">
                  <div class="level mb-1">
                    <div class="level-item">
                      <font-awesome-icon
                          :icon="{prefix: 'far', iconName: 'sun'}"
                          size="1x"
                      />
                      <p class="pl-1">{{ day_length }}</p>
                    </div>
                  </div>

                  <div style="width: 120px; height: 50px;" v-if="vehiclesUsed.length === 0" class="card mb-2">
                    <div class="container p-0 m-0">
                      <b>No Vehicle Data</b>
                    </div>
                  </div>

                  <b-carousel-list v-if="vehiclesUsed.length > 0" v-model="carouselIndex" :data="vehiclesUsed" :items-to-show="1" :repeat="false"
                                   style="width: 120px;">
                    <template #item="vehicle">
                      <div class="card">
                        <div class="container p-0 m-0">
                          <b>{{ vehicle.identifier }}</b>
                        </div>
                        <div class="level mb-1">
                          <div class="level-item">
                            <font-awesome-icon
                                :icon="{prefix: 'far', iconName: 'truck'}"
                                size="1x"
                            />
                            <p class="pl-1">{{ vehicle.worked }}</p>
                          </div>
                        </div>
                        <div class="level mb-1">
                          <div class="level-item">
                            <font-awesome-icon
                                :icon="{prefix: 'far', iconName: 'sun'}"
                                size="1x"
                            />
                            <p class="pl-1">{{ vehicle.dayLength }}</p>
                          </div>
                        </div>
                        <div class="level mb-1">
                          <div class="level-item">
                            <font-awesome-icon
                                :icon="{prefix: 'far', iconName: 'gauge'}"
                                size="1x"
                            />
                            <p class="pl-1">{{ vehicle.km ? round(vehicle.km) : 0 }} km</p>
                          </div>
                        </div>
                      </div>
                    </template>
                  </b-carousel-list>
                  <div class="level">
                    <div class="level-left">
                      <span class="level-item"></span>
                    </div>
                    <div class="level-right">
                      <div class="level-item mr-1">
                        <b-button v-if="status !== 'locked'" :type="status" :disabled="status === 'is-success'" icon-pack="far" icon-left="circle-check" @click="approve_day"></b-button>
                      </div>
                      <div class="level-item">
                        <b-button :disabled="status === 'locked'" icon-pack="far" icon-left="circle-plus" @click="add_entry"></b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td
        style="position: relative;"
    >

      <div v-if="hBarMode === '12'">
        <div style="position: absolute; left: calc(100% / 12 * 0); top: 0;">
          <div
              v-if="show_time_scale"
              class="clock-time"
          >
            6:00am
          </div>
        </div>
        <div style="position: absolute; left: calc(100% / 12 * 2); top: 0;">
          <div
              v-if="show_time_scale"
              class="clock-time"
          >
            8:00am
          </div>
        </div>
        <div style="position: absolute; left: calc(100% / 12 * 4); top: 0;">
          <div
              v-if="show_time_scale"
              class="clock-time"
          >
            10:00am
          </div>
        </div>
        <div style="position: absolute; left: calc(100% / 12 * 6); top: 0;">
          <div
              v-if="show_time_scale"
              class="clock-time"
          >
            12:00pm
          </div>
        </div>
        <div style="position: absolute; left: calc(100% / 12 * 8); top: 0;">
          <div
              v-if="show_time_scale"
              class="clock-time"
          >
            2:00pm
          </div>
        </div>
        <div style="position: absolute; left: calc(100% / 12 * 10); top: 0; ">
          <div
              v-if="show_time_scale"
              class="clock-time"
          >
            4:00pm
          </div>
        </div>
      </div>
      <div v-if="hBarMode === '24'">
        <div style="position: absolute;  left: calc(100% / 24 * 4); top: 0;">
          <div
              v-if="show_time_scale"
              class="clock-time"
          >
            4:00am
          </div>
        </div>
        <div style="position: absolute; left: calc(100% / 24 * 8); top: 0;">
          <div
              v-if="show_time_scale"
              class="clock-time"
          >
            8:00am
          </div>
        </div>
        <div style="position: absolute; left: calc(100% / 24 * 12); top: 0;">
          <div
              v-if="show_time_scale"
              class="clock-time"
          >
            12:00am
          </div>
        </div>
        <div style="position: absolute; left: calc(100% / 24 * 16); top: 0;">
          <div
              v-if="show_time_scale"
              class="clock-time"
          >
            16:00pm
          </div>
        </div>
        <div style="position: absolute; left: calc(100% / 24 * 20); top: 0;">
          <div
              v-if="show_time_scale"
              class="clock-time"
          >
            20:00pm
          </div>
        </div>
      </div>

      <div v-if="hBarMode !== 'tile'"
           :style="'position: relative; min-height: 101px; height: ' + (40 + (max_overlap * 30)) + 'px; width: 100%; top: 0px; overflow: hidden;margin-top:30px;'"
      >
        <div v-if="hBarMode !== 'tile'"
             style="position: absolute; left: calc(100% / 24 * 0); height: 100%; top: 0; width: 1px; background-color: #dddddd;"/>
        <div v-if="hBarMode !== 'tile'"
             style="position: absolute; left: calc(100% / 24 * 4); height: 100%; top: 0; width: 1px; background-color: #dddddd;"/>
        <div v-if="hBarMode !== 'tile'"
             style="position: absolute; left: calc(100% / 24 * 8); height: 100%; top: 0;width: 1px; background-color: #dddddd;"/>
        <div v-if="hBarMode !== 'tile'"
             style="position: absolute; left: calc(100% / 24 * 12); height: 100%; top: 0;width: 1px; background-color: #dddddd;"/>
        <div v-if="hBarMode !== 'tile'"
             style="position: absolute; left: calc(100% / 24 * 16); height: 100%; top: 0;width: 1px; background-color: #dddddd;"/>
        <div v-if="hBarMode !== 'tile'"
             style="position: absolute; left: calc(100% / 24 * 20); height: 100%; top: 0; width: 1px; background-color: #dddddd;"/>
        <EntrySummary
            v-for="(entry, idx) of entries_with_overlaps"
            :key="idx"
            :vehicles="vehicles"
            :h-bar-mode="hBarMode"
            :time-entry-types="timeEntryTypes"
            :entry="entry"
            :date="date"
            :overlap-map="overlapMap"
            @changeEntry="load_entry"
        />
      </div>
      <div class="grid" v-if="hBarMode === 'tile'" >
        <div style="height: 100px;width: 150px;" class="item" :id="'item-sm-' + index" :key="index" v-for="(item, index) in entries_with_overlaps">
          <div class="item-content">
            <EntrySummary
                :vehicles="vehicles"
                :h-bar-mode="hBarMode"
                :time-entry-types="timeEntryTypes"
                :entry="item"
                :date="date"
                :overlap-map="overlapMap"
                @changeEntry="load_entry"
            />
          </div>
        </div>
      </div>
      <!--<div class="vuuri-container" v-if="hBarMode === 'tile'" style="height: 100%;">
        <vuuri v-if="entries_with_overlaps" v-model="entries_with_overlaps" :get-item-height="getItemHeight"
               :get-item-width="getItemWidth" items="">
          <template #item="{ item }">
            <EntrySummary
                :vehicles="vehicles"
                :h-bar-mode="hBarMode"
                :time-entry-types="timeEntryTypes"
                :entry="item"
                :date="date"
                :overlap-map="overlapMap"
                @changeEntry="load_entry"
            />
          </template>
        </vuuri>
      </div>-->

    </td>
  </tr>
</template>

<script>

/* help function to render seconds into hours and minutes */
import {grooveDatabase} from "@/database";
/* setup component imports */
import EntrySummary from "./EntrySummary";
import {LMap, LPolyline, LTileLayer} from 'vue2-leaflet';
import LControlFullscreen from 'vue2-leaflet-fullscreen';
import {latLng} from "leaflet/dist/leaflet-src.esm";
import {formatSeconds} from "@/utilities/time";
import {DateTime} from "luxon";
import {roundNumber} from "@/utilities/number";

// Overnight Motel - with meal
const overnight_allowance_1 = 'd996d163-0cbf-40cc-8a48-92294d8e1274';
// Overnight Motel - meal self supplied
const overnight_allowance_2 = 'c33c1907-bcdf-4b27-ab7f-506753a6647e';

function pad(num) {
  return ("0" + num).slice(-2);
}

function formatEpoch(secs) {
  let minutes = Math.floor(secs / 60);
  const hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  return `${(hours)}h ${pad(minutes)}m`;
}

export default {
  name: "EntryDaySummary",
  components: {EntrySummary, LMap, LTileLayer, LPolyline, LControlFullscreen},
  props: {
    date: {
      type: Object,
      default: null
    },
    currentWorker: {
      type: String,
      default: null
    },
    entries: {
      type: Array,
      default: () => {
        return [];
      }
    },
    timeEntryTypes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // Contains all vehicles.
    vehicles: {
      type: Array,
      default: () => {
        return [];
      }
    },
    hBarMode: {
      type: String,
      default: '24'
    }
  },

  data: function () {
    return {
      carouselIndex: 0,
      logbookThumbnailUrl: null,
      logbookUrl: null,
      mapColours: [
        "#209cee",
        "#23d160",
        "#ffdd57",
        "#ff3860"
      ],
      modal_visible: false,
      isImageModalActive: false,
      overlapMap: {},
      max_overlap: 0,
      vehiclesUsed: [],
      vehicle_table_key: 120,
      // Map
      center: [-45.505, 169.30],
      zoom: 8,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      muuri: null
    }
  },

  computed: {

    /* this function determines which entries overlap and push them into a different row (e.g. changes the top css) */
    entries_with_overlaps: function () {
      const _this = this;

      let existing_end = 0;
      let overlap = 0;
      /* order them sequentially - does next entry overlap previous entry, if yes overlap++, if no, if overlap > 0, overlap-- */
      this.entries.filter(entry => {
        return entry.time_entry_type !== overnight_allowance_1 && entry.time_entry_type !== overnight_allowance_2 /* still have to exclude these entries first to prevent the overlap moving things */
      }).map(function (current_entry) {
        /* can't be overlapping */
        if (current_entry.start_time >= existing_end) {
          existing_end = current_entry.end_time;

          /* this must be tracking the current vertical position of the current element */
          if (overlap > 0) {
            --overlap;
          }
          _this.overlapMap[current_entry.id] = overlap;
        }
        /* is overlapping */
        else {
          /* ignore - completely overlapping */
          if (current_entry.end_time <= existing_end && current_entry.start_time <= existing_end) {
            _this.overlapMap[current_entry.id] = ++overlap;
          } else if (current_entry.end_time <= existing_end || current_entry.start_time <= existing_end) {
            _this.overlapMap[current_entry.id] = ++overlap;
            existing_end = current_entry.end_time;
          }
        }
      })

      let tmp_overlap = 0;
      Object.keys(_this.overlapMap).forEach(v => {
        if (v in _this.overlapMap && (_this.overlapMap[v] > tmp_overlap)) {
          tmp_overlap = _this.overlapMap[v];
        }
      });

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      _this.max_overlap = tmp_overlap;
      return this.entries.filter(entry => {
        return entry.time_entry_type !== overnight_allowance_1 && entry.time_entry_type !== overnight_allowance_2
      });
    },

    has_overtime_entry: function () {
      return this.entries.some(e => {
        return e.time_entry_type === overnight_allowance_1 || e.time_entry_type === overnight_allowance_2
      });
    },

    // Returns 1 or 2 based on overnight entry type to allow showing such in the summary.
    get_overnight_entry_type: function () {
      const entry = this.entries.find(e => {
        return e.time_entry_type === overnight_allowance_1 || e.time_entry_type === overnight_allowance_2
      });
      return entry.time_entry_type === overnight_allowance_1 ? 1 : 2
    },

    /* used for the date/clock helper labels */
    show_time_scale: function () {
      // return this.date.day() === 1;
      return true;
    },

    /* calculate by ignoring overlap - TODO - write a decent unit test for this - seems a bit sketchy and relies on correct order */
    hours_worked: function () {
      let existing_end = 0;
      const dayStart = this.date.startOf('day').toMillis();
      const dayEnd = this.date.endOf('day').toMillis();

      // We want these calcs only performed on manual entries regardless of auto mode being enabled or not.
      const filteredEntries = this.getManualEntries();

      const approved_secs = filteredEntries.filter(e => e.status === 'approved' || e.status === 'locked').reduce(function (total, current_entry) {

        let difference = 0;

        if (current_entry.start_time > existing_end) {
          existing_end = current_entry.end_time;

          /* this should never happen - a job crosses over three days */
          /* exclude unpaid breaks */
          if (current_entry.time_entry_type === 'b933f35b-a04e-4a6b-827c-b29e1f39093d') {
            return total;
          } else if (current_entry.end_time >= dayEnd && current_entry.start_time <= dayStart) {
            return (total + (86400000 / 1000));
          } else if (current_entry.end_time >= dayEnd) {
            return (total + (dayEnd - current_entry.start_time + 1000) / 1000);
          } else if (current_entry.start_time <= dayStart) {
            return (total + (current_entry.end_time - dayStart + 1000) / 1000);
          } else {
            return (total + (current_entry.end_time - current_entry.start_time) / 1000);
          }
        }
        /* is overlapping */
        else {
          /* ignore - completely overlapping */
          if (current_entry.time_entry_type === 'b933f35b-a04e-4a6b-827c-b29e1f39093d' && current_entry.end_time <= existing_end) {
            return (total - (current_entry.end_time - current_entry.start_time) / 1000);
          } else if (current_entry.time_entry_type === 'b933f35b-a04e-4a6b-827c-b29e1f39093d' && current_entry.end_time > existing_end) {
            return (total - (existing_end - current_entry.start_time) / 1000);
          } else if (current_entry.end_time <= existing_end) {
            return total;
          } else {
            /* only add the difference to total */
            if (current_entry.end_time > existing_end) {
              difference = (current_entry.end_time - existing_end) / 1000
              existing_end = current_entry.end_time;
              return (total + difference);
            }
          }
        }
      }, 0);

      existing_end = 0;

      let unapproved_secs = filteredEntries.filter(e => e.status === 'pending' || e.status === 'new').reduce(function (total, current_entry) {

        let difference = 0;


        /* can't be overlapping */
        if (current_entry.start_time > existing_end) {
          existing_end = current_entry.end_time;

          /* this should never happen - a job crosses over three days */
          /* exclude unpaid breaks */
          if (current_entry.time_entry_type === 'b933f35b-a04e-4a6b-827c-b29e1f39093d') {
            return total;
          } else if (current_entry.end_time >= dayEnd && current_entry.start_time <= dayStart) {
            return (total + (86400000 / 1000));
          } else if (current_entry.end_time >= dayEnd) {
            return (total + (dayEnd - current_entry.start_time + 1000) / 1000);
          } else if (current_entry.start_time <= dayStart) {
            return (total + (current_entry.end_time - dayStart + 1000) / 1000);
          } else {
            return (total + (current_entry.end_time - current_entry.start_time) / 1000);
          }
        }
        /* is overlapping */
        else {
          /* ignore - completely overlapping */
          if (current_entry.time_entry_type === 'b933f35b-a04e-4a6b-827c-b29e1f39093d' && current_entry.end_time <= existing_end) {
            return (total - (current_entry.end_time - current_entry.start_time) / 1000);
          } else if (current_entry.time_entry_type === 'b933f35b-a04e-4a6b-827c-b29e1f39093d' && current_entry.end_time > existing_end) {
            return (total - (existing_end - current_entry.start_time) / 1000);
          } else if (current_entry.end_time <= existing_end) {
            return total;
          } else {
            /* only add the difference to total */
            if (current_entry.end_time > existing_end) {
              difference = (current_entry.end_time - existing_end) / 1000
              existing_end = current_entry.end_time;
              return (total + difference);
            }
          }
        }
      }, 0);


      return {
        date: this.date.startOf('day').toMillis(),
        approved_text: formatEpoch(approved_secs),
        approved_value: approved_secs,
        unapproved_text: formatEpoch(unapproved_secs),
        unapproved_value: unapproved_secs,
        total_text: formatEpoch(approved_secs + unapproved_secs),
        total_value: approved_secs + unapproved_secs
      }
    },

    /* calculate the day length of a worker - TODO - how does this work for jobs that overlap midnight */
    day_length: function () {

      const today = this.date;
      // Remove overnight entries from calc.
      const filteredEntries = this.removeOvernightEntries();

      const earliest_timestamp = filteredEntries.reduce(function (earliest, current_entry) {
        if (earliest === 0 || current_entry.start_time < earliest) {
          if (current_entry.start_time < today.startOf('day')) {
            return today.startOf('day').toMillis();
          }
          return current_entry.start_time;
        }
        return earliest;
      }, 0);

      const oldest_timestamp = filteredEntries.reduce(function (oldest, current_entry) {
        if (current_entry.end_time > oldest) {
          if (current_entry.end_time > today.endOf('day')) {
            return today.endOf('day').toMillis() + 1000;
          }
          return current_entry.end_time;
        }
        return oldest;
      }, 0);

      return formatEpoch((oldest_timestamp - earliest_timestamp) / 1000);
    },

    approved: function () {
      return this.entries.every(function (entry) {
        return entry.status === "approved";
      });
    },

    status: function () {

      if (this.entries.every(e => e.status === 'approved')) {
        return "is-success";
      }
      if (this.entries.every(e => e.status === 'locked')) {
        return "locked";
      }
      return "";
    },

    approve: function () {
      return !this.entries.every(function (entry) {
        return entry.status !== "approved";
      });
    },


    isEmpty: function () {
      return this.entries.length === 0
    }

  },

  watch: {
    entries: {
      immediate: false,
      handler() {
        console.log(`ENTRIES UPDATED! : ${this.date.toLocaleString()}`);
        const _this = this;

        // To prevent triggering multiple updates we just create a new array and assign it once.
        const newVehiclesUsed = []

        // Removes overnight.
        const filteredEntries = this.removeOvernightEntries();

        // Generate list of unique vehicle ids.
        const uniqueIds = [...new Set(filteredEntries.map(function (entry) {
          return entry.is_vehicle && entry.cost_centre ? entry.cost_centre : null
        }).filter(id => id))];

        // Generate objects for each cost centre that is defined as a vehicle
        // These are populated by a watcher.
        uniqueIds.forEach((id) => {
          const vehicle = _this.vehicles.find(vehicle => {
            return vehicle.id === id
          });
          if (vehicle) {
            newVehiclesUsed.push({
              id: id,
              identifier: vehicle.identifier,
              worked: 0,
              dayLength: 0,
              telemetry: []
            });
          }
        })

        this.vehiclesUsed = newVehiclesUsed;
      }
    },
    vehiclesUsed: {
      async handler() {
        console.log("VEHICLES UPDATED");
        const _this = this;
        const start_of_day = this.date.startOf('day').toMillis();
        const end_of_day = this.date.endOf('day').toMillis();
        this.center = [-45.505, 169.30];

        for (const entry of this.vehiclesUsed) {

          const telemetryQuery = grooveDatabase.collection("telemetry")
              .where("assetId", "==", entry.id)
              .where("timestamp", ">=", start_of_day)
              .where("timestamp", "<=", end_of_day)
              .orderBy("timestamp", "asc");

          const summaryQuery = grooveDatabase.collection("telemetry_summaries")
              .doc(entry.id).collection("entries").doc(`${start_of_day}`);

          const results = await telemetryQuery.get();
          const telemetry = results.docs.map(
              item => item.data()
          );

          if (telemetry.length > 0) {

            // Generate telemetry before we resort the array.
            const leafletTelemetry = telemetry.map(it => latLng(it.latitude, it.longitude));
            const dayStart = telemetry.find(it => it.state === "IGNITION_ON");
            telemetry.sort((a, b) => b.timestamp - a.timestamp);
            const dayEnd = telemetry.find(it => it.state === "IGNITION_OFF");

            let dayLength = 0;
            if (dayStart && dayEnd) {
              dayLength = dayEnd.timestamp - dayStart.timestamp;
            }

            entry.telemetry = leafletTelemetry;
            if (leafletTelemetry.length > 1) {
              _this.center = leafletTelemetry[0];
            }

            const results = telemetry.map(item => item.odometer);

            if(results.length > 2) {
              entry.km =  Math.max(...results) - Math.min(...results);
            }

            const summaryResult = await summaryQuery.get();
            if (summaryResult.exists) {
              const summary = summaryResult.data();
              entry.worked = formatSeconds(summary.duration / 1000);
              entry.dayLength = formatSeconds(dayLength / 1000);
            } else {
              entry.worked = formatSeconds(0);
              entry.dayLength = formatSeconds(dayLength / 1000);
            }
          } else {
            entry.worked = formatSeconds(0);
            entry.dayLength = formatSeconds(0);
          }
        }
      }
    },
    hours_worked(value) {
      this.$emit('changeWeekSummary', value);
    },
  },

  /* the entries watcher is the trigger to async load telemetry data */
  /* the first step is to iterate over the entries and return unique telemetryProviderIds for the telemetry queries */
  /* this.vehicles is passed in from the parent component - possibly there might be an out of order issue here */
  /* given there are only 3-8 entries for a day per worker, it shouldn't take long to iterate over the entries to get the vehicle regos */


  methods: {
    round(num) {
      return roundNumber(num, 2);
    },
    getItemWidth(item) {
      if (item.description.length > 0) {
        return '150px';
      }

      return '100px';
    },
    getItemHeight(item) {
      console.log(item)
      return `110px`
    },
    removeOvernightEntries: function () {
      return this.entries.filter(entry => entry.time_entry_type !== "c33c1907-bcdf-4b27-ab7f-506753a6647e" && entry.time_entry_type !== "d996d163-0cbf-40cc-8a48-92294d8e1274");
    },
    getManualEntries: function () {
      return this.entries.filter(entry => entry.type === 'manual');
    },
    renderDate: function (d) {
      return d.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    },
    load_entry: function (entry) {
      this.$emit('changeEntry', entry);
    },
    approve_day: function () {
      const _this = this;
      /* flip between all pending and all approved */
      /* the firestore rules should kick and prevent unauthorized changes */
      if (this.entries.some(e => {
        return e.status === 'locked'
      })) {
        this.entries.map(function (entry) {
          entry.status = 'pending';
          if (!entry.history) {
            entry['history'] = []
          }
          entry.history.push(DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) + " Unlocked by " + _this.$root.user.email);
          grooveDatabase.collection("time_entries").doc(entry.id).set(entry);
        });
      } else if (this.entries.every(e => {
        return e.status === 'approved'
      })) {
        this.entries.map(function (entry) {
          entry.status = 'pending';
          entry['approved_by'] = _this.$root.user.uid;
          entry['approved_by_timestamp'] = DateTime.now().toMillis();
          if (entry.status === "locked") {
            throw "Locked";
          }
          if (!entry.history) {
            entry['history'] = []
          }
          entry.history.push(DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) + " Unapproved by " + _this.$root.user.email);
          grooveDatabase.collection("time_entries").doc(entry.id).set(entry);
        });
      } else {
        if (this.entries.every(e => {
          return !!e.cost_centre
        })) {
          this.entries.map(function (entry) {
            entry.status = 'approved';
            entry['approved_by'] = _this.$root.user.uid;
            entry['approved_by_timestamp'] = DateTime.now().toMillis();
            if (entry.status === "locked") {
              throw "Locked";
            }
            if (!entry.history) {
              entry['history'] = []
            }
            entry.history.push(DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) + " Approved by " + _this.$root.user.email);

            grooveDatabase.collection("time_entries").doc(entry.id).set(entry);
          });
        } else {
          alert('Some entries are missing cost centres or entry types');
        }
      }
    },

    add_entry: function () {
      this.$emit('addEntry', {day: this.date});
    },
    checkOvertimeExists: function () {
      const entry = this.entries.find(entry => {
        return entry.time_entry_type === overnight_allowance_1 || entry.time_entry_type === overnight_allowance_2
      });
      this.$emit('changeEntry', entry);
    }
  },
  mounted: function () {
    // For later use.
  }
}
</script>

<style scoped lang="scss">

img {
  height: 100px;
  width: 100px;
}

td {
  width: 150px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

td:last-child {
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

span.approve-label {
  display: inline-block;
  margin-left: 5px;
}

button {
  padding: 12.8px !important;
}

.approve {
  background-color: #0d90e0 !important;
}

.approved {
  background-color: lawngreen !important;
}

.locked {
  background-color: gold !important;
}

div.clock-time {
  font-size: 0.8em;
  margin-left: 0;
  margin-top: 20px;
}

.empty td {
  opacity: 0.5;
}

.empty .approve_button {
  display: none;
}

.summary-label {
  display: inline-block;
  width: 100px;
}

.summary-value {
  display: inline-block;
  width: 100px;
}

.b-tooltip {
  pointer-events: none;
  z-index: 1;
}
</style>
