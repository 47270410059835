<template>
  <div class="container is-fluid is-fullheight" style="overflow-x: auto">
    <Audit
        v-if="show_report === true"
        :workers="workers"
        :selected-summaries="checkedRows"
        @hideAuditReport="hideAuditReport"
    />
    <div
        v-if="show_report === false"
        class="container"
    >
      <b-navbar type="is-white" class="container pt-4 mt-2" style="position: fixed;width: 100%;">
        <template #brand>
        </template>
        <template #start>
          <b-navbar-item>
            <b-select icon-pack="far" icon="warehouse" size="is-small" v-model="selected_depot" placeholder="Depot">
              <option
                  v-for="depot in depots"
                  :value="depot.id"
                  :key="depot.id">
                {{ depot.name }}
              </option>
            </b-select>
            <b-select icon-pack="far" icon="building" size="is-small" v-model="selected_division" placeholder="Division">
              <option
                  v-for="division in divisions"
                  :value="division.id"
                  :key="division.id">
                {{ division.name }}
              </option>
            </b-select>
            <b-button class="ml-2" size="is-small" icon-right="broom" @click="clearFilters"/>
          </b-navbar-item>

          <b-navbar-item>
            <b-button
                class="ml-4 mr-4"
                size="is-small"
                icon-pack="far"
                icon-left="arrow-left"
                @click="previousPayPeriod"
            >
            </b-button>
            <p style="margin-bottom: 0">{{ startOfWeekFormatted }} - {{ endOfWeekFormatted }}</p>
            <b-button class="ml-4 mr-4" size="is-small"
                      icon-pack="far"
                      icon-left="arrow-right"
                      @click="nextPayPeriod"
            >
            </b-button>

            <b-button class="ml-4 mr-4" size="is-small"
                      icon-pack="far"
                      icon-left="check-double"
                      @click="selectAll"
            >
              Select All
            </b-button>
          </b-navbar-item>

        </template>

        <template #end>

          <b-navbar-item>
            <b-switch
                v-model="adminExport"
                style="margin-bottom: 0;"
                size="small"
                :rounded="false"
                :outlined="false"
                :left-label='true'>Admin</b-switch>

            <b-button size="is-small"
                      icon-pack="far"
                      icon-left="download"
                      @click="downloadPayrollExport"
            >
              CSV
            </b-button>
          </b-navbar-item>

          <b-navbar-item>
            <b-button
                size="is-small"
                icon-pack="far"
                icon-left="magnifying-glass"
                @click="audit_report"
            >
              Audit
            </b-button>
          </b-navbar-item>

          <b-navbar-item>
            <b-button
                :disabled="!hasRole('payroll')"
                size="is-small"
                icon-pack="far"
                icon-right="lock"
                @click="lockEntries"
            />
            <b-button
                :disabled="!hasRole('payroll')"
                size="is-small"
                icon-pack="far"
                icon-right="unlock"
                @click="unlockEntries"
            />
          </b-navbar-item>

        </template>
      </b-navbar>
      <div class="container is-fluid" style="padding-top: 80px;">
        <b-notification v-if="loading" :closable="false">
          Loading staff data...
          <b-loading :is-full-page="true" v-model="loading" :can-cancel="true">
            <b-icon
                pack="fas"
                icon="arrows-rotate"
                size="is-large"
                custom-class="fa-spin">
            </b-icon>
          </b-loading>
        </b-notification>
        <b-collapse v-for="(workerDetails, key) in computed_data.data"
                    :key="key" class="card mb-2">

          <template #trigger="props">
            <div
                class="card-header"
                role="button"
                aria-controls="content">
              <p class="card-header-title title is-3 mb-0">
                {{ workerDetails.name }}
              </p>
              <a class="card-header-icon">
                <b-icon
                    :icon="props.open ? 'angle-down' : 'angle-up'">
                </b-icon>
              </a>
            </div>
          </template>

          <div class="card-content">

            <b-table
                :data="getSummaries(workerDetails)"
                :checked-rows.sync="checkedRows"
                :row-class="getRowClass"
                :is-row-checkable="(row) => row.verified !== false || row.state === 'locked'"
                :hoverable="true"
                checkable
                :checkbox-position="checkboxPosition">

              <b-table-column field="date" label="Date" centered v-slot="props">
                {{ renderDate(props.row.date) }}
              </b-table-column>

              <b-table-column field="hours_worked" label="Hours Worked" centered v-slot="props">
                {{ renderTime(props.row.hours_worked) }}
              </b-table-column>

              <b-table-column field="length_of_day" label="Day Length" centered v-slot="props">
                {{ renderTime(props.row.length_of_day) }}
              </b-table-column>

              <b-table-column field="state" label="Status" centered v-slot="props">
              <span :class="
                            [
                                'tag',
                                {'is-danger': props.row.state === 'pending'},
                                {'is-success': props.row.state === 'approved'}
                            ]">
                            {{ props.row.state }}
                        </span>

              </b-table-column>
            </b-table>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>

import {grooveDatabase} from "@/database";
import Vue from "vue";
import Audit from "../Audit";
import {DateTime} from "luxon";
import {ExportToCsv} from "export-to-csv";
import {alignPayrollWeek} from "@/components/Payroll/index";
/* load workers - filter by depot */
/* iterate over workers, iterate over date, load time_entries, calculate */
/* handling the csv stuff, really need to do all the calculations in a single object so I can just serial the object to csv */
/* this makes the layout more problematic - I'm tempted to suggest that the master object is passed into each component purely for rendering purposes */
/* not very vueish */

function pad(num) {
  return ("0" + num).slice(-2);
}

function formatTimeWorked(secs) {
  let minutes = Math.floor(secs / 60);
  let hours = Math.floor(minutes / 60)
  minutes = minutes % 60;
  return `${(hours)} Hours ${pad(minutes)} Mins`;
}

const entry_type_leave = '6c284d6e-7b50-4329-acab-5687417a7bbb';
const entry_type_unpaid = 'b933f35b-a04e-4a6b-827c-b29e1f39093d';
const roadTransPayBaseline = "2020-05-15T00:00:00";


export default {
  name: "Export",
  components: {Audit},
  data: function () {
    return {
      scrollOptions: {
        size: 5
      },
      divisions: [],
      startOfPayPeriod: this.getPayrollPeriodStart(),
      endOfPayPeriod: this.getPayrollPeriodStart().plus({days: 13}),
      start_time: '',
      currentFilter: {},
      workers: [],
      tmp: [],
      idx: 0,
      selected_depot: null,
      computed_data: {},
      time_entry_types: [],
      depots: [],
      vehicles: [],
      all_selected: false,
      all_data: {}, /* store everything here including the calculated data */
      unordered_data: [],
      workers_map: {},
      ordered_workers: [],
      ordered_data: [],
      show_report: false,
      selected_division: null,
      checkboxPosition: 'left',
      checkedRows: [],
      loading: false,
      adminExport: false
    }
  },

  firestore: {
    time_entry_types: grooveDatabase.collection("time_entry_types").orderBy('name'),
    cost_centres: grooveDatabase.collection("cost_centres").orderBy('name'),
    vehicles: grooveDatabase.collection("vehicles").orderBy('identifier')
  },

  computed: {
    startOfWeekFormatted() {
      return this.startOfPayPeriod.toFormat("ccc, dd/LL/yyyy");
    },
    endOfWeekFormatted() {
      return this.endOfPayPeriod.toFormat("ccc, dd/LL/yyyy");
    },
    dates() {
      const date_array = [];
      for (let i = 0; i <= 13; i++) {
        date_array[i] = this.startOfPayPeriod.plus({days: 13}).minus({days: i});
      }
      return date_array;
    }
  },

  watch: {
    selected_depot: {
      handler: async function () {
        this.checkedRows = [];
        await this.updateWorkers();
      }
    },
    selected_division: {
      handler: async function () {
        this.checkedRows = [];
        await this.updateWorkers();
      }
    },

    workers: {
      handler: function () {
        let _this = this;
        this.ordered_workers = [];
        this.workers_map = {}; /* clean the workers_map */
        this.workers.forEach(function (worker) {
          let id = worker.id;
          Vue.set(_this.all_data, id, []);
          _this.workers_map[id] = false;
        });

        let tmpOrder = [...this.workers]
            .sort((a, b) => (a.displayName > b.displayName) ? 1 : -1);
        this.ordered_workers = tmpOrder.map(w => w.id);

        this.findEntries()
      }
    },
    ordered_data: {
      // immediate: true,
      deep: true,
      handler: function () {
        let _this = this;

        /* reset all data */
        _this.computed_data = {idx: 0, data: {}};

        this.ordered_data.forEach(function (od) {
          let workerId = od.id;
          for (const date of _this.dates) {
            let today = date;
            let currentStartTime = date.startOf('day').valueOf();
            let currentEndTime = date.endOf('day').valueOf();
            let entrySummariesKey = currentStartTime + "-" + workerId;

            /* setup the computed data structure */
            if (!(workerId in _this.computed_data.data)) {
              Vue.set(_this.computed_data.data, workerId, {name: '', data: {}});
              let w = _this.workers.find(function (worker) {
                return worker.id === workerId
              });
              _this.computed_data.data[workerId].name = w ? w.displayName : w.email;
              _this.computed_data.data[workerId].staffCode = w ? w.staffCode : 'n/a';
              _this.computed_data.data[workerId].isAdmin = !!(w && w.roles && w.roles.admin);
            }

            /* setup the date structure */
            _this.computed_data.data[workerId].data[entrySummariesKey] = {
              date: date,
              entries: [],
              hours_worked: 0,
              length_of_day: 0,
              selected: false,
              checked: false,
              idx: 0,
              errors: false,
              worked: false, /* this means something was done - even if other business rules say don't report it */
              state: 'pending' /* this is a bad way to do things */,
              workerId: workerId
            };


            let earliest = 0;
            let existing_end = 0;
            let existing_start = 0;
            let difference = 0;

            /* begin the parsing */
            od.data.forEach(function (entry, idx, arr) {

              let current_entry = entry;

              /* set time entry type */
              entry.tet = _this.time_entry_types.find(function (tet) {
                return entry.time_entry_type === tet.uuid
              });


              /* missing a time entry type - warn the user */
              if (!entry.tet) {
                _this.computed_data.data[workerId].data[entrySummariesKey].errors = 'Missing entry type';
              } else if (!_this.computed_data.data[workerId].staffCode && _this.computed_data.data[workerId].staffCode !== 'n/a') {
                _this.computed_data.data[workerId].data[entrySummariesKey].errors = 'Missing staff code';
              }

              /* make sure it is within this days boundary */
              if ((entry.start_time >= currentStartTime && entry.start_time < currentEndTime) || (entry.end_time >= currentStartTime && entry.end_time <= currentEndTime)) {

                let total = 0;
                let start_overlapping_breaktime = 0;
                let end_overlapping_breaktime = 0;

                if (current_entry.start_time > existing_end) {
                  existing_end = current_entry.end_time;
                  existing_start = current_entry.start_time;
                  /* this should never happen - a job crosses over three days */
                  /* exclude unpaid breaks */
                  if (current_entry.time_entry_type === 'b933f35b-a04e-4a6b-827c-b29e1f39093d') {
                    console.log();
                  } else if (current_entry.end_time >= today.endOf('day').valueOf() && current_entry.start_time <= today.startOf('day').valueOf()) {
                    total = (total + (86400000 / 1000));
                  } else if (current_entry.end_time >= today.endOf('day').valueOf()) {
                    total = (total + (today.endOf('day').valueOf() - current_entry.start_time + 1000) / 1000);
                  } else if (current_entry.start_time <= today.startOf('day').valueOf()) {
                    total = (total + (current_entry.end_time - today.startOf('day').valueOf() + 1000) / 1000);
                  } else {
                    total = (total + (current_entry.end_time - current_entry.start_time) / 1000);
                  }
                }
                /* is overlapping */
                else {
                  /* ignore - completely overlapping */
                  if (
                      current_entry.time_entry_type === 'b933f35b-a04e-4a6b-827c-b29e1f39093d' && current_entry.end_time <= existing_end && current_entry.start_time >= existing_start) {
                    end_overlapping_breaktime = (current_entry.end_time - current_entry.start_time) / 1000;
                    total = 0;
                  } else if (
                      current_entry.time_entry_type === 'b933f35b-a04e-4a6b-827c-b29e1f39093d' && current_entry.end_time <= existing_end) {
                    start_overlapping_breaktime = (current_entry.end_time - current_entry.start_time) / 1000;
                    total = 0;
                  } else if (
                      current_entry.time_entry_type === 'b933f35b-a04e-4a6b-827c-b29e1f39093d' && current_entry.start_time <= existing_end) {
                    end_overlapping_breaktime = (existing_end - current_entry.start_time) / 1000;
                    total = 0;
                  }
                      /* in this case, the break starts during a job, and finishes after the job finishes */
                  /* in this case, then take the break time that overlaps with the preceding job - and remove it from the preceding job */
                  else if (current_entry.time_entry_type === 'b933f35b-a04e-4a6b-827c-b29e1f39093d' && current_entry.end_time > existing_end) {
                    end_overlapping_breaktime = (existing_end - current_entry.start_time) / 1000;
                    total = (total - (existing_end - current_entry.start_time) / 1000);
                  } else if (current_entry.end_time <= existing_end) {
                    console.log();
                  } else {
                    /* only add the difference to total */
                    if (current_entry.end_time > existing_end) {
                      difference = (current_entry.end_time - existing_end) / 1000
                      existing_end = current_entry.end_time;
                      total = (total + difference);
                    }
                  }
                }

                /* compute length of day */
                if (entry.start_time < earliest || earliest === 0) {
                  if (entry.start_time <= currentStartTime) { /* handle overlapping from the day before and set to midnight */
                    earliest = currentStartTime;
                  } else {
                    earliest = entry.start_time;
                  }
                }

                if (entry.end_time > existing_end) {
                  if (entry.end_time >= currentEndTime) { /* handle overlapping from the day after and set to midnight */
                    existing_end = currentEndTime;
                  } else {
                    existing_end = entry.end_time;
                  }
                }

                _this.computed_data.data[workerId].data[entrySummariesKey].length_of_day = ((existing_end - earliest) / 1000);

                /* we did something - don't hide from the rendered view - but still allow zero rating of leave */
                _this.computed_data.data[workerId].data[entrySummariesKey].worked = true;

                /* just use existing status we can work out verified etc via property */
                _this.computed_data.data[workerId].data[entrySummariesKey].state = entry.status;

                /* indicates whether the time entry has been approved */
                _this.computed_data.data[workerId].data[entrySummariesKey].verified = Object.prototype.hasOwnProperty.call(entry, "approved_by");

                /* set the hours worked - NOTE WELL - this doesn't take into account overlaps - this SURELY is a mistake */
                /* if this is a break - it needs to be deducted from the previous entry */

                /* now handle overlapping breaks that overlap at the end*/
                if (end_overlapping_breaktime > 0 && idx > 0) {
                  /* crap - can't use the previous entry - have to interate backwards to find the first non break entry */
                  for (let z = 1; idx - z >= 0; z++) {
                    if (arr[idx - z].time_entry_type !== 'b933f35b-a04e-4a6b-827c-b29e1f39093d') {
                      arr[idx - z].hours_worked = arr[idx - z].hours_worked - end_overlapping_breaktime;
                      break;
                    }
                  }
                  entry.hours_worked = 0;
                }
                /* handle overlapping breaks that overlap at the start */
                else if (start_overlapping_breaktime > 0 && idx < (arr.length - 1)) {
                  for (let y = 1; idx + y < (arr.length - 1); y++) {
                    if (arr[idx + y].time_entry_type !== 'b933f35b-a04e-4a6b-827c-b29e1f39093d') {
                      arr[idx + y].hours_worked = arr[idx + y].hours_worked - start_overlapping_breaktime;
                      break;
                    }
                  }
                  entry.hours_worked = 0;
                } else {
                  entry.hours_worked = total;
                }

                /* set cost centre code */
                if (entry.is_vehicle) {
                  entry.cost_centre_code = _this.vehicles.find(function (v) {
                    return v.id === entry.cost_centre
                  });
                } else {
                  entry.cost_centre_code = _this.cost_centres.find(function (cc) {
                    return cc.uuid === entry.cost_centre
                  });
                }

                _this.computed_data.data[workerId].data[entrySummariesKey].entries.push(entry);
              }
            });

            if (_this.computed_data.data[workerId].data[entrySummariesKey]) {

              /* begin the computation for this worker for this day */
              _this.computed_data.data[workerId].data[entrySummariesKey].hours_worked = _this.computed_data.data[workerId].data[entrySummariesKey].entries.reduce(function (currentVal, val) {

                if (val.time_entry_type === entry_type_leave) {
                  return currentVal;
                }
                return currentVal + val.hours_worked;
              }, 0);

            }

            if (_this.computed_data.data[workerId].data[entrySummariesKey].worked === false) {
              delete _this.computed_data.data[workerId].data[entrySummariesKey];
            }
          }

          _this.idx++;

        });
      }
    }
  },

  methods: {
    hasRole(role) {
      return this.$root.userDetails
          && Object.prototype.hasOwnProperty.call(this.$root.userDetails.roles, role)
          && this.$root.userDetails.roles[role] === true;
    },
    getRowClass(row) {
      if (row.errors) {
        return 'is-error';
      }
      if (row.status !== 'Confirmed' || row.status !== 'Locked') {
        return 'is-warn';
      }
      return null;
    },
    getSummaries(worker) {
      // Converts summaries as properties on 'data' to an array.
      const summaryArray = Object.values(worker.data);
      summaryArray.sort((a, b) => (a.date.toMillis() > b.date.toMillis()) ? 1 : -1);
      return summaryArray;
    },
    async updateWorkers() {
      this.all_data = {};
      this.computed_data = {};
      let reference = grooveDatabase.collection("users").orderBy("displayName");
      if (this.selected_depot && this.selected_depot !== "All") {
        reference = reference.where("depot", "==", this.selected_depot);
      }
      if (this.selected_division && this.selected_division !== 'all') {
        reference = reference.where("division", "==", this.selected_division);
      }

      this.loading = true;
      await this.$bind('workers', reference);
      this.loading = false;
    },
    getClass(v) {
      let currentClass = "is-success";
      if (v.state === "pending") {
        currentClass = "is-warning";
      }
      if (v.errors) {
        currentClass = "is-error";
      }
      return currentClass;
    },
    hideAuditReport() {
      this.show_report = false;
    },
    getPayrollPeriodStart() {
      const value = alignPayrollWeek(roadTransPayBaseline, DateTime.now());
      console.log(`Get payroll start value: ${value}`);
      return value;
    },
    renderTime(secs) {
      return formatTimeWorked(secs);
    },
    renderDate(date) {
      return DateTime.fromSeconds(date / 1000).toFormat("dd LLL yyyy");
    },
    findEntries: async function () {
      const _this = this;
      this.loading = true;

      this.ordered_data = [];
      this.all_data = {};

      _this.currentFilter = {
        start_time: _this.startOfPayPeriod,
        end_time: _this.endOfPayPeriod
      }

      const filterArgs = _this.generate_filter();

      let prepareQuery = grooveDatabase.collection("time_entries")
          .where('type', '==', 'manual');
      for (let i = 0; i < filterArgs.length; i++) {
        prepareQuery = prepareQuery.where(...filterArgs[i]);
      }

      const result = await prepareQuery.orderBy('start_time', 'asc').get();
      if (result && result.docs.length) {
        await _this.order_data(result.docs);
      }

      this.loading = false;
    },
    order_data: async function (snapshot) {
      this.ordered_data = [];
      this.all_data = {};
      await snapshot.forEach(data => {
        let entry = data.data();
        entry.id = data.id;
        if (!(entry.worker in this.workers_map)) { /* the entry isn't in this depot */
          return;
        } else if (!this.all_data[entry.worker]) {
          this.all_data[entry.worker] = [];
        }
        this.all_data[entry.worker].push(entry);
      });

      this.ordered_workers.forEach(worker => {
        if (this.all_data[worker] && this.all_data[worker].length > 0) {
          this.ordered_data.push({id: worker, data: this.all_data[worker]});
        }
      });
    },
    clearFilters: function() {
      this.selected_depot = null;
      this.selected_division = null;
    },
    nextPayPeriod: function () {
      this.startOfPayPeriod = this.startOfPayPeriod.plus({weeks: 2});
      this.endOfPayPeriod = this.startOfPayPeriod.plus({weeks: 2}).minus({seconds: 1});
      this.findEntries();
    },
    previousPayPeriod: function () {
      this.startOfPayPeriod = this.startOfPayPeriod.minus({weeks: 2});
      this.endOfPayPeriod = this.startOfPayPeriod.plus({weeks: 2}).minus({seconds: 1});
      this.findEntries();
    },
    selectAll: async function() {
      // Toggles between select/clear all.
      if(this.checkedRows && this.checkedRows.length > 0) {
        this.checkedRows = [];
      } else {
        if(this.computed_data && this.computed_data.data) {
          const data = await this.computed_data.data;
          for(const worker of Object.values(data)) {
            const summaries = this.getSummaries(worker);
            this.checkedRows = [...this.checkedRows, ...summaries.filter(summary => summary.state !== "pending")];
          }
        } else {
          this.$buefy.toast.open({
            message: 'Select Depot/Division!',
            type: 'is-warning'
          });
        }
      }
    },
    audit_report: function () {
      this.show_report = true;
    },
    downloadPayrollExport: function () {
      this.download();
    },
    download: function () {
      const _this = this;

      /* setup correct format for MYOB */
      const csvHeader = ['STAFF_CODE', 'TYPE', 'UNITS', 'WAGE_TYPE', 'ALLOW_CODE', 'COSTCENTRE', 'UNITS2', 'WAGECODE'];

      /* need to group entries by type */
      const type_map = {};

      if(_this.checkedRows && _this.checkedRows.length > 0) {
        for (const summary of _this.checkedRows) {
          const worker = _this.workers.find(worker => worker.id === summary.workerId);

          // Payroll is done via two separate imports - Admin/Dispatch and the rest.
          // Here we switch based on selection.
          if(_this.adminExport && (!Object.prototype.hasOwnProperty.call(worker.roles, "admin") || worker.roles["admin"] === false)) {
            continue;
          }

          if(!_this.adminExport && Object.prototype.hasOwnProperty.call(worker.roles, "admin") && worker.roles["admin"] === true) {
            continue;
          }

          for (const entry of summary.entries) {
            const prefix = entry.tet && entry.tet.myob_code ? entry.tet.myob_code : '';
            const cost_centre = entry.is_vehicle && entry.cost_centre_code ? prefix + "" + entry.cost_centre_code['costCentre'] : (entry.cost_centre_code ? (prefix + "" + entry.cost_centre_code.code) : "INVALID CHECK VEHICLE CONFIG");
            const tm_key = summary.workerId + '-' + entry.tet.uuid + '-' + cost_centre;

            if (entry.tet.uuid !== entry_type_unpaid) {
              if (!type_map[tm_key]) {
                type_map[tm_key] = [
                  worker.staffCode || '',
                  entry.tet ? entry.tet.myob_category : '',
                  entry.tet.uuid !== entry_type_leave ? entry.hours_worked / 3600 : 0,
                  entry.myob_code || 1,
                  entry.tet.myob_allowance_code || "",
                  cost_centre,
                  0,
                  1
                ]
              } else {
                /* increment the hours worked */
                type_map[tm_key][2] = type_map[tm_key][2] + (entry.tet.uuid !== entry_type_leave ? entry.hours_worked / 3600 : 0);
              }
            }
          }
        }


        if(type_map && Object.keys(type_map).length > 0) {
          const results = Object.values(type_map);
          const options = {
            filename: 'Timesheet_' + this.startOfPayPeriod.toFormat("yyyyLLdd0000") + '_' + this.endOfPayPeriod.toFormat("yyyyLLdd0000") + ' .csv',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: csvHeader
          };

          const csvExporter = new ExportToCsv(options);

          csvExporter.generateCsv(results);

          _this.$buefy.toast.open({
            message: 'Payroll Exported!',
            type: 'is-success'
          });
        } else {
          _this.$buefy.toast.open({
            message: 'No Results To Export!',
            type: 'is-warning'
          });
        }
      } else {
        _this.$buefy.toast.open({
          message: 'Select Entries!',
          type: 'is-warning'
        });
      }
    },
    lockEntries: async function() {
      // TODO lock all entries.
      const _this = this;
      this.$buefy.dialog.confirm({
        message: 'Lock all entries within selected Pay Period?',
        onConfirm: async function() {
          _this.$buefy.toast.open({
            message: 'Locking Entries!',
            type: 'is-warning'
          });

          try {
            // TODO Get all entries within date range and lock.
            _this.currentFilter = {
              start_time: _this.startOfPayPeriod,
              end_time: _this.endOfPayPeriod
            }

            const filterArgs = _this.generate_filter();

            let prepareQuery = grooveDatabase.collection("time_entries")
                .where('type', '==', 'manual');
            for (let i = 0; i < filterArgs.length; i++) {
              prepareQuery = prepareQuery.where(...filterArgs[i]);
            }

            const result = await prepareQuery.orderBy('start_time', 'asc').get();

            let batch = grooveDatabase.batch();
            let docCount = 0;
            for(const doc of result.docs) {
              if(docCount === 200) {
                await batch.commit();
                batch = grooveDatabase.batch();
                docCount = 0;
              }
              const entry = doc.data()

              if(!entry.history) {
                entry.history = [];
              }

              entry.history.push(DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) + " Locked by " + _this.$root.user.email);

              batch.update(doc.ref, { status: 'locked', history: entry.history });
              docCount++;
            }
            console.log("committing updates");
            await batch.commit();

            _this.$buefy.toast.open({
              message: 'Selected Entries Locked!',
              type: 'is-success'
            });
          } catch(error) {
            console.error(error);
            _this.$buefy.toast.open({
              message: 'Locking Entries Failed!',
              type: 'is-danger'
            });
          }
        }
      })
    },
    unlockEntries: async function () {
      try {
        if (!this.checkedRows || this.checkedRows.length === 0) {
          this.$buefy.toast.open({
            message: 'No Entries Selected!',
            type: 'is-warning'
          });
          return;
        }
        const batch = grooveDatabase.batch();
        for (const summary of this.checkedRows) {
          for (const entry of summary.entries) {

            if ("history" in entry) {
              entry.history = [];
            }

            entry.history.push(DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) + "  Change made by " + this.$root.user.email);

            const reference = grooveDatabase.collection('time_entries').doc(entry.id);

            const status = Object.prototype.hasOwnProperty.call(entry, "approved_by") ? "approved" : "pending";
            batch.update(reference, { status: status, history: entry.history });
          }
        }
        await batch.commit();
        // Data doesn't rsync so update local models to reflect change.
        for (const summary of this.checkedRows) {
          for (const entry of summary.entries) {
            entry.status = Object.prototype.hasOwnProperty.call(entry, "approved_by") ? "approved" : "pending";
          }
        }
        this.$buefy.toast.open({
          message: 'Selected Entries Unlocked!',
          type: 'is-success'
        });
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          message: 'Unlock Failed!',
          type: 'is-danger'
        });
      }
    },
    generate_filter: function () {
      const filter = [];
      if ( 'worker' in this.currentFilter && this.currentFilter.worker) {
        filter.push(['worker', '==', this.currentFilter.worker])
      }
      if ('start_time' in this.currentFilter && this.currentFilter.start_time) {
        // We need to make sure we capture all entries for the last day of the pay period too.
        // This includes all entries till midnight - end time is set to midnight of the morning
        // of the last day to allow date to render correctly so we add 1.
        const endDate = this.currentFilter.end_time.plus({ days: 1 });
        filter.push(['start_time', '<=', endDate.toMillis()])
        filter.push(['start_time', '>', (this.currentFilter.start_time.toMillis() - 86400000)]) /* go back an extra day to capture jobs that start the day before the week starts */
      }
      filter.push(['deleted', '==', false]);
      return filter;
    },
  },

  async mounted() {
    await this.$bind('depots', grooveDatabase.collection("depots").orderBy('name'));
    await this.$bind('divisions', grooveDatabase.collection("divisions").orderBy('name'));
    await this.$bind('workers', grooveDatabase.collection("users").orderBy('displayName'));
  }
}
</script>

<style scoped>

.navbar.has-navbar-centered .navbar-start {
  margin-left: 0 !important;
}

.button-container button {
  margin-left: 10px;
}

table.wrapping-table {
  width: 100%;
}

table.wrapping-table td.outer {
  padding: 10px;
  vertical-align: top;
  font-weight: bold;
}

table.wrapping-table table {
}

table.wrapping-table table td, table.wrapping-table table th {
  padding: 10px;
  width: 200px;
}

tr {
  cursor: pointer;
}

tr tr:hover td {
  background-color: #eee;
}

table.wrapping-table table th.selected, td.selected {
  width: 40px !important;
  text-align: left;
}

th.date, td.date {
  padding-left: 50px !important;
  text-align: left;
}

table.ddd td:last-child {
  /*width: 100% !important;*/
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.hr {
  width: 100px;
}

td button.but {
  width: 300px;
}

.confirm {
  background-color: lawngreen !important;
}

.pending {
  background-color: red;
}
</style>
