






















import {Component, Prop, Vue} from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import {Invoice} from "@road-transport-logistics/groove-core/dist/invoicing/types/Invoice";
import {roundNumber} from "@/utilities/number";

@Component
export default class InvoiceItemList extends Vue {
  @Prop({ default: undefined }) public invoice?: Invoice
  formatNumber(value: number): string {
    return roundNumber(value, 2);
  }
}
