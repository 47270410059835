<template>
  <div class="card">
    <div v-if="currentEntry">
      <l-map
          v-if="currentEntry.is_vehicle && telemetry"
          style="height: 150px; width: 100%;"
          :center="center"
          :zoom="zoom"
      >
        <l-tile-layer
            :url="url"
            :attribution="attribution"
        />
        <l-polyline :lat-lngs="telemetry.map(entry => calcPos(entry))"></l-polyline>
      </l-map>
      <div class="card-content">
        <vue-scroll :ops="scrollOptions" class="scroll-container">
          <!--<section>-->
          <div class="section" v-if="currentEntry.job_numbers && currentEntry.job_numbers.length">
            <p>Jobs</p>
            <div class="field"
                 v-for="job_number in currentEntry.job_numbers"
                 :key="job_number"
            >
              <b-tag
                  type="is-primary">
                <a
                    style="display: block; color: white;"
                    target="_blank"
                    :href="'https://market2x.app/carrier/dispatch/allJobs/' + job_number + '/summary'"
                >{{ job_number.substring(0, 5) }}</a>
              </b-tag>
            </div>
          </div>

          <div class="level mb-1">
            <div class="level-left">
              <b-tag v-bind:class="{ 'is-warning': currentEntry.status === 'locked' }">{{ status }}</b-tag>
            </div>
            <div class="level-right">
              <b-icon icon-pack="far" icon="truck"></b-icon>
              <b-switch
                  v-model="currentIsVehicle"
                  :isOutlined="true"
                  size="is-small"
                  class="level-item pl-2 m-0"></b-switch>
            </div>
          </div>


          <b-field custom-class="is-small" label="Start Time" class="has-text-left is-small">
            <div class="control">
              <b-datetimepicker
                  :expanded="true"
                  :timepicker="timepickerConfiguration"
                  size="is-small"
                  icon="calendar"
                  type="datetime-local"
                  :editable="true"
                  v-model="startDateTime"
              />
            </div>
          </b-field>
          <b-field custom-class="is-small" label="End Time" class="has-text-left is-small">
            <b-datetimepicker
                :expanded="true"
                :timepicker="timepickerConfiguration"
                size="is-small"
                icon="calendar"
                type="datetime-local"
                :editable="true"
                v-model="endDateTime"
            />
          </b-field>
          <b-field custom-class="is-small" label="Entry Type" class="has-text-left">
            <b-select
                size="is-small"
                icon="shelves"
                icon-pack="far"
                v-model="currentTimeEntryType"
                placeholder="Select an entry type"
                expanded
            >
              <option
                  class="is-small"
                  v-for="time_entry_type in filtered_time_entry_types"
                  :value="time_entry_type.uuid"
                  :key="time_entry_type.uuid">
                {{ time_entry_type.name }}
              </option>
            </b-select>
          </b-field>
          <b-field custom-class="is-small" label="Sub Category" class="has-text-left"
                   v-if="currentIsVehicle !== true">
            <b-select
                size="is-small"
                icon="dollar-sign"
                v-model="currentCostCenter"
                placeholder="Select a category"
                expanded
            >
              <option
                  class="is-small"
                  v-for="option in filtered_cost_centres"
                  :value="option.uuid"
                  :key="option.uuid">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
          <b-field custom-class="is-small" label="Vehicle" class="has-text-left"
                   v-if="currentIsVehicle === true && (currentTimeEntryType !== 'b933f35b-a04e-4a6b-827c-b29e1f39093d' && currentTimeEntryType !== '6c284d6e-7b50-4329-acab-5687417a7bbb' )">
            <b-select
                size="is-small"
                icon="truck"
                v-model="currentCostCenter"
                placeholder="Select a vehicle"
                expanded
            >
              <option
                  class="is-small"
                  v-for="option in vehicles"
                  :value="option.id"
                  :key="option.id">
                {{ option.identifier }}
              </option>
            </b-select>
          </b-field>
          <b-field custom-class="is-small" label="Trailer" class="has-text-left"
                   v-if="currentIsVehicle === true
                     && currentTimeEntryType === 'd9312a41-38b7-4a24-8d20-52d356a400e3'">
            <b-select
                size="is-small"
                icon="trailer"
                v-model="currentTrailer"
                placeholder="Select a trailer"
                expanded
            >
              <option
                  class="is-small"
                  v-for="option in trailers"
                  :value="option.id"
                  :key="option.id">
                {{ option.identifier }}
              </option>
            </b-select>
          </b-field>
          <b-field custom-class="is-small" label="Description">
            <b-input v-model="currentDescription" maxlength="100" type="textarea"></b-input>
          </b-field>
          <b-field custom-class="is-small is-right" grouped expanded>
            <p class="control">
              <b-button
                  size="is-small"
                  :disabled="currentEntry.status === 'locked' || !currentEntry.type"
                  type="is-success"
                  icon-pack="far"
                  icon-right="floppy-disk"
                  @click="save">Save
              </b-button>
            </p>
            <p class="control">
              <b-button
                  size="is-small"
                  :disabled="currentEntry.status === 'locked' || !currentEntry.type"
                  type="is-primary"
                  icon-pack="far"
                  icon-right="ban"
                  @click="cancelEntry"/>
            </p>
            <p class="control">
              <b-button
                  size="is-small"
                  :disabled="currentEntry.status === 'locked' || !currentEntry.type"
                  type="is-danger"
                  icon-pack="far"
                  icon-right="trash"
                  @click="deleteEntry"/>
            </p>
          </b-field>
          <!--</section>-->
        </vue-scroll>
      </div>
    </div>
  </div>
</template>

<script>

import {grooveDatabase} from '@/database'

import {latLng} from "leaflet";

import {LMap, LPolyline, LTileLayer} from 'vue2-leaflet';
import firebase from 'firebase/app';
import Vue from 'vue';
import {DateTime} from "luxon";

export default {
  name: "EntryDetails",
  components: {LMap, LTileLayer, LPolyline},
  props: {
    currentEntry: {
      type: Object,
      default: null
    },
    timeEntryTypes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    vehicles: {
      type: Array,
      default: () => {
        return [];
      }
    },
    trailers: {
      type: Array,
      default: () => {
        return [];
      }
    },
    costCentres: {
      type: Array,
      default: () => {
        return [];
      }
    },
    workers: {
      type: Array,
      default: () => {
        return [];
      }
    },
    dataChanged: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      // Proxies to map between epochms and js Date.
      startDateTime: null,
      endDateTime: null,
      telemetry: [],
      // We maintain properties outside of raw model as we want updates to be non destructive.
      currentIsVehicle: false,
      currentTimeEntryType: null,
      currentCostCenter: null,
      currentTrailer: null,
      currentDescription: null,
      center: [-45.505, 169.30],
      zoom: 12,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      filtered_time_entry_types: [],
      filtered_cost_centres: [],
      showhistory: false,
      isOpen: 0,
      scrollOptions: {
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: false,
          keepShow: false,
          background: '#c1c1c1',
          opacity: 0.8,
          minSize: 0.1,
          size: '6px',
          disable: false
        }
      },
      collapses: [
        {
          title: 'Details',
          section: 'details'
        },
        {
          title: 'History',
          text: 'history'
        }
      ],
      worker: null,
      timepickerConfiguration: { enableSeconds: false, hourFormat: "12" }
    }
  },
  computed: {
    status: function () {
      return this.currentEntry ? this.currentEntry.status.toUpperCase() : "";
    },
    entryUpdated: function () {
      if (this.currentEntry) {
        return this.currentEntry.start_time !== DateTime.fromJSDate(this.startDateTime).set({seconds: 0}).toMillis() ||
        this.currentEntry.end_time !== DateTime.fromJSDate(this.endDateTime).set({seconds: 0}).toMillis() ||
        this.currentEntry.time_entry_type !== this.currentTimeEntryType ||
        this.currentEntry.cost_centre !== this.currentCostCenter ||
        this.currentEntry.is_vehicle !== this.currentIsVehicle ||
        this.currentEntry.trailer ? this.currentEntry.trailer !== this.currentTrailer : false;
      }

      return false;
    }
  },

  watch: {
    currentEntry: {
      immediate: false,
      handler() {
        if (this.currentEntry) {
          console.log("We have an entry");
          if (this.currentEntry.is_vehicle && this.currentEntry.start_time && this.currentEntry.end_time) {
            console.log("Binding telemetry");
            this.$bind("telemetry", grooveDatabase.collection("telemetry")
                .where("assetId", "==", this.currentEntry.cost_centre)
                .where("timestamp", ">=", this.currentEntry.start_time)
                .where("timestamp", "<=", this.currentEntry.end_time)
                .orderBy("timestamp", "desc")
                .limit(100));
          } else {
            this.telemetry = [];
          }

          this.worker = this.workers.find(w => {
            return w.id === this.currentEntry.worker
          });

          /* filter time entry types available to just this worker */
          if (this.worker && this.timeEntryTypes) {
            this.filtered_time_entry_types = this.timeEntryTypes.filter(t => {
              let found = false;
              if (t.roles) {
                Object.keys(t.roles).forEach(r => {
                  if (t.roles[r] === true && r in this.worker.roles && this.worker.roles[r] === true) {
                    found = true;
                  }
                });
              }
              return found;
            });
          }

          // We bind values to local properties to prevent update triggers as the model changes.

          // Bind epochms on entry to js Date
          this.startDateTime = DateTime.fromMillis(this.currentEntry.start_time).toJSDate();
          this.endDateTime = DateTime.fromMillis(this.currentEntry.end_time).toJSDate();

          this.currentIsVehicle = this.currentEntry.is_vehicle;
          this.currentCostCenter = this.currentEntry.cost_centre;
          this.currentTimeEntryType = this.currentEntry.time_entry_type;
          this.currentTrailer = this.currentEntry.trailer ? this.currentEntry.trailer : null;
          this.currentDescription = this.currentEntry.description;
        }
      }
    },
    telemetry: {
      immediate: true,
      handler() {
        if (this.telemetry && this.telemetry.length && this.telemetry[0]) {
          this.center = [this.telemetry[0].latitude, this.telemetry[0].longitude];
        }
      }
    },
    currentTimeEntryType: {
      immediate: true,
      handler() {
        /* filter time entry types available to just this worker */
        if (this.worker && this.costCentres) {
          this.filtered_cost_centres = this.costCentres.filter(cc => {
            let found = false;
            if (cc.roles) {
              Object.keys(cc.roles).forEach(r => {
                if (cc.roles[r] === true && r in this.worker.roles && this.worker.roles[r] === true) {

                  if (this.currentTimeEntryType) {
                    const tt = this.timeEntryTypes.find(t => {
                      return t.name === cc.kind
                    });
                    if (tt && this.currentTimeEntryType === tt.uuid) {
                      found = true;
                    }
                  } else {
                    found = true;
                  }
                }
              });
            }
            return found;
          });
        }
        this.$emit('canCancelEdit', !this.entryUpdated);
      }
    },
    startDateTime: {
      handler() {
        this.$emit('canCancelEdit', !this.entryUpdated);
      }
    },
    endDateTime: {
      handler() {
        this.$emit('canCancelEdit', !this.entryUpdated);
      }
    },
    currentIsVehicle: {
      handler() {
        this.$emit('canCancelEdit', !this.entryUpdated);
      }
    },
    currentCostCenter: {
      handler() {
        this.$emit('canCancelEdit', !this.entryUpdated);
      }
    }
  },

  created() {
    this.storage = firebase.storage();
  },

  methods: {
    setshowhistory() {
      this.showhistory = true;
    },
    async save() {
      try {
        if (this.currentEntry.status === "locked") {
          throw "Locked";
        }

        if ((this.currentTimeEntryType == null) || (this.currentCostCenter == null)) {
          Vue.$toast.open({
            message: "You must include a cost centre and entry type",
            type: "error",
            position: "top-right"
          });
          return;
        }

        if (this.currentTimeEntryType === 'bb218785-64c4-407b-aba5-e03710a86e6e' && (!this.currentDescription || this.currentDescription === '')) {
          Vue.$toast.open({
            message: "Non-Driving entries require a description.",
            type: "error",
            position: "top-right"
          });
          return;
        }
        // eslint-disable-next-line vue/no-mutating-props
        this.currentEntry.time_entry_type = this.currentTimeEntryType;
        // eslint-disable-next-line vue/no-mutating-props
        this.currentEntry.cost_centre = this.currentCostCenter;
        // eslint-disable-next-line vue/no-mutating-props
        this.currentEntry.is_vehicle = this.currentIsVehicle;
        // eslint-disable-next-line vue/no-mutating-props
        this.currentEntry.description = this.currentDescription;

        // Make sure we reset the trailer if the entry is changed to non driving.
        if (this.currentEntry.is_vehicle) {
          // eslint-disable-next-line vue/no-mutating-props
          this.currentEntry.trailer = this.currentTrailer;
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          this.currentEntry.trailer = null;
        }


        // Apply datetime changes.
        // eslint-disable-next-line vue/no-mutating-props
        this.currentEntry.start_time = DateTime.fromJSDate(this.startDateTime).set({seconds: 0}).toMillis();
        // eslint-disable-next-line vue/no-mutating-props
        this.currentEntry.end_time = DateTime.fromJSDate(this.endDateTime).set({seconds: 0}).toMillis();

        if (this.currentEntry.start_time > this.currentEntry.end_time) {
          Vue.$toast.open({
            message: "Please fix start/end times.",
            type: "error",
            position: "top-right"
          });
          return;
        }

        /* force the status back to pending on any change */
        // eslint-disable-next-line vue/no-mutating-props
        this.currentEntry.status = "pending";
        if (!('history' in this.currentEntry)) {
          // eslint-disable-next-line vue/no-mutating-props
          this.currentEntry['history'] = []
        }
        // eslint-disable-next-line vue/no-mutating-props
        this.currentEntry.history.push(DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) + "  Change made by " + this.$root.user.email);

        await grooveDatabase.collection("time_entries").doc(this.currentEntry.id).set(this.currentEntry);

        this.$emit('changeEntry', null);

        Vue.$toast.open({
          message: "Time Entry Saved.",
          type: "success",
          position: "top-right"
        });

      } catch (error) {
        console.log(error);
      }
    },
    cancelEntry: function () {
      const _this = this;
      if (this.entryUpdated) {
        this.$confirm(
            {
              message: 'You have unsaved changes! Still wish to cancel?',
              button: {
                no: 'No',
                yes: 'Yes'
              },
              callback: confirm => {
                if (confirm) {
                  _this.$emit('changeEntry', null);
                }
              }
            }
        )
      } else {
        this.$emit('changeEntry', null);
      }
    },
    deleteEntry: function () {
      let _this = this;
      this.$confirm(
          {
            message: 'Are you sure you want to delete this entry?',
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              if (confirm) {
                grooveDatabase.collection("time_entries")
                    .doc(this.currentEntry.id)
                    .update({deleted: true})
                    .then(function () {
                      Vue.$toast.open({
                        message: "Time Entry Deleted.",
                        type: "success",
                        position: "top-right"
                      });
                      _this.$emit('changeEntry', null);
                    });
              }
            }
          }
      )
    },
    calcPos: function (pos) {
      return latLng(pos.latitude, pos.longitude);
    }

  }
}
</script>

<style scoped lang="scss">

.card {
  height: auto !important;
}

#trash {
  margin-left: 10px;
  margin-top: 7px;
}

#unlock {
  margin-left: 10px;
  margin-top: 7px;
}

</style>
