import Vue from 'vue';
import VueRouter from 'vue-router';
import '@inkline/inkline/dist/inkline.css';
import Inkline from '@inkline/inkline';
import firebase from 'firebase/app';
import {firestorePlugin} from 'vuefire';
import App from './App.vue';

// Fontawesome setup
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/pro-regular-svg-icons'
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome'
// @ts-ignore
import Arrivals from "./components/Arrivals";
// @ts-ignore
import Auth from "./components/Auth";
// @ts-ignore
import Timesheets from "./components/TimeManagement/Timesheets.vue";
// @ts-ignore
import CostCentres from "./components/CostCentres";
// @ts-ignore
import TimeEntryTypes from "./components/TimeEntryTypes";
// @ts-ignore
import Users from "./components/Users";
// @ts-ignore
import Export from "./components/Payroll/Export.vue";
// @ts-ignore
import PurchaseOrders from "./components/PurchaseOrder/PurchaseOrders.vue";
// @ts-ignore
import PurchaseOrdersPrint from "./components/PurchaseOrder/PurchaseOrdersPrint.vue";
// @ts-ignore
import Tracking from "./components/Tracking";
// @ts-ignore
import Suppliers from "./components/Suppliers";
// @ts-ignore
import Home from "./components/Home";
// @ts-ignore
import LeaveRequests from "./components/LeaveRequests";
// @ts-ignore
import Batch from "./components/Invoicing/BatchSummary.vue";
// @ts-ignore
import VueConfirmDialog from 'vue-confirm-dialog'
// @ts-ignore
import VueMoment from 'vue-moment'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import AsyncComputed from 'vue-async-computed'
import axios from 'axios';
import VueAxios from 'vue-axios';
import Buefy from 'buefy'
// @ts-ignore
import Product from "@/components/Product";
// @ts-ignore
import Asset from "@/components/Asset";
// @ts-ignore
import Jobs from "@/components/Jobs.vue";
// @ts-ignore
import Invoicing from "@/components/Invoicing.vue";
// @ts-ignore
import vuescroll from "vuescroll/dist/vuescroll-native";
// import the css file
import "vuescroll/dist/vuescroll.css";
import {grooveDatabase} from "@/database";
import Batches from "@/components/Invoicing/Batches.vue";
import {UserDetails} from "@road-transport-logistics/groove-core/dist/database/models/UserDetails";
import grooveStore from "@/data/grooveStore";

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

library.add(fas)
// @ts-ignore
library.add(far)

Vue.config.productionTip = false

Vue.use(Inkline);
Vue.use(firestorePlugin)
Vue.use(VueRouter)
Vue.use(VueMoment);
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.use(VueToast);
Vue.use(AsyncComputed);
Vue.use(VueAxios, axios);
Vue.use(Buefy, {
    defaultIconComponent: FontAwesomeIcon,
    defaultIconPack: 'fas'
});
Vue.use(vuescroll);

// Ignore undefined properties.
firebase.firestore().settings({
    ignoreUndefinedProperties: true,
    merge: true
})

const router = new VueRouter({
    mode: 'history',
    routes: [
        {path: '/', component: Home},
        {path: '/auth', name: 'Auth', component: Auth},
        {path: '/timesheets', name: 'Timesheet Review', component: Timesheets},
        {path: '/costcentres', name: 'Cost Centre Administration', component: CostCentres},
        {path: '/timeentrytypes', name: 'Time Entry Types Administration', component: TimeEntryTypes},
        {path: '/batches', name: 'Batches', component: Batches},
        {path: '/accpac', name: 'Batch', component: Batch},
        {path: '/users', name: 'Users', component: Users},
        {path: '/export', name: 'Export', component: Export},
        {path: '/purchaseorders', name: 'Purchase Orders', component: PurchaseOrders},
        {path: '/purchaseorders/print', name: 'Print Purchase Orders', component: PurchaseOrdersPrint},
        {path: '/eta', name: 'Tracking & ETA', component: Tracking},
        {path: '/eta/arrivals', name: 'Arrivals', component: Arrivals},
        {path: '/purchaseorders/suppliers', name: 'Suppliers', component: Suppliers},
        {path: '/leave', name: 'Leave Requests', component: LeaveRequests},
        {path: '/products', name: 'Products', component: Product},
        {path: '/assets', name: 'Assets', component: Asset},
        {path: '/jobs', name: "Jobs", component: Jobs},
        {path: '/invoicing', name: "Invoicing", component: Invoicing},
    ]
});

new Vue({
    router,
    el: '#app',
    data() {
        return {
            user: null,
            userDetails: null
        }
    },
    async created() {


        firebase.auth().onAuthStateChanged(async (user) => {
            // @ts-ignore
            if (user && !this.user) {
                // @ts-ignore
                this.user = user
                const reference = await grooveDatabase.collection("users").doc(user.uid).get();
                // @ts-ignore
                this.userDetails = reference.data() as UserDetails;
                grooveStore.hotUpdate({});
            } else {
                this.$router.push('/auth').then(() => console.log("Navigating to authentication"));
            }
        });
    },
    render(h) {
        return h(App, {props: this.$data})
    },
});




